import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import { Provider as MobxProvider } from "mobx-react";
import { stores } from './store/stores';


const app = (
  <MobxProvider {...stores}>
    <HashRouter>
      <App />
    </HashRouter>
  </MobxProvider>
);


ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
