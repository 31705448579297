import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Typography } from "@material-ui/core";
const ElipseUI = (props) => {
  return (
    <OverlayTrigger
      trigger="hover"
      key={props.name + "ovt"}
      placement="top"
      overlay={
        <Tooltip id={`tooltipt-${props.children}`}>
          <strong>{props.name}</strong>
        </Tooltip>
      }
    >
      <Typography {...props}>
        {props.size == "full" || !props.size
          ? props.name
          : props.name.toString().substr(0, props.size) + "..."}
      </Typography>
    </OverlayTrigger>
  );
};
export default ElipseUI;
