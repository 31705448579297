import React from "react";
import { types, flow } from "mobx-state-tree";
import { LocationModel } from "../models/LocationModel";
import { Axios } from "../utils/http-common";
import chroma from "chroma-js";
import { cloneDeep } from "lodash";

const LocationsStore = types
  .model("LocationsStore", {
    locations: types.optional(types.array(LocationModel), [])
  })
  .actions(self => ({
    setLocations: locations => {
      self.locations = locations;
    },
    deleteLocation: id => {
      self.locations = self.locations.filter(l => l.id !== id);
    },
    fetchLocations: flow(function* func() {
      yield Axios.get("/location").then(({ data }) => {
          self.setLocations(data);
      });
    })
  }))
  .views(self => {
    return {
      getById: id => {
        const found = self.locations.filter(location => {
          return location.id == id;
        });
        return found.length > 0 ? found[0] : null;
      },

      getDeviceById: (locationId, deviceId) => {
        const location = self.getById(locationId);
        if (location) {
          const foundDevices = location.devices.filter(
            device => device.id == deviceId
          );
          return foundDevices.length > 0 ? foundDevices[0] : null;
        }
        return null;
      },

      getAllDevices: (locationId) => {
        const location = self.getById(locationId);
        return JSON.parse(JSON.stringify(location.devices));
      },

      getRows: () => {
        try {
          const newLocations = cloneDeep(self.locations.toJSON());
          return newLocations.map(location => {
            location.devices = location.devices.map(device => {
              device.services = device.services.map(service => {
                return (
                  <span
                    key={service.id}
                    style={{
                      borderRadius: 5,
                      padding: "2px 5px",
                      marginInlineEnd: 5
                    }}
                    className="tag"
                  >
                    {service.name}
                  </span>
                );
              });
              device.tags = device.tags.map(tag => {
                let color = chroma("black");
                if (
                  tag.color &&
                  tag.color.match(
                    /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^\)]*\)/
                  )
                ) {
                  color = chroma(tag.color);
                }
                return (
                  <span
                    key={tag.id}
                    style={{
                      backgroundColor: color.alpha(0.1),
                      color: color,
                      borderRadius: 5,
                      padding: "2px 5px",
                      marginInlineEnd: 5,
                      border: 0
                    }}
                    className="tag"
                  >
                    {tag.name}
                  </span>
                );
              });
              return device;
            });
            return location;
          });
        } catch (e) {
          return [];
        }
      }
    };
  });

export const locationsStore = LocationsStore.create({});
