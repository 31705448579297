import { types, onSnapshot } from "mobx-state-tree";
import { ServiceModel } from "./ServiceModel";
import { TagModel } from "../store/tagStore";

/**
 * @typedef {Object} DeviceStatus
 * @property {string} status - What the food should be called
 * @property {string} ipAddress - What the food should be called
 * @property {number} availableStorage - What the food should be called
 * @property {number} totalStorage - What the food should be called
 */
const DeviceStatus = types.model("DeviceStatus", {
  status: types.string,
  ipAddress: types.string,
  availableStorage: types.optional(types.number, 0), // in gigs
  totalStorage: types.optional(types.number, 0) // in gigs
});

/**
 * @typedef {Object} DeviceModel
 * @property {string} id
 * @property {string} deviceName
 * @property {number | null} resizeWidth
 * @property {number | null} resizeHeight
 * @property {number} totalStorage
 */
export const DeviceModel = types
  .model("DeviceModel", {
    id: types.string,
    deviceName: types.string,
    resizeWidth: types.maybeNull(types.number),
    resizeHeight: types.maybeNull(types.number),
    shutdownTime: types.string,
    wakeupTime: types.string,
    orientation: types.number,
    services: types.optional(types.array(ServiceModel), []),
    tags: types.optional(types.array(TagModel), []),
    status: types.optional(types.maybeNull(DeviceStatus), null)
  })
  .actions(self => {
    return {};
  });
