import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import { inject, observer } from "mobx-react";
import { Axios, source } from "../../utils/http-common";

export const PlaylistSelect = observer(
  ({ onChange = playlist => {}, value = undefined }) => {

    const [playlists, setPlaylists] = useState([]);

    useEffect(() => {
      const cancelTokenSource = source();
      Axios.get("/playlist", {
        cancelToken: cancelTokenSource.token
      })
        .then(res => {
          setPlaylists(res.data);
        })
        .catch(error => {
          setPlaylists([]);
        });
      return () => {
        cancelTokenSource.cancel();
      };
    }, []);

    return (
      <ReactSelect
        value={value}
        getOptionLabel={option => option.type}
        getOptionValue={option => option}
        onChange={v => {
          onChange(v);
        }}
        options={playlists}
      />
    );
  }
);
