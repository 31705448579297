import React from 'react'

const CopyRight = ({showCompanyName,color}) => {
  return (
    <p className={`text-${color}`}>© {new Date().getFullYear()} {showCompanyName?"Jereer.":""}</p>

  )
}


CopyRight.defaultProps={
  color:"white"
}

export default CopyRight