import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from "@material-ui/core";
import { useState } from "react";
import { Button } from "react-bootstrap";

const SettingConfirmation = ({ open, handleClose, handleAgreement }) => {
  const [prom, setprom] = useState();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>You are updating your settings. Continue?</DialogTitle>

      {prom ? (
        <DialogContent className="m-4 text-center">
          <CircularProgress />
        </DialogContent>
      ) : (
        <div>
          <Button variant="danger" onClick={handleClose} className="m-4">
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setprom(handleAgreement());
            }}
          >
            Agree
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default SettingConfirmation;
