import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { upload } from "../../services/FileUploadService";
import "../../styles/Slot.css";
import { Axios, config } from "../../utils/http-common";
import { Promise } from "q";
import SweetAlert from "react-bootstrap-sweetalert";
import { NotificationManager } from "react-notifications";
import { v4 as uuidv4 } from "uuid";
import * as _ from "lodash";
import { CircularProgress } from "@material-ui/core";
import { inject } from "mobx-react";
import { autorun } from "mobx";
import FileTrackerContainer from "../FileTracker/container";

/**
 * Formats the size
 */
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

const AdvertiserMedia = ({
  advertiser,
  onChange = (media = []) => {},
  createCampaignStore,
}) => {
  const [files, setFiles] = useState([]);
  const [filesToShowInFileTracker, setFilesToShowInFileTracker] = useState([]);
  const [showFileTracker, setShowFileTracker] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [webUrl, setWebUrl] = useState("");
  const [deletingMedia, setDeletingMedia] = useState(false);
  const [mediaFile, setMediaFile] = useState(-1);
  const [mediaIndex, setMediaIndex] = useState(-1);
  const [fetchedFiles, setFetchedFiles] = useState([]);
  const [slots, setSlots] = useState([]);
  const [intervals, setIntervals] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(null);
  const [retrevingData, setRetrevingData] = useState(false);

  // retrieve intervals for media
  useEffect(() => {
    Axios.get("/interval").then((res) => {
      setIntervals(res.data);
    });
    return () => {};
  }, []);

  // retrieve files for advertiser
  useEffect(() => {
    if (advertiser) {
      fetchFiles(advertiser.id);
    }
    // retrieve reserved slots from create campaign store
    autorun(() => {
      setSlots(createCampaignStore.campaign.reservedSlots.toJSON());
    });
    return () => {};
  }, [advertiser]);

  useEffect(() => {
    createCampaignStore.campaign.setSlots(slots);
    return () => {};
  }, [slots, fetchedFiles]);

  const deleteFile = (advertiserId, file, index) => {
    setDeletingMedia(false);
    Axios.delete("/library/link/" + advertiserId + "/" + file.id)
      .then(() => {
        fetchFiles(advertiserId);
        NotificationManager.success(`File ${file.title} deleted successfully`);
      })
      .catch((e) => {
        NotificationManager.error(`File was NOT deleted.`);
      });
    setSlots(slots.filter((_, i) => index !== i));
  };

  const handleAcceptedFiles = (inputFiles, rejectedFiles) => {
    setShowFileTracker(true);
    inputFiles.map((file) =>
      Object.assign(file, {
        id: uuidv4(),
        uploadProgress: 0,
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    rejectedFiles?.map((file) =>
      Object.assign(file, {
        id: uuidv4(),
        uploadProgress: 0,
        type: "asdasd",
        name: "rejected file",
      })
    );
    if (rejectedFiles) {
      setFiles([...rejectedFiles, ...inputFiles, ...files]);
    } else setFiles([...inputFiles, ...files]);
    uploadNewFiles(inputFiles);
  };

  const fetchFiles = (advertiserId) => {
    return Axios.get(`/library/link/${advertiserId}`).then((res) => {
      res.data = res.data.map((file) => {
        const newUrl = `${file.url}`;
        return {
          ...file,
          url: newUrl,
        };
      });
      setFetchedFiles(res.data);
      onChange(res.data);
      return res;
    });
  };

  // WEB URI MEDIA FUNCTION
  // const createWebMedia = () => {
  //   setUploading(true);
  //   return Promise.all(
  //     createWebUrlMedia(advertiser.id, webUrl, (progressEvent) => {
  //       var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //       updateFileUploadProgress(file, percentCompleted);
  //       console.log("upload progress: ", percentCompleted);
  //     })
  //   )
  //     .then((res) => {
  //       fetchFiles(advertiser.id);
  //       setFiles([]);
  //     })
  //     .finally(() => {
  //       setUploading(false);
  //     });
  // };

  // assign files to currentFiles (files to be displayed)
  useEffect(() => {
    if (files.length !== 0) {
      setFilesToShowInFileTracker((old) => [...old, files]);
    }
    setCurrentFiles([...files]);
  }, [files]);

  // add upload progress to file
  const updateFileUploadProgress = (file = {}, progress = 0) => {
    let oldState = _.cloneDeep(files);
    file.uploadProgress = progress;
    setUploadingFile({ ...file });
  };

  const emptyUpCurrentFiles = () => {
    setCurrentFiles([]);
  };

  // upload files and calculate progress
  const uploadNewFiles = (newFiles) => {
    setUploading(true);
    return Promise.all(
      newFiles.map((file) =>
        upload(file, advertiser.id, (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          updateFileUploadProgress(file, percentCompleted);
        })
      )
    ).then(() => {
      fetchFiles(advertiser.id);
      setFilesUploaded(newFiles);
    });
  };

  // media items that have been uploaded
  const mediaItems = !retrevingData ? (
    fetchedFiles.map((file, index) => (
      <OverlayTrigger
        trigger="hover"
        key={file.title + index + "ov"}
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${file.title}`}>
            <strong>Name: {file.title}</strong>
          </Tooltip>
        }
      >
        <div className="thumb" key={file.title + index + "t"}>
          <div className="thumbInner" key={file.title + index + "ti"}>
            {file.title.includes(".mp4") || file.title.includes(".mkv") ? (
              <video
                className="img-fluid p-1"
                style={{
                  objectFit: "cover",
                }}
                preload="metadata"
              >
                <source src={file.url + "#t=0.50"} type="video/mp4"></source>
              </video>
            ) : (
              <img id={file.url} src={file.url} className="mediaImage" />
            )}
          </div>
          <button
            className="thumbButton"
            onClick={(e) => {
              e.stopPropagation();
              setDeletingMedia(true);
              setMediaFile(file);
              setMediaIndex(index);
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete Media"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path
                fillRule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          </button>
        </div>
      </OverlayTrigger>
    ))
  ) : (
    <CircularProgress
      style={{ margin: "auto", verticalAlign: "middle", display: "block" }}
    />
  );

  return (
    <Row>
      <FileTrackerContainer
        show={showFileTracker}
        setShow={setShowFileTracker}
        files={currentFiles}
        setFiles={emptyUpCurrentFiles}
      />
      <Col lg={12}>
        <div className="card m-b-20">
          <div className="card-body">
            <div className="row d-flex justify-content-between">
              <div className="col-6">
                <h4 className="mt-0 header-title">Available media</h4>
                <p className="text-muted m-b-30 font-14">
                  Available media under the selected advertiser.
                </p>
              </div>

              <div>
                <Dropzone
                  onDrop={(acceptedFiles, rejected) =>
                    handleAcceptedFiles(acceptedFiles, rejected)
                  }
                  disableClick={null}
                  multiple
                  accept="image/*,video/*"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button>Upload</Button>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
            <aside className="container-fluid">{mediaItems}</aside>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title"></h4>
                    <p className="text-muted m-b-30 font-14"></p>
                    <section>
                      <Row
                        className="dropzone-previews mt-3 mb-3"
                        id="file-previews"
                      >
                        {uploading &&
                          currentFiles.map((f, i) => {
                            return (
                              <Col md={12} key={i}>
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="card-body">
                                    <Row className="align-items-center">
                                      <Col sm={6}>
                                        <div className="mb-2">
                                          {f.type.includes("video") && (
                                            <video
                                              style={{
                                                maxWidth: "100%",
                                              }}
                                              controls
                                            >
                                              <source
                                                src={f.preview}
                                                type={f.type}
                                              ></source>
                                            </video>
                                          )}
                                          {f.type.includes("image") && (
                                            <img
                                              data-dz-thumbnail=""
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                              height="150"
                                              style={{
                                                maxWidth: "100%",
                                              }}
                                            />
                                          )}
                                        </div>
                                      </Col>
                                      <Col sm={6}>
                                        <div className="">
                                          <p
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </p>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <ProgressBar
                                        className="w-100"
                                        animated
                                        striped
                                        min={0}
                                        now={f.uploadProgress}
                                        max={100}
                                      />
                                      {/* <button
                                      className="btn btn-warning cancel"
                                      onClick={() => {
                                        cancelUpload();
                                      }}
                                    >
                                      <span>Cancel</span>
                                    </button> */}
                                    </Row>
                                  </div>
                                </Card>
                              </Col>
                            );
                          })}
                      </Row>
                    </section>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    setFiles([]);
                    setUploading(false);
                    cancelUpload();
                  }}
                >
                  Cancel
                </button> */}
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    setFiles([]);
                    setUploading(false);
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col>
        <div></div>
      </Col>
      {/* WEB URI MEDIA UPLOAD */}
      {/* <Col lg={12}>
        <section>
          <div className="card m-b-20">
            <div className="card-body">
              <h4 className="mt-0 header-title">Web Media Url</h4>
              <div className="form-group row">
                <div className="col-sm-12">
                  <input
                    value={webUrl}
                    placeholder="https://jereer.net"
                    onChange={(e) => setWebUrl(e.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <Button onClick={() => createWebMedia()}>Save</Button>
            </div>
          </div>
        </section>
      </Col> */}
      {deletingMedia && (
        <SweetAlert
          show
          type="danger"
          title="Are you sure?"
          onConfirm={() => deleteFile(advertiser.id, mediaFile, mediaIndex)}
          onCancel={() => setDeletingMedia(false)}
          showCancel
          cancelBtnBsStyle="primary"
          confirmBtnBsStyle="danger"
          focusConfirmBtn={false}
        >
          You will not be able to revert changes.
        </SweetAlert>
      )}
    </Row>
  );
};

export default inject(["createCampaignStore"])(AdvertiserMedia);
