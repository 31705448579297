import { Axios, source, cancelToken } from "../utils/http-common";
import { NotificationManager } from "react-notifications";

// let cancel;
// assign files to form data and post to backend
const upload = (file, advertiserId, onUploadProgress) => {
  let formData = new FormData();

  formData.append("file", file);
  formData.append("advertiserId", advertiserId);
  return Axios.post("/library/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
    // cancelToken: new cancelToken(function executor(c) {
    //   cancel = c;
    // })
  })
    .then((response) => {})
    .catch((error) => {
      NotificationManager.error(error.message, "the file/s was not uploaded");

      if (Axios.isCancel(error)) {
        console.log("post Request canceled");
      }
    });
};

const cancelUpload = () => {
  cancel(); //Check this out
  // source.cancel("Operation has been canceled");
};

const getFiles = (advertiserId) => {
  return Axios.get(`/library/link/${advertiserId}`);
};

const createWebUrlMedia = (advertiserId, webUrl, onUploadProgress) => {
  let formData = new FormData();
  console.log(webUrl);
  console.log(advertiserId);
  formData.append("webUrl", webUrl);
  formData.append("advertiserId", advertiserId);

  console.log(formData);

  formData = {
    webUrl: webUrl,
    advertiserId: advertiserId + "",
  };

  return Axios.post("/library/webUrlMedia", formData, {
    headers: {
      "Content-Type": "application/json",
    },
    onUploadProgress,
  });
};

export { upload, getFiles, createWebUrlMedia, cancelUpload };
