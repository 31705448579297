import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { inject } from "mobx-react";
import { JereerBrand } from "../JereerBrand";
import UserAvatar from "../UserAvatar/UserAvatar";
import Aaa from "./../../assets/jereerBeta.png";

class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      dropdownOpen1: false,
      dropdownOpenprofile: false,
      dropdownOpenbadge: false,
      now_route: "",
      notifications: [],
    };

    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleprofile = this.toggleprofile.bind(this);
    this.togglebadge = this.togglebadge.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  toggle1() {
    this.setState((prevState) => ({
      dropdownOpen1: !prevState.dropdownOpen1,
    }));
  }
  toggleprofile() {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  }
  togglebadge() {
    this.setState((prevState) => ({
      dropdownOpenbadge: !prevState.dropdownOpenbadge,
    }));
  }

  togglescreen(e) {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  componentDidMount() {
    this.setState({ now_route: this.props.location.pathname });
  }

  render() {
    return (
      <div className="topbar">
        <nav className="navbar-custom">
          <ul
            style={{ listStyle: "none" }}
            className="d-flex align-items-center dropdown float-right mb-0"
          >
            <li className="list-inline-item dropdown notification-list hidden-xs-down">
              <Link
                onClick={() => this.togglescreen()}
                className="nav-link waves-effect"
                to="#"
                id="btn-fullscreen"
              >
                <i className="mdi mdi-fullscreen noti-icon"></i>
              </Link>
            </li>

            <li className="notification-list">
              <UserAvatar full dark />
            </li>
          </ul>

          <ul className="app-header pl-3 list-inline menu-left mb-0 d-flex justify-content-start align-items-center">
            <li className="list-inline-item">
              <button
                onClick={() => this.props.layoutStore.toggleEnlarge()}
                type="button"
                className="button-menu-mobile open-left waves-effect"
              >
                <i className="ion-navicon"></i>
              </button>
            </li>
            <li className="d-block d-lg-none mr-3">
              <JereerBrand theme="light" />
            </li>
            <li className="hide-phone hide-tablet list-inline-item app-search">
              <h3 className="page-title d-lg-block d-none" id="now_routing">
                <img src={Aaa} width={150} className="mr-3" />
                {window.location.href.includes("/#/")
                  ? window.location.href
                      .split("/#/")[1]
                      .split("/")
                      .join(" / ")
                      .split("?")[0]
                  : ""}
              </h3>
            </li>
          </ul>
          <div className="clearfix"></div>
        </nav>
      </div>
    );
  }
}

export default inject("authStore", "layoutStore")(withRouter(header));
