import { MDBDataTable } from "mdbreact";
import { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import PaginationTable from "../../../components/PaginationTable";
import { Axios } from "../../../utils/http-common";

const Administrative = (props) => {
  return (
    <div className="row mt-5">
      <div className="col-12">
        <div className="card m-b-20">
          <div className="card-body">
            <PaginationTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Administrative;
