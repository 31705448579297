import firebase from "firebase/app";
import "firebase/auth";

// TODO: CHANGE FIREBASE DEVELOPMENT CONFIG
// const firebaseApp = firebase.initializeApp({
//     apiKey: "AIzaSyDQX5VSDltrlQR8NRJM2jWOnX2m9LROcRE",
//     authDomain: "jereerds.firebaseapp.com",
//     databaseURL: "https://jereerds-default-rtdb.firebaseio.com",
//     projectId: "jereerds",
//     storageBucket: "jereerds.appspot.com",
//     messagingSenderId: "1048431670996",
//     appId: "1:1048431670996:web:dabfedf36dee1c46d1ed41",
//     measurementId: "G-YQGKXE3PQ3"
// });
const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURE_ID,
});
/*
const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBxIsK0DTXTP7nT0K9aHvolaneOTulAVV8",
    authDomain: "jereer-identity.firebaseapp.com",
    databaseURL: "https://jereer-identity-default-rtdb.firebaseio.com",
    projectId: "jereer-identity",
    storageBucket: "jereer-identity.appspot.com",
    messagingSenderId: "783435736652",
    appId: "1:783435736652:web:2f685c846e7199d67876e9",
    measurementId: "G-CTYNP9ZQ01"
  });*/

export const auth = firebaseApp.auth();
