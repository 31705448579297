/* eslint-disable no-empty */
import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { CardHeader } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import { Field, Form, Formik } from "formik";
import * as Scheme from "yup";
import { inject, observer } from "mobx-react";
import { Axios } from "../../../../utils/http-common";
import { NotificationManager } from "react-notifications";
import md5 from "md5";
import SettingConfirmation from "./../../../../components/Dialogs/SettingConfirmation";
import { CircularProgress } from "@material-ui/core";
const UserInfoScheme = Scheme.object().shape({
  firstName: Scheme.string(),
  lastName: Scheme.string(),
  password: Scheme.string().required(),
});

const UserInfoForm = ({
  user,
  onSubmit = (values) => {},
  onReset = () => {},
}) => {
  const [agreementDialog, setAgreementDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const submit = async (values) => {
    setLoading(true);
    let newValues = {
      id: values.id,
      firstName: values.fullName.split(" ")[0],
      lastName: values.fullName.split(" ")[1]
    }
    setFormData({ ...newValues, password: await hashPassword(values.password) });
    setAgreementDialog(true);
  };

  const hashPassword = (password) => {
    const hashedPassword = md5(password);
    return hashedPassword;
  };
  const handleClose = () => {
    setAgreementDialog(false);
    setLoading(false);
  };
  const handleAgreement = () => {
    return Axios.patch("user/", formData)
      .then((res) => {
        onSubmit(res.data);
        NotificationManager.success("Your settings have been updated successfully");
      })
      .catch((e) => {
        NotificationManager.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Formik
      validationSchema={UserInfoScheme}
      initialValues={{
        ...user,
      }}
      validateOnChange
      onSubmit={submit}
      onReset={onReset}
    >
      {({ errors }) => (
        <Form>
          <SettingConfirmation
            handleClose={handleClose}
            handleAgreement={handleAgreement}
            open={agreementDialog}
          />
          <div className="form-group">
            <label>Full Name</label>
            <Field name="fullName" type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Password</label>
            <Field name="password" type="password" className="form-control" />
          </div>

          <div className="form-group">
            <Button variant={"outline-danger"} type="reset">
              <span>Cancel</span>
            </Button>
            <Button variant="success" type="submit" className="mr-2">
              {loading && (
                <>
                  {" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span>Loading...</span>
                </>
              )}
              {!loading && <span>Save</span>}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

/**
 * User Settings
 * Services and Intervals Settings
 */
const UserSettings = ({ authStore }) => {
  const [isEditingCompanyInfo, setisEditingCompanyInfo] = useState(false);
  const { user } = authStore;

  return (
    <div className="page-content-wrapper">
      <div className="mb-3 mt-3 container-fluid">
        <Row className="mb-2">
          <Col xs={8}>
            <h4 className="mt-0 header-title">
              <i className="fa fa-cogs mr-3"></i>User Settings
            </h4>
          </Col>
        </Row>
        <Row className="mb-2">
          <Card className="w-100">
            <CardHeader className="d-flex justify-content-between align-items-center">
              <div className="header-title">User Information</div>
              {!isEditingCompanyInfo && (
                <Button
                  onClick={() => {
                    setisEditingCompanyInfo(true);
                  }}
                  variant="info"
                >
                  <i className="fa fa-edit mr-2" />
                  Edit
                </Button>
              )}
            </CardHeader>

            <CardBody>
              {isEditingCompanyInfo ? (
                <UserInfoForm
                  onSubmit={(values) => {
                    setisEditingCompanyInfo(false);
                    authStore.fetchProfile();
                  }}
                  user={user}
                  onReset={() => setisEditingCompanyInfo(false)}
                />
              ) : (
                <>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Full Name</label>
                    <div className="col-sm-10">{user.fullName()}</div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Email</label>
                    <div className="col-sm-10">{user.email}</div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Password</label>
                    <div className="col-sm-10">*************</div>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Row>
      </div>
    </div>
  );
};

UserSettings.propTypes = {};

export default inject("authStore")(observer(UserSettings));
