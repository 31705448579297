import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Button, Col, Row } from "react-bootstrap";
import Location from "./Location";
import AddLocationModal from "./AddLocationModal";
import EditLocationModal from "./EditLocationModal";
import { Axios } from "../../../../utils/http-common";

const LocationsDevices = ({ locationsStore }) => {
  const { getRows, fetchLocations } = locationsStore;
  const [openAddLocationModal, setOpenAddLocationModal] = useState(false);
  const [editingLocation, setEditingLocation] = useState(null);
  const [countryName, setCountryName] = useState(null);

  useEffect(() => {
    getGeoInfo();
    return () => {};
  }, []);

  const getGeoInfo = () => {
    Axios.get("https://ipapi.co/json/")
      .then((response) => {
        if (response.data.country_name == "Israel") {
          setCountryName("Palestine");
        } else {
          setCountryName(response.data.country_name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div className="page-content-wrapper">
      <AddLocationModal
        show={openAddLocationModal}
        onClose={() => setOpenAddLocationModal(false)}
        countryName={countryName}
      />
      {editingLocation && (
        <EditLocationModal
          location={editingLocation}
          show
          onClose={() => setEditingLocation(null)}
        />
      )}
      <div className="mb-3 mt-3 container-fluid">
        <Row className="mb-2">
          <Col xs={7}>
            <h4 className="mt-0 header-title">
              <i className="fa fa-tv mr-2" />
              Locations and screens
            </h4>
            <p className="text-muted m-b-30 font-14">
              Locations list and screens, you will be able to add, edit or
              remove locations and screens. Be careful.
            </p>
          </Col>
          <Col xs={5}>
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  setOpenAddLocationModal(true);
                }}
                variant="dark"
              >
                <i className="fa fa-map-marker mr-2" />
                Add a location
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {getRows().map((location) => (
              <Location
                onRemove={() => {
                  locationsStore.deleteLocation(location.id);
                }}
                onLocationEdit={(location) => {
                  setEditingLocation(location);
                }}
                key={location.id}
                location={location}
              />
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

LocationsDevices.propTypes = {
  locationsStore: PropTypes.shape({
    locations: PropTypes.any,
    fetchLocations: PropTypes.func,
  }),
};

export default inject("locationsStore")(observer(LocationsDevices));
