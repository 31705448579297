import { types, flow } from "mobx-state-tree";
import { CampaignModel } from "../models/CampaignModel";
import { Axios } from "../utils/http-common";
import { NotificationManager } from "react-notifications";
import { authStore } from "./authStore";

const CreateCampaignStore = types
  .model("CreateCampaignStore", {
    campaign: types.optional(CampaignModel, {}),
  })
  .actions((self) => ({
    setCampaign: (campaign) => {
      self.campaign = campaign;
    },
    reset: () => {
      self.campaign = {};
    },
    submit: async (onSuccess = () => {}, onError = () => {}) => {
      if (!self.campaign.advertiser) {
        NotificationManager.error("Please make sure advertiser is selected.");
      } else if (!self.campaign.title.trim() || !self.campaign.startDate) {
        NotificationManager.error(
          "Please make sure campaign informations are entered."
        );
      } else if (self.campaign.locations.length === 0) {
        NotificationManager.error("Please make sure you selected locations.");
      } else if (
        self.campaign.reservedSlots.length === 0 ||
        self.campaign.reservedSlots.filter((slot) => slot.media === null)
          .length > 0
      ) {
        NotificationManager.error(
          "Please make sure you added slots to the campaign playlist."
        );
      } else if (
        self.campaign.reservedSlots.filter((slot) => slot.duration === 0)
          .length > 0
      ) {
        NotificationManager.error(
          "Please make sure the media slot duration is greater than 0 seconds."
        );
      } else if (
        self.campaign.reservedSlots.filter((slot) => isNaN(slot.duration))
          .length > 0
      ) {
        NotificationManager.error(
          "Please make sure the media slot duration is filled."
        );
      } else {
        const requestBody = {
          title: self.campaign.title,
          startDate: self.campaign.startDate,
          endDate: self.campaign.endDate,
          active: true,
          reservedSlots: self.campaign.reservedSlots.map((slot) => ({
            media: {
              id: slot.media.id,
            },
            interval: {
              id: slot.interval.id,
            },
            duration: slot.duration,
          })),
          locations: self.campaign.locations.map((location) => ({
            id: location.id,
          })),
          advertiser: {
            id: self.campaign.advertiser.id,
          },
          company: {
            id: authStore.user.parentCompany.id,
          },
        };

        return await Axios.post("/campaign", requestBody)
          .then((res) => {
            // trigger a success message
            NotificationManager.success(
              `Campaign ${res.data.title} created successfully`
            );
            self.reset();
            onSuccess();
          })
          .catch((error) => {
            // trigger an error message
            NotificationManager.error(error.message);
            onError();
          });
      }

      return null;
    },
    submitEdit: flow(function* (onSuccess = () => {}, onError = () => {}) {
      if (!self.campaign.advertiser) {
        NotificationManager.error("Please make sure advertiser is selected.");
      } else if (!self.campaign.title.trim() || !self.campaign.startDate) {
        NotificationManager.error(
          "Please make sure campaign information are entered."
        );
      } else if (self.campaign.locations.length === 0) {
        NotificationManager.error("Please make sure you selected locations.");
      } else if (
        self.campaign.reservedSlots.length === 0 ||
        self.campaign.reservedSlots.filter((slot) => slot.media === null)
          .length > 0
      ) {
        NotificationManager.error(
          "Please make sure you have chosen slots for the campaign."
        );
      } else if (
        self.campaign.reservedSlots.filter((slot) => slot.duration === 0)
          .length > 0
      ) {
        NotificationManager.error(
          "Please make sure the media slot duration is greater than 0 seconds."
        );
      } else if (
        self.campaign.reservedSlots.filter((slot) => isNaN(slot.duration))
          .length > 0
      ) {
        NotificationManager.error(
          "Please make sure the media slot duration is filled."
        );
      } else {
        const requestBody = {
          id: self.campaign.id,
          title: self.campaign.title,
          priority: self.campaign.priority,
          startDate: self.campaign.startDate,
          endDate: self.campaign.endDate,
          active: true,
          reservedSlots: self.campaign.reservedSlots.map((slot) => ({
            id: slot.id > 0 ? slot.id : undefined,
            media: {
              id: slot.media.id,
            },
            interval: {
              id: slot.interval.id,
            },
            duration: slot.duration,
          })),
          locations: self.campaign.locations.map((location) => ({
            id: location.id,
          })),
          advertiser: {
            id: self.campaign.advertiser.id,
          },
          company: {
            id: authStore.user.parentCompany.id,
          },
        };

        yield Axios.put("/campaign/" + self.campaign.id, requestBody)
          .then((res) => {
            // trigger a success message
            NotificationManager.success(
              `Campaign ${res.data.title} edited successfully`
            );
            onSuccess();
          })
          .catch((error) => {
            // trigger an error message
            NotificationManager.error(error.message);
            onError();
          });
      }

      return null;
    }),
  }));

export default CreateCampaignStore.create({
  campaign: {},
});
