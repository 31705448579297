import React, { useEffect, useState } from "react";
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";
import { Axios, source } from "../../../../utils/http-common";

const StatusBadge = props => {
  const status = props.status ? props.status.status : "unknown";
  let badge = <span className="badge badge-pill badge-dark">UNKNOWN</span>;

  if (status) {
    if (status.toLowerCase() === "active") {
      badge = <span className="badge badge-pill badge-success">ACTIVE</span>;
    } else if (status.toLowerCase() === "inactive") {
      badge = <span className="badge badge-pill badge-danger">INACTIVE</span>;
    }
  }

  return badge;
};

const DeviceStatusModal = props => {
  const { availableStorage, totalStorage, screenshot, status } = props.status;

  const { id } = props.device;
  if (!status || status.toLowerCase() === "unknown") return null;

  const [activeCampaigns, setActiveCampaigns] = useState([]);

  useEffect(() => {
    // const cancelTokenSource = source();
    Axios.get("/device/" + id + "/activeCampaigns", {
      // cancelToken: cancelTokenSource.token
    }).then(res => {
      setActiveCampaigns(res.data);
    });
    return () => {
      // cancelTokenSource.cancel();
    };
  }, []);

  return (
    <Modal centered isOpen={props.open} className="dark">
      <ModalHeader>
        <p className="m-0 mr-2">
          <b>{props.device.deviceName}</b> screen
        </p>
        <StatusBadge status={props.status} />
        <Button
          style={{
            position: "absolute",
            top: 10,
            right: 5
          }}
          size={"sm"}
          onClick={props.toggleModal}
          variant="danger"
        >
          <i style={{ color: "white" }} className="fa fa-close" />
        </Button>
      </ModalHeader>
      <ModalBody>
        <Row className="mt-3">
          <Col sm={12}>
            <Card>
              <CardBody>
                <i className="fa fa-bullhorn mr-2" />
                <p className="m-0 mb-3">Playing campaigns</p>
                {activeCampaigns.length > 0 ? (
                  activeCampaigns.map(e => (
                    <Card className="border-0 mb-1" key={e.id}>
                      <CardBody className={"d-flex p-3"}>
                        <span>{e.title}</span>
                      </CardBody>
                    </Card>
                  ))
                ) : (
                  <p className="m-0 text-muted">
                    No campaigns found playing on this device currently
                  </p>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12}>
            <Card
              className={
                (totalStorage - availableStorage) / totalStorage > 0.8
                  ? "danger"
                  : null
              }
            >
              <CardBody>
                <i className="fa fa-hdd mr-2" />
                <p className="m-0">Available Storage</p>
                <h4>
                  <b>{availableStorage}</b>GB
                </h4>
                <ProgressBar
                  min={0}
                  now={totalStorage - availableStorage}
                  max={totalStorage}
                  variant={
                    (totalStorage - availableStorage) / totalStorage > 0.8
                      ? "danger"
                      : "success"
                  }
                  label={`${totalStorage - availableStorage}/${totalStorage}`}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {screenshot && (
          <Row className="mt-3">
            <Col sm={12}>
              <img
                style={{ borderRadius: 10, width: "100%" }}
                className="img-fluid"
                src={"data:image/jpeg;base64, " + screenshot}
              />
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

export class DeviceStatusBadge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusOpen: false
    };
  }

  toggleStatusModal = () => {
    console.log("toggling");
    this.setState(prevState => ({
      statusOpen: !prevState.statusOpen
    }));
  };

  deviceStatus(status, device) {
    if (status.status !== "active") return null
    return (
      <DeviceStatusModal
        toggleModal={this.toggleStatusModal}
        open={this.state.statusOpen}
        status={status}
        device={device}
      />
    );
  }

  render() {
    return (
      <>
        <StatusBadge status={this.props.status} />
        {this.props.status.status === "active" && <Button size={"sm"} onClick={this.toggleStatusModal} variant="">
          <i style={{ color: "black" }} className="fa fa-arrow-circle-right" />
        </Button>}
        {this.deviceStatus(this.props.status, this.props.device)}
      </>
    );
  }
}
