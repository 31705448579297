import { types } from "mobx-state-tree";

export const CompanyModel = types.model("CompanyModel", {
  id: types.identifierNumber,
  companyName: types.string,
  description: types.string,
  address: types.string,
  classic: types.boolean,
  subscription_id:types.string,
});
