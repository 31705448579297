import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";
import { MDBDataTable } from "mdbreact";
import { Row, Button, Col } from "react-bootstrap";
import AddAdvertiserModal from "./AddAdvertiserModal";
import EditAdvertiserModal from "./EditAdvertiserModal";
import { autorun } from "mobx";

const Advertiser = ({ advertiserStore, match, history }) => {
  const { advertiserRows = [] } = advertiserStore;

  const [editingAdvertiser, setEditingAdvertiser] = useState(null);
  const [addAdvertiser, setAddAdvertiser] = useState(false);

  useEffect(() => {
    autorun(() => {
      const advertiserParam = match.params.advertiserId;
      if (advertiserParam) {
        const advertiser = advertiserStore.getAdvertiser(advertiserParam);
        if (advertiser)
          setEditingAdvertiser(advertiser);
      }
    })
    return () => {};
  }, []);

  const columns = [
    {
      label: "",
      field: "image",
      sort: "asc",
      width: 50
    },
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 270
    },
    {
      label: "Phone Number",
      field: "phone",
      sort: "asc",
      width: 200
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
      width: 100
    },
    {
      label: "Address",
      field: "address",
      sort: "asc",
      width: 100
    },
    {
      label: "",
      field: "actions",
      sort: "asc",
      width: 150
    }
  ];

  return (
    <div className="page-content-wrapper">
      <AddAdvertiserModal
        show={addAdvertiser}
        onClose={() => {
          setAddAdvertiser(false);
          advertiserStore.fetchAdvertisers(true);
        }}
      />

      {editingAdvertiser && (
        <EditAdvertiserModal
          advertiser={editingAdvertiser}
          show
          onClose={() => {
            setEditingAdvertiser(null);
            advertiserStore.fetchAdvertisers(true);
            history.push("/digital-signage/advertiser");
          }}
        />
      )}
      <div className="container-fluid">
        <div className="mb-3 mt-3 row">
          <div className="col-12">
            <h4 className="mt-0 header-title">
              <i className="fa fa-users mr-3"></i>Advertisers
            </h4>
            <p className="text-muted m-b-30 font-14">
              advertisers are people or entities that advertise on your devices.
              they can't do that by themselves, you have to create campaign for
              them.
            </p>
            <div className="card m-b-20">
              <div className="card-body">
                <Row className="d-fex">
                  <Col md={6}></Col>
                  <Col md={6} className="d-flex justify-content-end">
                    <Button
                      onClick={() => setAddAdvertiser(true)}
                      variant={"dark"}
                      className="mr-2"
                    >
                      <i className="fa fa-plus mr-2"></i>
                      Add Advertiser
                    </Button>

                    <Button
                      onClick={() => advertiserStore.fetchAdvertisers(true)}
                      variant={"primary"}
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </Col>
                </Row>
                <MDBDataTable
                  sortable
                  exportToCSV
                  noBottomColumns
                  striped
                  hover
                  data={{ columns, rows: [...advertiserRows] }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("advertiserStore")(observer(Advertiser));
