import { useEffect, useState } from "react";
import { Axios } from "../../utils/http-common";
import PaginationTableUI from "./components/PaginationTableUI";
import { Button } from "reactstrap";
const PaginationTableContainer = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagesAmount, setPagesAmount] = useState();
  const [textSearch, setTextSearch] = useState("");

  const columns = [
    {
      label: "Full Name",
      field: "fullName",
      sort: "desc",
      width: 50,
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
      width: 50,
    },
    {
      label: "CompanyName",
      field: "companyName",
      sort: "asc",
      width: 50,
    },
    {
      label: "Act Like",
      field: "actLike",
      sort: "asc",
      width: 50,
    },
  ];

  useEffect(() => {
    setLoading(true);
    getClientsOnPage(pageNumber).then(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    getClientsOnPage(pageNumber, textSearch).then(() => setLoading(false));
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber == 1) {
      getClientsOnPage(pageNumber, textSearch).then(() => setLoading(false));
    }
    setPageNumber(1);
  }, [textSearch]);

  const changePageNumber = (pageNum) => {
    setPageNumber(pageNum);
  };

  const changeTextSearch = (textToSearch) => {
    setTextSearch(textToSearch);
  };

  const getClientsOnPage = async (pageNum, textToSearchFor) => {
    await Axios.post(
      `/user/getCustomersList/`,
      JSON.stringify({ page: `${pageNum}`, searchText: `${textToSearchFor}` })
    )
      .then((rez) => {
        console.log(rez);

        setPagesAmount(Math.ceil(rez.data.count / 20));
        setRows(
          rez.data.data.map((user) => {
            return {
              email: user.email,
              companyName: user.parentCompany.companyName,
              fullName: `${user.firstName} ${user.lastName}`,
              actLike: (
                <Button
                  onClick={(e) => {
                    var d = e.target.getAttribute("data-id");
                    Axios(
                      `/auth/getCustomerSigninToken/${e.target.getAttribute(
                        "data-id"
                      )}`
                    )
                      .then((rez) => {
                        const adminUserInfo = JSON.parse(localStorage.userInfo);
                        const adminAccessTocken = localStorage.accessToken;
                        localStorage.clear();
                        localStorage.setItem(
                          "userInfo",
                          JSON.stringify({ ...rez.data.user })
                        );
                        localStorage.setItem(
                          "accessToken",
                          rez.data.accessToken
                        );
                        localStorage.setItem(
                          "adminInfo",
                          JSON.stringify({ ...adminUserInfo })
                        );
                        localStorage.setItem(
                          "AdminAccessToken",
                          adminAccessTocken
                        );
                      })
                      .then(() => window.location.replace("/"));
                  }}
                  size="sm"
                  data-id={user.id}
                >
                  LogIn
                </Button>
              ),
            };
          })
        );
      })
      .catch(() => (pageNumber == "" ? setPageNumber("") : null));
  };
  return (
    <PaginationTableUI
      columns={columns}
      rows={rows}
      pageNumber={pageNumber}
      changePageNumber={changePageNumber}
      pagesAmount={pagesAmount}
      loading={loading}
      changeTextSearch={changeTextSearch}
    />
  );
};
export default PaginationTableContainer;
