import React, {  } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import * as Scheme from "yup";
import { Axios } from "../../../../utils/http-common";
import ClassNames from "classnames";
import { NotificationManager } from "react-notifications";

const IntervalFormScheme = Scheme.object().shape({
  name: Scheme.string(),
  itemTime: Scheme.number(),
  overallTime: Scheme.number(),
  service: Scheme.object().shape({ id: Scheme.number() })
});

const AddIntervalModal = ({ show, onClose, service }) => {
  const onSubmit = values => {
    Axios.post("/interval", {
      ...values,
      service: {id: service.id}
    }).then(value => {
      NotificationManager.success(
        `Service ${value.data.name} added successfully`
      );
      onClose(value.data);
    });
  };
  return (
    <Modal isOpen={show}>
      <ModalHeader>Add interval to {service.name}</ModalHeader>
      <ModalBody>
        <Formik
          validationSchema={IntervalFormScheme}
          initialValues={{
            name: "",
            itemTime: 10,
            overallTime: 1,
          }}
          validateOnChange
          onSubmit={values => onSubmit(values)}
        >
          {({ errors }) => (
            <Form>
              <div className="form-group">
                <label>Name</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.name
                  })}
                  name="name"
                  type="text"
                  placeholder="E.g: Interval 1"
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Item Duration (Seconds)</label>
                    <Field
                      className={ClassNames("form-control", {
                        "is-invalid": errors.itemTime
                      })}
                      name="itemTime"
                      type="number"
                      placeholder="0"
                    />
                    {errors.itemTime && (
                      <div className="invalid-feedback">{errors.itemTime}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Overall Duration (Minutes)</label>
                    <Field
                      className={ClassNames("form-control", {
                        "is-invalid": errors.overallTime
                      })}
                      name="overallTime"
                      type="number"
                      placeholder="0"
                    />
                    {errors.overallTime && (
                      <div className="invalid-feedback">
                        {errors.overallTime}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2"
                  type="reset"
                  onClick={() => onClose()}
                  variant="outline-danger"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="success">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

AddIntervalModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  service: PropTypes.any
};

AddIntervalModal.defaultProps = {
  show: false,
  onClose: () => {}
};

export default AddIntervalModal;
