import PrivateRoute from "./PrivateRoute";
import { Redirect } from "react-router-dom";
import { Axios } from "../../utils/http-common";
import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";

const AdminRoute = (props) => {
  const [isAdmin, setIsAdmin] = useState(0);
  const [fallBack, setFallBack] = useState(0);
  useEffect(() => {
    Axios("/user").then((rez) => {
      setIsAdmin(rez.data.is_admin);
      setFallBack(1);
    });
  }, []);

  return fallBack == 1 ? (
    isAdmin ? (
      <PrivateRoute {...props} is_admin={1} />
    ) : (
      <Redirect to="/digital-signage/home" />
    )
  ) : (
    <ProgressBar />
  );
};

export default AdminRoute;
