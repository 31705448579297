import { types } from "mobx-state-tree";
import { ServiceModel } from "./ServiceModel";

export const IntervalModel = types.model("IntervalModel", {
  id: types.identifierNumber,
  name: types.string,
  itemTime: types.number,
  overallTime: types.number,
  service: types.optional(types.maybeNull(ServiceModel), null)
});
