import { MDBDataTable } from "mdbreact";
import { Button } from "reactstrap";
import {
  Modal,
  TextField,
  TablePagination,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "react-responsive-pagination";

const PaginationTableUI = ({
  columns,
  rows,
  pageNumber,
  pagesAmount,
  changePageNumber,
  loading,
  changeTextSearch,
}) => {
  return (
    <div>
      <div style={{ maxHeight: "110em" }}>
        <MDBDataTable
          paging={false}
          data={{
            columns,
            rows: rows,
          }}
          noBottomColumns
          onSearch={(e) => {
            changeTextSearch(e);
          }}
        />
      </div>
      {/* <div>
        <span>{rows.length}</span>
      </div>
      <div>
        <span>{`${pageNumber} - ${pagesAmount}`}</span>
      </div>
      <TextField
        type="number"
        id="outlined-basic"
        value={pageNumber}
        onChange={(e) => {
          changePageNumber(e.target.value);
        }}
        variant="outlined"
      />
      <br />
      <Button color="primary" size="sm" onClick={() => changePageNumber(1)}>
        <span aria-hidden="true">&lt;</span>
        <span aria-hidden="true">&lt;</span>
      </Button>
      <Button
        color="primary"
        size="sm"
        onClick={() =>
          pageNumber > 1 ? changePageNumber(Number(pageNumber) - 1) : null
        }
      >
        <span aria-hidden="true">&lt;</span>
      </Button>
      <span> {pageNumber} </span>
      <Button
        color="primary"
        size="sm"
        onClick={() =>
          pageNumber < pagesAmount
            ? changePageNumber(Number(pageNumber) + 1)
            : null
        }
      >
        <span aria-hidden="true">&gt;</span>
      </Button>
      <Button
        color="primary"
        size="sm"
        onClick={() => changePageNumber(pagesAmount)}
      >
        <span aria-hidden="true">&gt;</span>
        <span aria-hidden="true">&gt;</span>
      </Button> */}
      <Pagination
        maxWidth={500}
        current={pageNumber}
        total={pagesAmount}
        onPageChange={(e) => {
          changePageNumber(e);
        }}
      />
    </div>
  );
};
export default PaginationTableUI;
