import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { LocationSelect } from "../../../components/LocationSelect";
import { SlotsReserver } from "../../../components/SlotsReserver";
import { inject, observer } from "mobx-react";
import { AdvertiserSelect } from "../../../components/AdvertiserSelect/AdvertiserSelect";
import { autorun } from "mobx";
import { withRouter } from "react-router-dom";
import { CampaignMedia } from "../../../components/CampaignMedia";
import AddLocationModal from "../Settings/LocationsDevices/AddLocationModal";
import { Axios } from "../../../utils/http-common";

/**
 * create a campaign page
 */

const CreateCampaign = ({
  createCampaignStore,
  advertiserStore,
  match,
  authStore,
  history,
}) => {
  const [openAddLocationModal, setOpenAddLocationModal] = useState(false);
  const [countryName, setCountryName] = useState(null);

  const onTitleChange = (newTitle) => {
    createCampaignStore.campaign.setTitle(newTitle);
  };
  const getGeoInfo = () => {
    Axios.get("https://ipapi.co/json/")
      .then((response) => {
        if (response.data.country_name == "Israel") {
          setCountryName("Palestine");
        } else {
          setCountryName(response.data.country_name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {}, [openAddLocationModal]);
  const [mediaLibrary, setMediaLibrary] = useState([]);
  const [displayEndDate, setDisplayEndDate] = useState(false);
  const [locationUpdated, setLocationUpdated] = useState(false);
  const [init, setInit] = useState([]);

  useEffect(() => {
    createCampaignStore.reset();
    autorun(() => {
      const preSelectedAdvertiser = advertiserStore.getAdvertiser(
        match.params.advertiserId
      );
      createCampaignStore.campaign.setAdvertiser(preSelectedAdvertiser);
      if (createCampaignStore.campaign.startDate == null) {
        createCampaignStore.campaign.setStartDate(new Date());
      }
    });
    getGeoInfo();
    return () => {};
  }, []);

  useEffect(() => {
    if (!displayEndDate) {
      createCampaignStore.campaign.setEndDate(null);
    } else {
      createCampaignStore.campaign.setEndDate(
        new Date(createCampaignStore.campaign.startDate).setDate(
          new Date(createCampaignStore.campaign.startDate).getDate() + 30
        )
      );
    }
  }, [displayEndDate, createCampaignStore.campaign.startDate]);

  return (
    <Fragment>
      <AddLocationModal
        show={openAddLocationModal}
        onClose={() => {
          setLocationUpdated((oldValue) => (oldValue ? false : true));
          setOpenAddLocationModal(false);
        }}
        countryName={countryName}
      />
      <div className="page-content-wrapper">
        <div className="mb-3 mt-3 container-fluid">
          <div className="row">
            <div className="col-12">
              <h4 className="mt-0 header-title">Create New Campaign</h4>
              <p className="text-muted font-14 mb-4">
                follow the steps to create a new campaign
              </p>
              <div
                className={authStore.company.classic ? "d-none" : "card m-b-20"}
              >
                <div className="card-body">
                  <h4 className="mt-0 header-title">advertiser</h4>
                  <p className="text-muted m-b-30 font-14">
                    Campaigns are associated with advertiser
                  </p>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">
                      Advertiser
                    </label>
                    <div className="col-sm-10">
                      <AdvertiserSelect
                        disabled={authStore.company.classic}
                        pickFirst={authStore.company.classic}
                        value={createCampaignStore.campaign.advertiser}
                        onChange={(advisor) => {
                          createCampaignStore.campaign.setAdvertiser(advisor);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card m-b-20">
                <div className="card-body">
                  <h4 className="mt-0 header-title">Campaign Info</h4>
                  <p className="text-muted m-b-30 font-14">
                    Set the start and end date of campaign and the needed info.
                  </p>

                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Campaign Name
                    </label>
                    <div className="col-sm-12">
                      <input
                        value={createCampaignStore.campaign.title}
                        onChange={(e) => onTitleChange(e.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <Row>
                    <Col md={6}>
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Start Date
                        </label>
                        <div className="col-sm-12">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            selected={createCampaignStore.campaign.startDate}
                            defaultDate={createCampaignStore.campaign.startDate}
                            onChange={(date) => {
                              createCampaignStore.campaign.setStartDate(date);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          End Date
                        </label>
                        <div className="col-sm-12">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            disabled={!displayEndDate}
                            selected={
                              displayEndDate
                                ? createCampaignStore.campaign.endDate
                                : null
                            }
                            onChange={(date) => {
                              createCampaignStore.campaign.setEndDate(date);
                              if (!createCampaignStore.campaign.endDate) {
                                createCampaignStore.campaign.setEndDate(
                                  new Date(
                                    createCampaignStore.campaign.startDate
                                  ).setDate(new Date().getDate() + 30)
                                );
                              }
                            }}
                            minDate={createCampaignStore.campaign.startDate}
                          />
                        </div>
                      </div>
                      <div className="form-check row">
                        <div className="col-sm-12">
                          <FormControlLabel
                            label="No end date?"
                            control={
                              <Checkbox
                                color="primary"
                                onChange={() => {
                                  setDisplayEndDate(!displayEndDate);
                                }}
                                checked={
                                  createCampaignStore.campaign.endDate == null
                                    ? true
                                    : false
                                }
                              />
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="card m-b-20">
                <div className="card-body">
                  <Row>
                    <Col>
                      <h4 className="mt-0 header-title">Campaign Locations</h4>
                    </Col>
                    <Col md={50}>
                      <Button
                        onClick={() => {
                          setOpenAddLocationModal(true);
                        }}
                        variant="dark"
                      >
                        <i className="fa fa-map-marker mr-2" />
                        Add a location
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="text-muted m-b-30 font-14">
                        Campaign will be delivered and shown to the selected
                        locations devices.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <label className="col-sm-2 col-form-label">Locations</label>

                    <Col md={10}>
                      <LocationSelect
                        locationUpdated={locationUpdated}
                        initialValue={
                          createCampaignStore.campaign.locations.toJSON()
                            ? createCampaignStore.campaign.locations.toJSON()
                            : init
                        }
                        onChange={(selected) => {
                          createCampaignStore.campaign.setLocations(selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>

              {createCampaignStore.campaign.advertiser && (
                <CampaignMedia
                  advertiser={createCampaignStore.campaign.advertiser}
                  onChange={(media = []) => {
                    setMediaLibrary(media);
                  }}
                />
              )}

              <div className="card m-b-20">
                <div className="card-body">
                  <h4 className="mt-0 header-title">Campaign Playlist</h4>
                  <p className="text-muted m-b-10 font-14">
                    Duration in seconds.
                  </p>
                  <SlotsReserver mediaLibrary={mediaLibrary} />
                </div>
              </div>
              <Button
                variant="info"
                onClick={() => history.push("/digital-signage/campaign/all")}
              >
                Cancel
              </Button>
              <Button
                variant="success"
                onClick={() =>
                  createCampaignStore.submit(() => {
                    history.push("/digital-signage/campaign/all");
                  })
                }
              >
                Publish
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(
  inject(
    "createCampaignStore",
    "advertiserStore",
    "authStore"
  )(observer(CreateCampaign))
);
