import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import { inject, observer } from "mobx-react";

const CampaignSelect = inject("campaignStore")(
    observer(({ onChange = (campaign) => {}, value = undefined, campaignStore }) => {

        const { getCampaigns } = campaignStore;

        return (
            <ReactSelect
                value={value}
                getOptionLabel={(option) => option.title}
                getOptionValue={(option) => option}
                onChange={v => {
                    onChange(v);
                }}
                options={getCampaigns}
            />
        );
    })
);
export { CampaignSelect };
