import firebase from 'firebase/app';
import "firebase/auth"
import { auth } from '../utils/config';

export const register = async({email, password})=>{
    const resp = await firebase.auth()
      .createUserWithEmailAndPassword(email, password).then(res => {
        return res;
      }).catch(error => {
        console.log(error);
      })
    return resp.user;
}
  
export const login = async({email, password})=>{
    const res = await firebase.auth()
      .signInWithEmailAndPassword(email, password);
    return res.user;
}

export const getPasswordResetEmail = async({email})=>{
  const res = await firebase.auth()
    .sendPasswordResetEmail(email);
  return res;
}

export const handleResetPassword = async({newPassword, actionCode})=>{
  const res = await firebase.auth().verifyPasswordResetCode(actionCode).then(async(email) => {
    await firebase.auth().confirmPasswordReset(actionCode, newPassword);
  }).catch((error) => {
    console.log(error)
  })
  return res;
}