import {
  AppBar,
  Card,
  CardContent,
  Divider,
  Fade,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import EliseContainer from "../../ElipseTypo/ElipseContainer";
import { absluteBottomRight, scrollbarc } from "./styles";
const FileTrackerUI = ({ show, onClose, title, files }) => {
  return (
    <div style={absluteBottomRight}>
      <Fade in={show}>
        <Paper style={{ maxWidth: "350px" }}>
          <Card>
            <AppBar
              position="static"
              style={{ background: "#36a9e0" }}
              className="p-3"
            >
              <Grid container>
                <Grid item xs={6}>
                  <Typography style={{ display: "inline" }} color="inherit">
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ display: "inline" }}>
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </Grid>
              </Grid>
            </AppBar>
            <CardContent style={scrollbarc}>
              {files.map((file) => {
                return (
                  <div key={`div-fileTracker${file.id}`}>
                    <Grid container className="m-2 mt-2">
                      <Grid
                        item
                        xs={4}
                        style={{
                          maxWidth: "70px",
                          maxHeight: "60px",
                          overflow: "hidden",
                        }}
                      >
                        <Grid container justify="center">
                          <div className=" ">
                            <div className="border dz-processing dz-image-preview dz-success dz-complete">
                              {file.type.includes("video") && (
                                <video width="45px" controls>
                                  <source
                                    src={file.preview + "#t=0.50"}
                                    type={file.type}
                                  ></source>
                                </video>
                              )}
                              {file.type.includes("image") && (
                                <img
                                  data-dz-thumbnail=""
                                  width="45px"
                                  className="avatar-sm rounded bg-light"
                                  alt={file.name}
                                  src={file.preview}
                                />
                              )}
                              {!file.type.includes("image") &&
                                !file.type.includes("video") && (
                                  <img
                                    data-dz-thumbnail=""
                                    width="45px"
                                    className="avatar-sm rounded bg-light"
                                    src={""}
                                  />
                                )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container justify="flex-start">
                          {file.type.includes("image") ||
                          file.type.includes("video") ? (
                            <EliseContainer
                              style={{ padding: "5px", paddingTop: "10px" }}
                              size={12}
                            >
                              {file.name}
                            </EliseContainer>
                          ) : (
                            <EliseContainer
                              style={{ padding: "5px", paddingTop: "10px" }}
                              size={10}
                            >
                              {file.name}
                            </EliseContainer>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container justify="flex-end">
                          {!file.type.includes("image") &&
                            !file.type.includes("video") && (
                              <div
                                style={{
                                  margin: 0,

                                  textAlign: "left",
                                }}
                              >
                                <Chip
                                  style={{
                                    background: "red",
                                    color: "white",
                                    marginLeft: "1em",
                                  }}
                                  label="Decline"
                                />
                              </div>
                            )}
                          {file.uploadProgress == 100 ? (
                            <Chip
                              style={{ background: "green", color: "white" }}
                              label="Done"
                            />
                          ) : (
                            <CircularProgress
                              style={{ color: "#36a9e0" }}
                              thickness={5}
                              variant="static"
                              value={file.uploadProgress}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />
                  </div>
                );
              })}
            </CardContent>
          </Card>
        </Paper>
      </Fade>
    </div>
  );
};

export default FileTrackerUI;
