import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import ClassNames from "classnames";
import * as Scheme from "yup";
import { Axios } from "../../../../utils/http-common";

const CreatePlaylistScheme = Scheme.object().shape({
  type: Scheme.string().required()
});

export const CreatePlaylist = ({ editing = false, playlist = {}, onSuccess = playlist => {}, onClose = () => {}, onCancel = () => { console.log("cancel") } }) => {
  const submit = values => {
    console.log("submitting");
    if (editing) {
        Axios.put("/playlist/" + playlist.id, {
          ...values
        })
          .then(res => {
            onSuccess(res.data);
            onClose();
          })
          .catch(error => {
            NotificationManager.error("Could not save the playlist.");
          });
        return;
    }
    Axios.post("/playlist", {
      ...values
    })
      .then(res => {
        onSuccess(res.data);
        onClose();
      })
      .catch(error => {
        NotificationManager.error("Could not save the playlist.");
      });
  };

  return (
    <div style={{ background: "rgb(238 247 255)" }} className="card mb-3">
      <div className="card-header m-0 border-0">
        <div className="header-title">{editing ? "Edit Playlist" : "New Playlist"}</div>
      </div>
      <div className="card-body d-flex justify-content-between">
        <Formik
          initialValues={{
            type: editing ? playlist.type : null
          }}
          onSubmit={values => submit(values)}
          onCancel={() => onClose()}
          validationSchema={CreatePlaylistScheme}
        >
          {({ values, errors, isValid, isSubmitting }) => {
            return (
              <Form className="d-flex align-items-end justify-content-between w-100">
                <div
                  style={{ flexGrow: 1 }}
                  className="form-group m-0 d-flex justify-content-start align-items-center"
                >
                  <label className="m-0 mr-4" htmlFor="type">
                    Type
                  </label>
                  <Field
                    className={ClassNames("form-control", {
                      "is-invalid": errors.type
                    })}
                    name="type"
                    type="text"
                    placeholder="E.g: HDMI"
                  />
                </div>
                <div className="ml-2">
                  {isSubmitting && <Spinner />}
                  <Button
                    outline
                    variant="danger"
                    size="sm"
                    className="m-0 mr-1"
                    onClick={ () => onClose() }
                  >
                    Cancel
                  </Button>
                  <Button
                    outlined
                    variant="success"
                    size="sm"
                    className="m-0"
                    disabled={!isValid || isSubmitting}
                    type="submit"
                  >
                    <i className="fa fa-save mr-2" />
                    Submit
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
