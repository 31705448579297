import React, { useState, useEffect } from "react";
import { Axios, source, variables } from "../../../utils/http-common";
import { authStore } from "../../../store/authStore";
import moment from "moment";
import { Spinner } from "reactstrap";
import { MDBDataTable } from "mdbreact";

/**
 * inactive devices list
 */
export const InactiveDevices = () => {
  const [devices, setDevices] = useState(null);
  const [mappedDevices, setMappedDevices] = useState(null);

  const columns = [
    {
      label: "Screen Name",
      field: "deviceName",
      sort: "asc",
      width: 150,
    },
    {
      label: "Screen Status",
      field: "status",
      sort: "asc",
      width: 50,
    },
    {
      label: "Last Active",
      field: "last_active",
      sort: "asc",
      width: 50,
    },
  ];

  const fetchDevices = () => {
    Axios.get("dashboard/dead", {}).then((res) => {
      setDevices(res.data);
      getNewDevices(res.data);
    });
  };

  const getNewDevices = (tempDevices) => {
    setMappedDevices(tempDevices);
  };

  useEffect(() => {
    // get all dead devices
    fetchDevices();

    // setInterval(() => {
    //   fetchDevices();
    // }, variables().periodic_interval);
  }, []);

  if (devices === null) {
    return (
      <div className="card m-b-20">
        <div
          style={{ minHeight: 200 }}
          className="card-body pt-0 justify-content-center d-flex align-items-center"
        >
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card m-b-20">
          <div className="card-body">
            <MDBDataTable
              sortable
              exportToCSV
              hover
              data={{
                columns,
                rows: [
                  ...devices.map((device) => ({
                    deviceName: device.deviceName,
                    status:
                      Date.now() - device.layoutLastUpdated >= 15000 ? (
                        <div style={{ color: "red" }}>Offline</div>
                      ) : (
                        <div style={{ color: "green" }}>Online</div>
                      ),
                    last_active: moment(device.layoutLastUpdated).fromNow(),
                  })),
                ],
              }}
              striped
              noBottomColumns
            />
          </div>
        </div>
      </div>
    </div>
  );
};
