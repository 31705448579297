import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import PropType from "prop-types";
import { DeviceStatusBadge } from "./DeviceStatus";
import SweetAlert from "react-bootstrap-sweetalert";
import { Axios } from "../../../../utils/http-common";
import { NotificationManager } from "react-notifications";
import { noop } from "lodash";

export function Device(props) {
  const [deletingDevice, setDeletingDevice] = useState(false);
  const [state, setState] = useState({
    status: "unknown",
    availableStorage: 0,
    totalStorage: 0,
    onRemove: noop,
  });

  return (
    <tr>
      <td hidden>
        <DeviceStatusBadge device={props.device} status={state} />
      </td>
      <td colSpan="2">{props.device.deviceName}</td>
      <td>
        {props.device.orientation == 0
          ? "Landscape"
          : props.device.orientation === 1
          ? "Portrait"
          : props.device.orientation == 8
          ? "Reverse Landscape"
          : "Reverse Portrait"}
      </td>
      <td>{props.device.services}</td>
      <td>
        <Button variant="info" size="sm" onClick={props.onClick}>
          <i className="fa fa-edit" />
        </Button>
        <Button
          variant="danger"
          size="sm"
          onClick={() => setDeletingDevice(true)}
        >
          <i className="fa fa-remove" />
        </Button>
        {deletingDevice && (
          <SweetAlert
            show
            type="danger"
            title="Are you sure?"
            onConfirm={() => {
              setDeletingDevice(false);
              props.onClick1(props.device);
            }}
            onCancel={() => setDeletingDevice(false)}
            showCancel
            cancelBtnBsStyle="primary"
            confirmBtnBsStyle="danger"
            focusConfirmBtn={false}
          >
            You will not be able to revert changes.
          </SweetAlert>
        )}
      </td>
    </tr>
  );
}

Device.propTypes = {
  device: PropType.any,
  onClick: PropType.func,
  onClick1: PropType.func,
};
