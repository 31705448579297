import { types, flow } from "mobx-state-tree";
import { Axios } from "../utils/http-common";
import {} from "lodash";
import { UserModel } from "../models/UserModel";
import advertiserStore from "../store/advertiserStore";
import {
  login,
  handleResetPassword,
  getPasswordResetEmail,
} from "../services/Auth";

const AuthStore = types
  .model("AuthStore", {
    user: types.optional(types.maybeNull(UserModel), null),
    isLoggedIn: types.optional(types.boolean, false),
  })
  .actions((self) => {
    return {
      setUser: (user) => {
        self.user = user;
      },
      login: flow(function* (email, password) {
        try {
          const firebaseRes = yield login({ email, password });
          const firebaseParse = JSON.parse(JSON.stringify(firebaseRes));
          const firebaseUID = firebaseParse.uid;
          const res = yield Axios.post("/auth/user/login", {
            username: email,
            password: password,
            firebaseUID: firebaseUID,
          });
          self.setUser(res.data.user);
          localStorage.setItem("userInfo", JSON.stringify({ ...self.user }));
          localStorage.setItem("accessToken", res.data.accessToken);
          self.isLoggedIn = true;
        } catch (e) {
          console.error("e");
          throw e;
        }
      }),
      signup: flow(function* (newUser) {
        try {
          const res = yield Axios.post("/user/register", {
            ...newUser,
          });
        } catch (e) {
          throw e;
        }
      }),
      recoverPassword: flow(function* (email) {
        try {
          const res = yield getPasswordResetEmail({ email });
        } catch (e) {
          throw e;
        }
      }),
      resetPassword: flow(function* (newPassword, actionCode) {
        try {
          const res = yield handleResetPassword({ newPassword, actionCode });
        } catch (e) {
          throw e;
        }
      }),

      validateUser: () => {
        try {
          const accessToken = localStorage.getItem("accessToken");
          const userInfo = localStorage.getItem("userInfo");

          if (userInfo != null) {
            //await self.fetchProfile();
            self.setUser(JSON.parse(userInfo));
            advertiserStore.fetchAdvertisers();
            self.isLoggedIn = true;
            return true;
          } else {
            self.setUser(null);
          }
          return false;
        } catch (error) {
          localStorage.clear();
          return false;
        }
      },

      logout: () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userInfo");
        self.setUser(null);
        window.location.href = "/";
      },

      fetchCompany: () => {
        Axios.get("/company/" + self.company.id).then((res) => {
          self.user.setCompany(res.data);
        });
      },

      fetchProfile: async () => {
        return Axios.get("user")
          .then((res) => {
            self.setUser(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    };
  })
  .views((self) => ({
    get company() {
      if (self.user) {
        return self.user.parentCompany;
      }
      return null;
    },

    get accessToken() {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) return null;
      return accessToken;
    },
  }));

export const authStore = AuthStore.create({});
