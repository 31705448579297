import React, { Fragment } from "react";
import Layout from "./components/Layout/Layout";
import { Route, Switch, Redirect } from "react-router-dom";
import MainBuilder from "./containers/mainbuilder/mainBuilder";
import { withRouter, useLocation } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import "react-datepicker/dist/react-datepicker.css";
import AdminRoute from "./containers/mainbuilder/AdminRoute";

const App = observer(({ authStore }) => {
  let layout = null;
  const location = useLocation();

  const isLoggedIn = authStore.validateUser();

  layout = isLoggedIn ? (
    <Layout header sidebar footer>
      <Switch>
        <Route path={location.pathname} component={MainBuilder} />
      </Switch>
      <NotificationContainer />
    </Layout>
  ) : (
    <Fragment>
      <NotificationContainer />
      <Switch>
        <Route path="/" component={MainBuilder} />
      </Switch>
    </Fragment>
  );

  return <Fragment>{layout}</Fragment>;
});

export default inject("authStore")(withRouter(App));
