/* eslint-disable react/prop-types */
import React from "react";
import { observer, inject } from "mobx-react";
import ReactSelect from "react-select";

class StateSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.lookupsStore.fetchCountries();
  }

  countryToOption = (country) => {
    return {
      ...country,
      value: country.name,
      label: country.name,
      country: country.name,
      key: country.abbr,
      id: country.abbr,
    };
  };

  values() {
    if (this.props.value) {
      const found = this.props.lookupsStore.getStateByValue(
        this.props.country,
        this.props.value
      );
      if (found) {
        return this.countryToOption(found);
      }
    }
    return null;
  }

  render() {
    const {
      lookupsStore,
      multi = true,
      id,
      name,
      onChange,
      onBlur,
      touched,
      error,
      country,
    } = this.props;
    return (
      <ReactSelect
        isClearable
        className={this.props.className}
        isMulti={multi}
        isDisabled={!country}
        value={this.values()}
        options={lookupsStore
          .statesOfCountry(country)
          .map(this.countryToOption)}
        key={"country-select"}
        id={id}
        name={name}
        onChange={(selected) =>
          onChange(name, selected ? selected.country : null)
        }
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }
}

StateSelect.defaultProps = {
  multi: true,
};

export default inject("lookupsStore")(observer(StateSelect));
