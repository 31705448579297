import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AdvertiserMedia from "../../../components/AdvertiserMedia/AdvertiserMedia";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { CampaignMedia } from "../../../components/CampaignMedia";

function MediaPage({ match, advertiserStore, history }) {
  let advertiserId = null;
  if (!match.params.advertiserId && advertiserStore.getFirst()) {
    advertiserId = advertiserStore.getFirst().id;
  } else {
    advertiserId = match.params.advertiserId;
  }

  const [advertiser, setAdvertiser] = useState(null);
  useEffect(() => {
    setAdvertiser(advertiserStore.getAdvertiser(advertiserId));
    return () => {};
  });

  if (advertiser === undefined) {
    return (
      <div className="page-content-wrapper">
        <div className="mb-3 mt-3 container-fluid">
          <div className="row">
            <div className="col-12">Advertiser not found</div>
          </div>
        </div>
      </div>
    );
  }

  if (advertiser === null) {
    return (
      <div className="page-content-wrapper">
        <div className="mb-3 mt-3 container-fluid">
          <div className="row">
            <div className="col-12">Loading ...</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-content-wrapper">
      <div className="mb-3 mt-3 container-fluid">
        <div className="row">
          <div className="col-12">
            <h4 className="mt-0 header-title">
              {advertiser.name}: Media library
            </h4>
            <p className="text-muted mb-4 font-14">
              check out the uploaded media or upload new media.
            </p>
            <AdvertiserMedia advertiser={advertiser} />
          </div>
        </div>
      </div>
    </div>
  );
}

MediaPage.propTypes = {
  advertiser: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default inject("advertiserStore")(observer(MediaPage));
