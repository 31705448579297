import React from "react";
import { types, flow } from "mobx-state-tree";
import { Axios } from "../utils/http-common";
import { AdvertiserModel } from "../models/AdvertiserModel";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const AdvertiserStore = types
  .model("AdvertiserStore", {
    advertisers: types.optional(types.array(AdvertiserModel), []),
  })
  .actions((self) => {
    return {
      setAdvertisers: (advertisers) => {
        self.advertisers = advertisers;
      },
      fetchAdvertisers: flow(function* (force = false) {
        if (force || self.advertisers.length === 0) {
          yield Axios.get("/advertiser")
            .then((res) => {
              self.setAdvertisers(res.data);
            })
            .catch((e) => {
              localStorage.clear();
              window.location.replace("/");
            });
        }
      }),
    };
  })
  .views((self) => ({
    get advertiserRows() {
      return self.advertisers.map((adv) => ({
        ...adv,
        image: adv.image ? (
          <img
            height="30"
            className="rounded"
            src={"data:image/png;base64, " + adv.image}
            alt={adv.name}
          />
        ) : (
          <div className="text-center advertiser-bullet">
            {adv.name
              .split(" ")
              .map((word) => word[0])
              .join("")}
          </div>
        ),
        actions: (
          <>
            <Link
              to={`/advertiser/${adv.id}`}
              className="btn btn-text btn-sm mr-2"
            >
              <i className="fa fa-edit mr-1"></i>
              Edit
            </Link>
            <Link
              to={`/campaign/create/${adv.id}`}
              className="btn btn-secondary btn-sm mr-2"
            >
              Create Campaign
            </Link>
            <Link
              to={`/advertiser/${adv.id}/media/`}
              className="btn btn-dark btn-sm mr-2"
            >
              Media
            </Link>

            <Button variant={"danger"} size="sm" onClick={() => {}}>
              <i className="fa fa-remove"></i>
            </Button>
          </>
        ),
      }));
    },
    getFirst: () => {
      if (self.advertisers.length > 0) {
        return self.advertisers[0];
      }

      return undefined;
    },
    /**
     * find an advertiser by id
     * @returns {AdvertiserModel | unedfined}
     */
    getAdvertiser: (advertiserId) => {
      const foundIndex = self.advertisers
        .toJSON()
        .findIndex((adv) => adv.id == advertiserId);
      return foundIndex === -1 ? undefined : self.advertisers[foundIndex];
    },
  }));

const advertiserStore = AdvertiserStore.create({
  adverisers: [],
});

export default advertiserStore;
