import React, { Component } from "react";
import { InactiveDevices } from "./InactiveDevices";
import { CampaignsActiveCount } from "./CampaignsActiveCount";
import { NearEndCampaigns } from "./NearEndCampaigns";
import { ServiceStatistics } from "./ServiceStatistics";
import { WelcomeBanner } from "./WelcomeBanner";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
/**
 * dashboard page containing statistics components
 */ 1;

const dashboard1 = observer(({ advertiserStore }) => {
  return (
    <div className="page-content-wrapper">
      <div className="mb-4 mt-4 container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
            <WelcomeBanner />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12">
            <InactiveDevices />
          </div>

          {/* <div className="col-sm-12 col-md-6 col-lg-6" hidden>
            <NearEndCampaigns />
          </div>
          <div className="col-sm-12 col-lg-4" hidden>
            <ServiceStatistics />
          </div> */}
        </div>
      </div>
    </div>
  );
});

export default inject("advertiserStore")(withRouter(dashboard1));
