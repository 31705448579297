import { types } from "mobx-state-tree";

export const AdvertiserModel = types
         .model("AdvertiserModel", {
           name: types.maybe(types.string),
           id: types.maybe(types.identifierNumber),
           email: types.optional(types.string, ""),
          //  image: types.optional(types.maybeNull(types.string), null),
           phone: types.optional(types.string, ""),
           address: types.optional(types.string, "")
         })
         .actions(self => ({
           update: data => {
               
           }
         }));
