import React, { Component, Fragment, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import CopyRight from "../../../components/Footer/CopyRight";

const PagesRecovery = ({ authStore, history }) => {
  const [email, setEmail] = useState("");
  const [recoveryEmailSent, setrecoveryEmailSent] = useState(false);

  const onSubmit = async () => {
    if (email == "") {
      NotificationManager.error("Please fill in the required fields.");
      return;
    }
    try {
      await authStore.recoverPassword(email);
      NotificationManager.success("Email Sent!");
      setrecoveryEmailSent(true);
    } catch (e) {
      NotificationManager.error(e.message);
    }
  };

  return (
    <Fragment>
      <div className="accountbg" />
      <div className="wrapper-page">
        {!recoveryEmailSent ? (
          <div className="card">
            <div className="card-body pt-5">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin">
                  <img src="/assets/images/logo.png" height="70" alt="logo" />
                </Link>
              </h3>

              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">
                  Recover Your Account
                </h4>
                <p className="text-muted text-center">
                  Enter your Email and instructions will be sent to you!
                </p>

                <form
                  className="form-horizontal m-t-30"
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="useremail">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="useremail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email"
                    />
                  </div>

                  <div className="form-group row m-t-20">
                    <div className="col-12 text-right">
                      <button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {recoveryEmailSent ? (
          <div className="card">
            <div className="card-body pt-5">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin">
                  <img src="assets/images/logo.png" height="70" alt="logo" />
                </Link>
              </h3>
              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">Email sent!</h4>
                <p className="text-muted text-center">
                  An email will arrive shortly to the provided account allowing
                  you to reset your password.
                </p>
              </div>
              <div className="col-12 text-center">
                <button
                  className="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                  onClick={() => history.push("/")}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="m-t-40 text-center">
          <p className="text-white">
            Back to
            <Link
              to="login"
              className="font-500 font-14 text-white font-secondary"
            >
              {" "}
              Login{" "}
            </Link>
          </p>
          <CopyRight showCompanyName/>
        </div>
      </div>
    </Fragment>
  );
};

export default inject(["authStore"])(PagesRecovery);
