import React from "react";
import MediaPage from "../Advertiser/MediaPage";

const MediaLibrary = (props) => {
    return <div>
        <MediaPage {...props} />
    </div>
};

export default MediaLibrary;
