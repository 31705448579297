import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import * as Scheme from "yup";
import ClassNames from "classnames";
import { NotificationManager } from "react-notifications";
import TagSelect from "./../../../components/TagSelect/TagSelect";
import { Axios } from "../../../utils/http-common";
import MaskedInput from "react-text-mask";

const EventFormScheme = Scheme.object().shape({
  name: Scheme.string().required(),
  startDate: Scheme.date(),
  endDate: Scheme.date(),
  tags: Scheme.array(Scheme.object()).min(1),
  startTime: Scheme.string()
    .required()
    .matches(
      /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])*$/,
      "Please enter a correct time format. E.g: 01:00"
    ),
  endTime: Scheme.string()
    .required()
    .matches(
      /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])*$/,
      "Please enter a correct time format. E.g: 01:00"
    ),
  exceptionPauseTime: Scheme.number().min(0)
});

const AddEventModal = ({ show, onClose }) => {
  const onSubmit = values => {
    Axios.post("/event", {
      ...values,
    }).then(value => {
      NotificationManager.success(
        `Event ${value.data.name} added successfully`
      );
      onClose(value.data);
    });
  };

  return (
    <Modal isOpen={show}>
      <ModalHeader>Create new event</ModalHeader>
      <ModalBody>
        <Formik
          validationSchema={EventFormScheme}
          initialValues={{
            name: "",
            startDate: null,
            endDate: null,
            tags: [],
            exceptionPauseTime: 1080,
            startTime: "01:00",
            endTime: "02:00"
          }}
          validateOnChange
          onSubmit={values => onSubmit(values)}
        >
          {({ errors, values, setFieldTouched, setFieldValue, touched }) => (
            <Form>
              <div className="form-group">
                <label>Name</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.name
                  })}
                  name="name"
                  type="text"
                  placeholder="E.g: Nature day event"
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Start date</label>
                    <Field
                      className={ClassNames("form-control", {
                        "is-invalid": errors.startDate
                      })}
                      name="startDate"
                      type="date"
                      placeholder="0"
                    />
                    {errors.startDate && (
                      <div className="invalid-feedback">{errors.startDate}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>End date</label>
                    <Field
                      className={ClassNames("form-control", {
                        "is-invalid": errors.endDate
                      })}
                      name="endDate"
                      type="date"
                      placeholder="0"
                    />
                    {errors.endDate && (
                      <div className="invalid-feedback">{errors.endDate}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Start time (24H)</label>
                    <Field
                      name="startTime"
                      type="time"
                      placeholder="00:00"
                      render={({ field }) => (
                        <MaskedInput
                          {...field}
                          mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                          id="startTime"
                          type="text"
                          className={ClassNames("form-control", {
                            "is-invalid": errors.startTime
                          })}
                        />
                      )}
                    />
                    {errors.startTime && (
                      <div className="invalid-feedback">{errors.startTime}</div>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>End time (24H)</label>
                    <Field
                      name="endTime"
                      type="time"
                      placeholder="00:00"
                      render={({ field }) => (
                        <MaskedInput
                          {...field}
                          mask={[/\d/, /\d/, ":", /\d/, /\d/]}
                          id="endTime"
                          type="text"
                          className={ClassNames("form-control", {
                            "is-invalid": errors.endTime
                          })}
                        />
                      )}
                    />
                    {errors.endTime && (
                      <div className="invalid-feedback">{errors.endTime}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Exception Pause Time (Seconds)</label>
                    <Field
                      className={ClassNames("form-control", {
                        "is-invalid": errors.endDate
                      })}
                      name="exceptionPauseTime"
                      type="number"
                      placeholder="60"
                    />
                    {errors.exceptionPauseTime && (
                      <div className="invalid-feedback">
                        {errors.exceptionPauseTime}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <Row>
                <Col sm={12}>
                  <div className="form-group">
                    <label>Tags</label>
                    <TagSelect
                      id="tag-select"
                      name="tags"
                      value={values.tags}
                      isMulti={true}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      touched={touched.fieldOfResearch}
                      error={errors.fieldOfResearch}
                    />
                    {errors.tags && (
                      <div className="invalid-feedback">{errors.tags}</div>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2"
                  type="reset"
                  onClick={() => onClose()}
                  variant="outline-danger"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="success">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

AddEventModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

AddEventModal.defaultProps = {
  show: false,
  onClose: () => {}
};

export { AddEventModal };
