import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import { inject, observer } from "mobx-react";
import { autorun } from "mobx";

const AdvertiserSelect = inject("advertiserStore")(
  observer(
    ({
      onChange = advertiser => {},
      advertiserStore = {},
      value = undefined,
      disabled = false,
      pickFirst = false,
    }) => {
      const [advertisers, setAdvertisers] = useState([]);

      useEffect(() => {
        autorun(() => {
          setAdvertisers(advertiserStore.advertisers.toJSON());
        });
        return () => {};
      }, []);

      if (pickFirst) {
        if (advertisers.length > 0) {
          onChange(advertisers[0]);
          return <div>{advertisers[0].name}</div>;
        }
        return <div>Ooops! No associated advertisers with this account, please contact jereer support.</div>;
      }
      return (
        <ReactSelect
          isDisabled={disabled}
          value={value}
          getOptionLabel={option => option.name}
          getOptionValue={option => option}
          onChange={v => {
            onChange(v);
          }}
          options={advertisers}
        />
      );
    }
  )
);
export { AdvertiserSelect };
