import { Axios } from "../../../utils/http-common";
import React, { Component, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { NotificationManager } from "react-notifications";
import md5 from "md5";
import termsOfUse from "../../../utils/termsofuse";
import CopyRight from "../../../components/Footer/CopyRight";

const Pages_register = observer(({ history, authStore }) => {
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [nextStep, setNextStep] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");

  const [registerationDone, setRegistrationDone] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const onSubmit = async () => {
    if (fullname == "" || email == "" || password == "") {
      NotificationManager.error("Please fill in the required fields.");
      return;
    }
    if (!termsChecked) {
      NotificationManager.error("Please accept Jereer's Terms of Use.");
      return;
    }

    const firstName = fullname.split(" ")[0];
    let lastName = fullname.split(" ")[1];
    lastName = lastName ? lastName : " ";
    const hashedPassword = md5(password);
    const companyInfo = {
      companyName: fullname,
      description: "",
      address: "",
      classic: true,
    };
    const newUser = {
      firstName: firstName,
      lastName: lastName,
      username: email.toLowerCase().trim(),
      email: email.toLowerCase().trim(),
      password: hashedPassword,
      parentCompany: companyInfo,
    };

    try {
      await authStore.signup(newUser);
      setRegistrationDone(true);
      NotificationManager.success("Successfully created account!");
    } catch (e) {
      NotificationManager.error(e.response.data.message);
    }
  };

  return (
    <Fragment>
      <div className="accountbg"></div>
      <div className="wrapper-page">
        {!registerationDone ? (
          <div className="card">
            <div className="card-body pt-5">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin">
                  <img src="assets/images/logo.png" height="70" alt="logo" />
                </Link>
              </h3>

              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">Welcome !</h4>
                <p className="text-muted text-center">
                  Create your Jereer account
                </p>
                <div className="form-horizontal m-t-30">
                  <div className="form-group">
                    <label htmlFor="fullname">Full Name</label>
                    <input
                      type="fullname"
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                      className="form-control"
                      id="fullname"
                      placeholder="Enter Full Name"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="useremail">Email</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      id="useremail"
                      placeholder="Enter Email"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="username">Password</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                      id="password"
                      placeholder="Enter Password"
                      required
                    />
                  </div>

                  <div className="form-group row m-t-20">
                    <div className="col-12 text-right">
                      <button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          onSubmit();
                        }}
                      >
                        Register
                      </button>
                    </div>
                  </div>

                  <div className="form-group m-t-10 mb-0 row">
                    <div className="col-12 m-t-20 d-flex flex-row">
                      <div>
                        <input
                          type="checkbox"
                          defaultChecked={termsChecked}
                          onChange={() => {
                            setTermsChecked(!termsChecked);
                          }}
                        />
                      </div>
                      <p className="font-15 text-muted mb-0 m-l-10">
                        By registering you agree to Jereer's{" "}
                        <Link
                          to="#"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          Terms of Use
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {registerationDone ? (
          <div className="card">
            <div className="card-body pt-5">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin">
                  <img src="assets/images/logo.png" height="70" alt="logo" />
                </Link>
              </h3>
              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">
                  Registration Complete
                </h4>
                <p className="text-muted text-center">
                  Thank you for signing up to Jereer! We will contact you
                  shortly.
                </p>
              </div>
              <div className="col-12 text-center">
                <button
                  className="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                  onClick={() => history.push("/")}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="m-t-40 text-center">
          <p className="text-white">
            Already have an account ?{" "}
            <Link
              to="/login"
              className="font-500 font-14 text-white font-secondary"
            >
              {" "}
              Login{" "}
            </Link>{" "}
          </p>
          <CopyRight showCompanyName />
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Jereer Terms of Usage
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{termsOfUse}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
});

export default inject(["authStore"])(Pages_register);
