import React from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

const PrivateRoute = observer(
  ({ component: Component, authStore, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          authStore.user ? (
            rest?.is_admin == 1 ? (
              <Component {...props} {...{ is_admin: rest?.is_admin }} />
            ) : (
              <div className="mx-2">
                <Component {...props} />
              </div>
            )
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
);

export default inject(["authStore"])(PrivateRoute);
