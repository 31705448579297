import React, { Component } from "react";
import Dashboard1 from "../MainContent/Dashboard/Dashboard1";
import { Route, Switch, Redirect } from "react-router-dom";
import Campaign from "../MainContent/Campaign/Campaign";
import CreateCampaign from "../MainContent/Campaign/CreateCampaign";
import { CompanySettings } from "../MainContent/Settings/Company";
import { LocationsDevices } from "../MainContent/Settings/LocationsDevices";
import { Advertiser } from "../MainContent/Advertiser";
import MediaPage from "../MainContent/Advertiser/MediaPage";
import { Events } from "../MainContent/Event/Events";
import Page404 from "./../MainContent/Pages/Pages_400";
import Administrative from "./../MainContent/Pages/Pages_Administrative";
import EditCampaign from "../MainContent/Campaign/EditCampaign";
import { ServicesSettings } from "../MainContent/Settings/Services/Services";
import { ReportMain } from "../MainContent/Report/ReportMain";
import { Playlists } from "../MainContent/Settings/Playlist/Playlists";
import { AdvertiserMedia } from "../../components/AdvertiserMedia";
import MediaLibrary from "../MainContent/Media/MediaLibrary";
import { Dashboard } from "../MainContent/JereerAnalytics";
import UserSettings from "../MainContent/Settings/Company/UserSettings";
import PagesRegister from "../MainContent/Pages/Pages_register";
import PagesRecovery from "../MainContent/Pages/Pages_recovery";
import PagesLogin from "../MainContent/Pages/Pages_login";
import PrivateRoute from "../mainbuilder/PrivateRoute";
import PublicRoute from "../mainbuilder/PublicRoute";
import AdminRoute from "../mainbuilder/AdminRoute";
import PagesReset from "../MainContent/Pages/PagesReset";
import PagesSubscription from "../MainContent/Pages/pages_subscription";
import PaymentStatus from "../MainContent/Pages/Pages_PaymentStatus";

class MainBuilder extends Component {
  render() {
    return (
      <Switch>
        <PublicRoute
          exact
          restricted={true}
          path="/login"
          component={PagesLogin}
        />

        <PublicRoute
          exact
          restricted={true}
          path="/register"
          component={PagesRegister}
        />

        <PublicRoute
          exact
          restricted={true}
          path="/reset"
          component={PagesReset}
        />

        <PublicRoute
          exact
          restricted={true}
          path="/recovery"
          component={PagesRecovery}
        />

        <PrivateRoute
          exact
          path="/"
          component={() => <Redirect to="/digital-signage/home" />}
        />

        <PrivateRoute
          exact
          path="/digital-signage/report"
          component={(props) => <ReportMain {...props} />}
        />
        <PrivateRoute
          exact
          path="/digital-signage/campaign/all"
          component={(props) => <Campaign {...props} />}
        />
        
        <AdminRoute
          exact
          path="/digital-signage/Admin"
          component={(props) => <Administrative {...props} />}
        />

        {/* <PrivateRoute
          exact
          path="/digital-signage/subscription"
          component={(props) => <PagesSubscription {...props} />}
        /> */}

        {/* <PrivateRoute
          exact
          path="/digital-signage/paymentstatus"
          component={PaymentStatus}
        /> */}

        <PrivateRoute
          exact
          path="/digital-signage/campaign/:campaignId/edit"
          component={(props) => <EditCampaign {...props} />}
        />

        <PrivateRoute
          exact
          path="/digital-signage/media"
          component={(props) => <MediaLibrary {...props} />}
        />

        <PrivateRoute
          exact
          path="/digital-signage/campaign/create/:advertiserId?"
          component={(props) => <CreateCampaign {...props} />}
        />
        <PrivateRoute
          exact
          path="/digital-signage/settings/services"
          component={(props) => <ServicesSettings {...props} />}
        />

        <PrivateRoute
          exact
          path="/digital-signage/settings/playlists"
          component={(props) => <Playlists {...props} />}
        />

        <PrivateRoute
          exact
          path="/digital-signage/event/:eventId/edit"
          component={(props) => <Events {...props} />}
        />

        <PrivateRoute
          exact
          path="/digital-signage/event/create"
          component={(props) => <Events {...props} />}
        />

        <PrivateRoute
          exact
          path="/digital-signage/event/all"
          component={(props) => <Events {...props} />}
        />
        <PrivateRoute
          path="/settings/user"
          component={(props) => <UserSettings {...props} />}
        />
        <PrivateRoute
          path="/settings/company"
          component={(props) => <CompanySettings {...props} />}
        />
        <PrivateRoute
          path="/digital-signage/locations"
          component={(props) => <LocationsDevices {...props} />}
        />
        <PrivateRoute
          path={"/digital-signage/advertiser/:advertiserId/media"}
          component={(props) => <MediaPage {...props} />}
        />
        <PrivateRoute
          path={"/digital-signage/advertiser/:advertiserId?"}
          component={(props) => <Advertiser {...props} />}
        />

        <PrivateRoute path="/digital-signage/home" component={Dashboard1} />

        <PrivateRoute path="/analytics/dashboard" component={Dashboard} />
        <PrivateRoute
          exact
          path="/analytics"
          component={(props) => <Redirect to="/analytics/dashboard" />}
        />
        <PrivateRoute
          exact
          path="/digital-signage"
          component={(props) => <Redirect to="/digital-signage/home" />}
        />
        <PublicRoute component={Page404} />
      </Switch>
    );
  }
}

export default MainBuilder;
