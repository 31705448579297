import React from "react";

const Dashboard = () => {
    return <div className="page-content-wrapper">
        <div className="container-fluid">

        </div>
    </div>
}

export default Dashboard;
