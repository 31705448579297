/* eslint-disable react/prop-types */
import React from "react";
// import ReactSelect from "react-select";
import chroma from "chroma-js";
import { observer, inject } from "mobx-react";
import ReactSelect from "react-select/creatable";

const customStyles = {
  control: styles => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color ? chroma(data.color) : chroma("white");
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css())
      }
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css()
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white"
    }
  })
};
class TagSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.tagStore.fetchTags();
  }

  tagToOption = tag => {
    let color = tag.color;
    if (
      !(
        tag.color &&
        tag.color.match(
          /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^\)]*\)/
        )
      )
    ) {
      color = "black";
    }
    return {
      ...tag,
      value: tag.name,
      label: tag.name,
      color: color,
      key: tag.id,
      id: tag.id
    };
  };

  values() {
    console.log("tag select values", this.props.value);
    return this.props.value ? this.props.value.map(this.tagToOption) : null;
  }

  render() {
    const {
      tagStore,
      multi = true,
      id,
      name,
      onChange,
      onBlur,
      touched,
      error
    } = this.props;
    return (
      <ReactSelect
        className={this.props.className}
        isMulti={multi}
        styles={customStyles}
        value={this.values()}
        options={tagStore.tags.map(this.tagToOption)}
        key={"tag-select"}
        id={id}
        name={name}
        onChange={selected => onChange(name, selected.map(sel => sel.__isNew__ ? ({...sel, name: sel.label}) : sel))}
        onBlur={onBlur}
        touched={touched}
        error={error}
      />
    );
  }
}

TagSelect.defaultProps = {
  multi: true
};

export default inject("tagStore")(observer(TagSelect));
