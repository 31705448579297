import React from "react";
import { types, flow } from "mobx-state-tree";
import { CampaignModel } from "../models/CampaignModel";
import { Axios } from "../utils/http-common";
import { clone } from "lodash";
import { Link } from "react-router-dom";
import { Button, ProgressBar } from "react-bootstrap";
import moment from "moment";
import "mdbreact/dist/css/mdb.css";

const CampaignStore = types
  .model("CampaignStore", {
    campaigns: types.optional(
      types.maybeNull(types.array(CampaignModel)),
      null
    ),
    deleteCampaign: types.optional(
      types.union(types.boolean, types.string),
      false
    ),
    currentCampaignId: types.optional(types.maybeNull(types.number), null),
  })
  .actions((self) => {
    return {
      setCampaigns: (campaigns) => {
        self.campaigns = campaigns;
      },
      setDeleteCampaign: flow(function* (value) {
        self.deleteCampaign = value;
      }),
      setCampaignId: flow(function* (value) {
        self.currentCampaignId = value;
      }),
      fetchCampaigns: flow(function* () {
        yield Axios.get("/campaign").then((res) => {
          const sortedCampaigns = res.data.sort((a, b) => a.id - b.id);
          self.setCampaigns(sortedCampaigns);
        });
      }),
      deleteCampaigns: flow(function* (campaignId) {
        yield Axios.delete("/campaign/" + campaignId).then(() => {
          self.fetchCampaigns();
        });
      }),
    };
  })
  .views((self) => ({
    getRows: () => {
      try {
        return self.campaigns.map((campaign) => {
          const newOne = clone(campaign);
          newOne.startDate = campaign.startDate.toISOString();
          newOne.endDate = campaign.endDate
            ? campaign.endDate.toISOString()
            : null;
          newOne.slots = campaign.reservedSlots.length;
          newOne.active = (
            <>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`${campaign.id}-active-toggle`}
                  disabled={campaign.active === "disabled"}
                  checked={campaign.active === true}
                  onChange={() => {
                    campaign.setActiveRemote(!campaign.active);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`${campaign.id}-active-toggle`}
                ></label>
              </div>
            </>
          );
          newOne.projections = (
            <>
              <i className="mdi mdi-play-circle"></i> 153
            </>
          );

          const progressValue = campaign.endDate
            ? ((Date.now() - campaign.startDate.getTime()) /
                (campaign.endDate.getTime() - campaign.startDate.getTime())) *
              100
            : "unlimited";
          newOne.dateRange = (
            <div>
              <div className="campaign-daterange">
                <span>
                  {`${moment(campaign.startDate).format("DD/MM/YYYY")}`}
                </span>
                <span className="middle-date-span">{`${
                  campaign.endDate
                    ? moment(campaign.endDate).format("DD/MM/YYYY")
                    : "Infinite"
                }`}</span>
              </div>
              {!campaign.endDate ? (
                <ProgressBar
                  variant={campaign.endDate ? "success" : "info"}
                  min={0}
                  max={100}
                  now={100}
                />
              ) : (
                <ProgressBar
                  variant={campaign.endDate ? "success" : "info"}
                  min={0}
                  max={campaign.endDate.getTime() - 1627200000000}
                  now={Date.now() - 1627200000000}
                />
              )}
            </div>
          );
          newOne.actions = (
            <>
              <div style={{ textAlign: "center" }}>
                <Link
                  to={`/digital-signage/campaign/${campaign.id}/edit`}
                  className="btn btn-info btn-sm"
                >
                  <i className="fa fa-edit mr-1"></i>
                  Edit
                </Link>
                <Button
                  variant={"danger"}
                  size="sm"
                  onClick={() => {
                    self.setDeleteCampaign(true);
                    self.setCampaignId(campaign.id);
                  }}
                >
                  <i className="fa fa-remove"></i>
                </Button>
              </div>
            </>
          );
          return newOne;
        });
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    get getCampaigns() {
      if (!self.campaigns) {
        self.fetchCampaigns();
        return [];
      }
      return self.campaigns.toJSON();
    },
  }));

const campaignStore = CampaignStore.create({
  campaigns: null,
});

export default campaignStore;
