import React, { FunctionComponent, useState, ReactElement } from "react";
import { inject, observer } from "mobx-react";
import { Button, Card, Spinner } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as Scheme from "yup";
import { CampaignSelect } from "../../../components/CampaignSelect/CampaignSelect";
import { Axios, config } from "../../../utils/http-common";

import { values } from "mobx";
type ReportMainProps = {
  reportStore: any;
};

const CampaignReportFormScheme = Scheme.object().shape({
  campaign: Scheme.object().required(),
});

function NewComponent(props: { campaignReport: any; onClose: Function }) {
  console.log(props.campaignReport);
  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title text-muted d-flex justify-content-between">
            Campaign projections report
            <Button
              onClick={() => props.onClose()}
              size={"sm"}
              variant="danger"
            >
              <i className="fa fa-close" />
            </Button>
          </div>
          <div className="d-flex align-items-center">
            Campaign:
            <p className="font-weight-bolder m-0 mr-2 ml-1">
              {props.campaignReport.campaign.title}
            </p>
            <span className="mr-2 p-2">
              <i className="fa fa-calendar mr-2" />
              {new Date(
                props.campaignReport.campaign.startDate
              ).toDateString()}{" "}
              - {new Date(props.campaignReport.campaign.endDate).toDateString()}
            </span>
          </div>
          <p className="text-muted m-0">
            {props.campaignReport.totalProjections} total projections.
          </p>
        </div>
        <div className="card-body">
          <div className="mb-4">
            <p className="m-0">Services:</p>
            <div className="d-inline-block">
              {props.campaignReport.services.map((service: any) => (
                <span
                  key={service.id}
                  className="badge badge-dark badge-pill mr-2"
                >
                  {service.name}
                </span>
              ))}
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <td>Location Name</td>
                <td>Projections</td>
              </tr>
            </thead>
            <tbody>
              {props.campaignReport.locations.map((location: any) => (
                <>
                  <tr key={location.id} style={{ background: "#eee" }}>
                    <td>
                      Location:{" "}
                      <b className="font-weight-bold">
                        {location.info
                          ? location.info.name
                          : "Unknown Location"}
                      </b>
                    </td>
                    <td>
                      <strong className="font-weight-bold">
                        {location.projections}
                      </strong>
                    </td>
                  </tr>
                  {/*location.services.map(
                      (service: {
                        id: number;
                        info: JSONObject;
                        projections: React.ReactNode;
                      }) => (
                        <tr key={service.id}>
                          <td>
                            -- Service:{" "}
                            <span className="badge badge-dark badge-pill">
                              {service.info.name}
                            </span>
                          </td>
                          <td>{service.projections}</td>
                        </tr>
                      )
                    )*/}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export const ReportMain: FunctionComponent<ReportMainProps> = inject(
  "reportStore"
)(
  observer(({ reportStore }) => {
    const [loading, setLoading] = useState(false);
    const { fetchCampaignReport, campaignReport, resetCampaignReport } =
      reportStore;
    const onSubmit = (data: any) => {
      setLoading(true);

      fetchCampaignReport(data.campaign.id).finally(() => {
        setLoading(false);
      });
    };

    const exportPdf = (data: any) => {
      setLoading(true);

      Axios.get("/report/" + data.campaign.id + "/pdf", {
        responseType: "blob",
      })
        .then((response: any) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "campaign_" + data.campaign.id + "_report.pdf"
          );
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h4 className="mt-0 header-title">
                <i className="fa fa-file mr-3" />
                Reports
              </h4>
              <p className="text-muted m-b-30 font-14">
                Generate a report of projections on a campaign
              </p>
              <Card>
                <Card.Body>
                  <Formik
                    validationSchema={CampaignReportFormScheme}
                    initialValues={{
                      campaign: null,
                    }}
                    isInitialValid={false}
                    validateOnChange
                    onSubmit={(values) => onSubmit(values)}
                  >
                    {({
                      errors,
                      resetForm,
                      setFieldValue,
                      isValid,
                      values,
                    }) => (
                      <Form>
                        <div className="form-group">
                          <label>Campaign</label>
                          {/* @ts-ignore*/}
                          {/* eslint-disable-next-line */}
                          <CampaignSelect
                            value={values.campaign}
                            onChange={(campaign) => {
                              setFieldValue("campaign", campaign);
                            }}
                          />
                          {errors.campaign && (
                            <div className="invalid-feedback">
                              {errors.campaign}
                            </div>
                          )}
                        </div>

                        <div className="d-flex justify-content-end">
                          <Button
                            className="mr-2"
                            type="reset"
                            onClick={() => resetForm()}
                            variant="outline-danger"
                          >
                            Reset
                          </Button>
                          <Button
                            disabled={!isValid || loading}
                            type="submit"
                            variant="success"
                          >
                            Submit
                          </Button>
                          <Button
                            disabled={!isValid || loading}
                            onClick={() => exportPdf(values)}
                            variant="dark"
                          >
                            <i className="fa fa-file-pdf-o mr-2" />
                            Export Pdf
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>

                  {campaignReport && (
                    <NewComponent
                      onClose={resetCampaignReport}
                      campaignReport={campaignReport.toJSON()}
                    />
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  })
);
