import { useState, useEffect, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { LocationSelect } from "../../../components/LocationSelect";
import { SlotsReserver } from "../../../components/SlotsReserver";
import { inject, observer } from "mobx-react";
import { Axios } from "../../../utils/http-common";
import { CampaignMedia } from "../../../components/CampaignMedia";

/**
 * create a campaign page
 */
const EditCampaign = observer(
  ({ createCampaignStore, advertiserStore, match, history }) => {
    const onTitleChange = (newTitle) => {
      createCampaignStore.campaign.setTitle(newTitle);
    };

    const [mediaLibrary, setMediaLibrary] = useState([]);
    const [displayEndDate, setDisplayEndDate] = useState(true);
    const [disableEndDate, setDisableEndDate] = useState(true);

    useEffect(() => {
      // get the campaign
      Axios.get("/campaign/" + match.params.campaignId).then((res) => {
        const cam = res.data;
        createCampaignStore.setCampaign(cam);

        if (cam.endDate == null) {
          setDisableEndDate(true);
          setDisplayEndDate(false);
        } else {
          setDisableEndDate(false);
          setDisplayEndDate(true);
        }
      });
    }, []);

    useEffect(() => {
      if (!displayEndDate) {
        createCampaignStore.campaign.setEndDate(null);
      } else {
        createCampaignStore.campaign.setEndDate(
          new Date(createCampaignStore.campaign.startDate).setDate(
            new Date(createCampaignStore.campaign.startDate).getDate() + 30
          )
        );
      }
    }, [displayEndDate, createCampaignStore.campaign.startDate]);

    return (
      <Fragment>
        <div className="page-content-wrapper">
          <div className="mb-3 mt-3 container-fluid">
            <div className="row">
              <div className="col-12">
                <h4 className="mt-0 header-title">
                  <i className="ml-3 fa fa-edit mr-3" />
                  Edit campaign
                </h4>
                <p className="ml-3 text-muted font-14 mb-4">
                  follow the steps to edit campaign
                </p>

                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Campaign advertiser</h4>
                    <p className="text-muted m-b-30 font-14">
                      Campaigns are associated with advertiser
                    </p>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Advertiser
                      </label>
                      <div className="col-sm-10">
                        {createCampaignStore.campaign.advertiser
                          ? createCampaignStore.campaign.advertiser.name
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Campaign info</h4>
                    <p className="text-muted m-b-30 font-14">
                      Set the start and end date of campaign and the needed
                      info.
                    </p>

                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Campaign name
                      </label>
                      <div className="col-sm-12">
                        <input
                          value={createCampaignStore.campaign.title}
                          onChange={(e) => onTitleChange(e.target.value)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">
                            Start date
                          </label>
                          <div className="col-sm-12">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              selected={createCampaignStore.campaign.startDate}
                              onChange={(date) => {
                                createCampaignStore.campaign.setStartDate(date);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">
                            End date
                          </label>
                          <div className="col-sm-12">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              disabled={!displayEndDate}
                              selected={
                                displayEndDate
                                  ? createCampaignStore.campaign.endDate
                                  : null
                              }
                              onChange={(date) => {
                                createCampaignStore.campaign.setEndDate(date);
                                if (!createCampaignStore.campaign.endDate) {
                                  createCampaignStore.campaign.setEndDate(
                                    new Date(
                                      createCampaignStore.campaign.startDate
                                    ).setDate(new Date().getDate() + 30)
                                  );
                                }
                              }}
                              minDate={createCampaignStore.campaign.startDate}
                            />
                          </div>
                        </div>
                        <div className="form-check row">
                          <div className="col-sm-12">
                            <FormControlLabel
                              label="No end date?"
                              control={
                                <Checkbox
                                  color="primary"
                                  onChange={() => {
                                    setDisplayEndDate(!displayEndDate);
                                  }}
                                  checked={
                                    createCampaignStore.campaign.endDate == null
                                      ? true
                                      : false
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Campaign locations</h4>
                    <p className="text-muted m-b-30 font-14">
                      Campaign will be delivered and shown to the selected
                      locations devices.
                    </p>

                    <Row>
                      <label className="col-sm-2 col-form-label">
                        Locations
                      </label>
                      <Col md={10}>
                        {createCampaignStore.campaign && (
                          <LocationSelect
                            initialValue={createCampaignStore.campaign.locations.toJSON()}
                            onChange={(selected) => {
                              createCampaignStore.campaign.setLocations(
                                selected
                              );
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>

                {createCampaignStore.campaign.advertiser && (
                  <CampaignMedia
                    advertiser={createCampaignStore.campaign.advertiser}
                    onChange={(media = []) => {
                      setMediaLibrary(media);
                    }}
                  />
                )}

                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Campaign Playlist</h4>
                    <p className="text-muted m-b-10 font-14">
                      Duration in seconds.
                    </p>
                    <SlotsReserver />
                  </div>
                </div>
                <Button
                  variant="info"
                  onClick={() => history.push("/digital-signage/campaign/all")}
                >
                  Cancel
                </Button>
                <Button
                  variant="success"
                  onClick={() =>
                    createCampaignStore.submitEdit(() => {
                      history.push("/digital-signage/campaign/all");
                    })
                  }
                >
                  Publish
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
);

export default inject("createCampaignStore", "advertiserStore")(EditCampaign);
