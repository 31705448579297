import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Axios, source } from "../../utils/http-common";

export const ServiceSelect = ({ field, form, multi = true }) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    Axios.get("/service", {
    }).then(({ data }) => {
      setServices(data);
    });
    return () => {
    };
  }, []);
  return (
    <ReactSelect
      placeholder={ multi ? "Select services" : "Select a service" }
      isMulti={multi}
      onBlur={form.setFieldTouched}
      touched={form.touched[field.name]}
      error={form.errors[field.name]}
      value={services
        .map(service => ({
          value: service.id,
          label: service.name
        }))
        .filter(option => {
          if (multi) {
            return field.value.includes(option.value);
          } else {
            return field.value === option.value;
          }
        })}
      options={services.map(service => ({
        value: service.id,
        label: service.name
      }))}
      onChange={(options = []) => {
        if (!multi) {
          form.setFieldValue(`${field.name}`, options.value);
          return;
        }
        form.setFieldValue(`${field.name}`, []);
        options && options.forEach((option, index) => {
          form.setFieldValue(`${field.name}[${index}]`, option.value);
        });
      }}
    />
  );
};
