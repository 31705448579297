import { CardActionArea } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export const WelcomeBanner = () => {
  const history = useHistory();
  return (
    <div className="row">
      <div className="card-group">
        <div className="col-sm-4 col-md-4 col-lg-4">
          <CardActionArea
            onClick={() => history.push("/digital-signage/locations")}
          >
            <div className="card welcome-card">
              <img
                className="card-img-top"
                src="assets/images/welc2.png"
                alt="Card image cap"
              />
              <h5
                className="card-title text-center"
                style={{ fontWeight: "400" }}
              >
                Add your screen to the system
              </h5>
              <div className="card-body pt-2">
                <p className="font-weight-light">
                  Click "LOCATIONS" and follow the instructions to add a
                  username with a password for your screen.
                </p>
              </div>
            </div>
          </CardActionArea>
        </div>
        <div className="col-sm-4 col-md-4 col-lg-4">
          <CardActionArea
            onClick={() =>
              window.location.replace(
                "https://play.google.com/store/apps/details?id=com.jereer.ds"
              )
            }
          >
            <div className="card welcome-card">
              <img className="card-img-top" src="assets/images/welc1.png" />
              <h5
                className="card-title text-center"
                style={{ fontWeight: "400" }}
              >
                Install Jereer App Player
              </h5>
              <div className="card-body pt-2 welcome-card-body-two">
                <p>
                  Install “Jereer Digital Signage” from Google Play directly on
                  your device and launch it.
                </p>
              </div>
            </div>
          </CardActionArea>
        </div>

        <div className="col-sm-4 col-md-4 col-lg-4">
          <CardActionArea
            onClick={() => history.push("/digital-signage/campaign/create")}
          >
            <div className="card ">
              <img
                className="card-img-top"
                src="assets/images/welc3.png"
                alt="Card image cap"
              />
              <h5
                className="card-title text-center"
                style={{ fontWeight: "400" }}
              >
                Create a Campaign
              </h5>
              <div className="card-body pt-2 welcome-card-body-three">
                <p className="font-weight-light">
                  Click "NEW CAMPAIGN" to start publishing your content directly
                  to your screens.
                </p>
              </div>
            </div>
          </CardActionArea>
        </div>
      </div>
    </div>
  );
};
