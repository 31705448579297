export const absluteBottomRight = {
  margin: 0,
  top: "auto",
  right: 20,
  bottom: 20,
  left: "auto",
  position: "fixed",
  zIndex: 5,
  minWidth: window.innerWidth < 400 ? "15em" : "25em",
};
export const scrollbarc = {
  overflowY: "auto",
  maxHeight: "25em",
};
