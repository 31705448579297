import { types } from "mobx-state-tree";
import { CompanyModel } from "./CompanyModel";

export const UserModel = types
  .model("UserModel", {
    id: types.string,
    //image: types.maybeNull(types.string),
    firstName: types.string,
    lastName: types.string,
    username: types.string,
    email: types.string,
    is_admin: types.number,
    parentCompany: CompanyModel,
  })
  .views((self) => ({
    initials: () => {
      if (self.firstName && self.lastName) {
        return self.firstName[0] + self.lastName[0];
      } else if (self.firstName) {
        return self.firstName[0];
      } else if (self.lastName) {
        return self.lastName[0];
      }
      return "";
    },
    fullName() {
      return [self.firstName, self.lastName].join(" ");
    },
  }))
  .actions((self) => ({
    setCompany: (company) => {
      self.parentCompany = company;
    },
  }));
