import { useEffect, useState } from "react";
import FileTrackerUI from "./components/FileTrackerUI";

const FileTrackerContainer = ({
  show,
  files,
  setShow,
  setFiles = () => {},
}) => {
  const [dataToShow, setDataToShow] = useState([]);
  useEffect(() => {
    setDataToShow(files);
  }, [files]);
  const onClose = () => {
    setShow(false);
  };

  const onMinimaize = () => {
    setShow(false);
  };

  return (
    <FileTrackerUI
      show={show}
      onClose={onClose}
      title="Upload media"
      files={dataToShow ? dataToShow : []}
    />
  );
};
export default FileTrackerContainer;
