import React from "react";
import CopyRight from "../Footer/CopyRight";

const footer = (props) => (
  <footer className="footer">
  <div className="d-none d-sm-inline-block float-left">
    <CopyRight color="muted" />{" "}

  </div>
    <span className="text-muted d-none d-sm-inline-block float-right">
      Crafted with <i className="mdi mdi-heart text-danger"></i> by Jereer
    </span>
  </footer>
);

export default footer;
