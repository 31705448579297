import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import Service from "./Service";
import { Axios } from "../../../../utils/http-common";
import AddServiceModal from "./AddServiceModal";
import EditServiceModal from "./EditServiceModal";

export const ServicesIntervals = ({ company = {} }) => {
  const [services, setServices] = useState([]);
  const [openAddServiceModal, setOpenAddServiceModal] = useState(false);
  const [editingService, setEditingService] = useState(null);

  const fetchServices = () => {
    Axios.get("service").then(res => {
      setServices(res.data);
    });
  };

  useEffect(() => {
    fetchServices();
    return () => {};
  }, []);

  return (
    <Card className="w-100">
      <div className="card-header d-flex justify-content-between">
        Services & Intervals
        <div>
          <Button
            variant="dark"
            onClick={() => {
              setOpenAddServiceModal(true);
            }}
          >
            <i className="fa fa-plus mr-1" />
            <span>Add Service</span>
          </Button>
        </div>
      </div>
      <div className="card-body">
        {services.map(service => (
          <Service
            key={service.id}
            onEdit={() => { setEditingService(service) }}
            onRemove={() => {
              fetchServices();
            }}
            service={service}
          />
        ))}
      </div>
      <AddServiceModal
        onClose={data => {
          if (data) {
            fetchServices();
          }
          setOpenAddServiceModal(false);
        }}
        show={openAddServiceModal}
        company={company}
      />

      {editingService && <EditServiceModal
        onClose={data => {
          if (data) {
            fetchServices();
          }
          setEditingService(null);
        }}
        show
        service={editingService}
      />}
    </Card>
  );
};
