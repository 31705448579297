import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { inject, observer } from "mobx-react";
import { Button, Col, Row } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { NotificationManager } from "react-notifications";
import { Axios } from "../../../utils/http-common";

/**
 * campaigns listing component
 */
const Campaign = ({ campaignStore, history }) => {
  const columns = [
    {
      label: "Title",
      field: "title",
      sort: "asc",
      width: 150,
    },
    // {
    //     label: "Priority",
    //     field: "priority",
    //     sort: "asc",
    //     width: 270
    // },
    {
      label: "Slots",
      field: "slots",
      sort: "asc",
      width: 200,
    },
    {
      label: <div>Start/End Date</div>,
      field: "dateRange",
      sort: "asc",
      width: 100,
    },
    {
      label: "",
      field: "actions",
      sort: "disabled",
    },
  ];

  const deleteCampaign = (campaignId) => {
    campaignStore.setDeleteCampaign(false);
    Axios.delete("/campaign/" + campaignId)
      .then((res) => {
        campaignStore.fetchCampaigns();
        NotificationManager.success(`Campaign has been deleted successfully`);
      })
      .catch((e) => {
        NotificationManager.error(`Campaign was NOT deleted`);
      });
  };

  useEffect(() => {
    campaignStore.fetchCampaigns();
    return () => {};
  }, []);

  return (
    <div className="page-content-wrapper">
      <div className="mb-3 mt-3 container-fluid">
        <div className="row">
          <div className="col-12">
            <h4 className="mt-0 header-title">
              <i className="fa fa-bullhorn mr-3"></i>Campaigns
            </h4>
            <p className="text-muted m-b-30 font-14">
              list of campaigns registered
            </p>
            <div className="card m-b-20">
              <div className="card-body">
                <Row className="d-fex">
                  <Col md={6}></Col>
                  <Col md={6} className="d-flex justify-content-end">
                    <Button
                      onClick={() =>
                        history.push("/digital-signage/campaign/create")
                      }
                      variant={"dark"}
                      className="mr-2"
                    >
                      <i className="fa fa-plus mr-2"></i>
                      New Campaign
                    </Button>

                    <Button
                      onClick={() => campaignStore.fetchCampaigns()}
                      variant={"primary"}
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </Col>
                  {campaignStore.deleteCampaign && (
                    <SweetAlert
                      show
                      type="danger"
                      title="Are you sure?"
                      onConfirm={() =>
                        deleteCampaign(campaignStore.currentCampaignId)
                      }
                      onCancel={() => campaignStore.setDeleteCampaign(false)}
                      showCancel
                      cancelBtnBsStyle="primary"
                      confirmBtnBsStyle="danger"
                      focusConfirmBtn={false}
                    >
                      You will not be able to revert changes.
                    </SweetAlert>
                  )}
                </Row>
                <MDBDataTable
                  className="campaign-data-table"
                  responsive
                  sortable
                  exportToCSV
                  hover
                  data={{ columns, rows: [...campaignStore.getRows()] }}
                  striped
                  noBottomColumns
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("campaignStore")(observer(Campaign));
