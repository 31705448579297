import { useState, useEffect } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import ReactSelect from "react-select";
import PropTypes from "prop-types";
import { Axios, config } from "../../utils/http-common";
import { cloneDeep } from "lodash";
import { inject, observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import { autorun } from "mobx";
import { CardGroup, ListGroup } from "reactstrap";
import "../../styles/Slot.css";

const SlotsReserver = ({ createCampaignStore }) => {
  const [currentSlots, setCurrentSlots] = useState([]);
  const [intervals, setIntervals] = useState([]);
  useEffect(() => {
    Axios.get("/interval").then((res) => {
      setIntervals(res.data);
    });
    return () => {};
  }, []);

  // load original slots -> needed to always update reserved slots with autorun
  useEffect(() => {
    autorun(() => {
      setCurrentSlots(createCampaignStore.campaign.reservedSlots.toJSON());
    });
    return () => {};
  }, []);

  // // new slot changes -> needed for when slots are removed
  useEffect(() => {
    createCampaignStore.campaign.setSlots(currentSlots);
    return () => {};
  }, [currentSlots]);

  return (
    <section>
      <div className="container-fluid slotHorizontalRow">
        <div className="d-flex flex-nowrap ml-2">
          {createCampaignStore.campaign.reservedSlots
            .toJSON()
            .map((slot, index) => (
              <div
                className="row"
                key={
                  createCampaignStore.campaign.reservedSlots
                    .toJSON()
                    .filter((reb) => reb.url == slot.url)
                    .filter((e) => e).length > 1
                    ? `slot-camps-${slot.url}-${index}`
                    : `slot-camps-${slot.url}`
                }
              >
                <div className="col" style={{ paddingRight: "20px" }}>
                  <Card
                    style={{ width: "14rem", height: "16rem" }}
                    key={slot.key}
                  >
                    <div className="card-header d-flex justify-content-baseline">
                      <div style={{ paddingTop: 6 }}>
                        <input
                          name="slotDuration"
                          onChange={(e) => {
                            const newSlots = cloneDeep(currentSlots);
                            newSlots[index].duration = parseInt(e.target.value);
                            setCurrentSlots(newSlots);
                          }}
                          value={slot.duration}
                          type="number"
                          min={1}
                          color="secondary"
                          className="form-control"
                        />
                      </div>
                      <Button
                        onClick={() => {
                          setCurrentSlots(
                            createCampaignStore.campaign.reservedSlots
                              .toJSON()
                              .filter((slotItem, i) => i !== index)
                          );
                        }}
                        size={"sm"}
                        variant={"danger"}
                      >
                        <i className="fa fa-close"></i>
                      </Button>
                    </div>
                    <div className="slotContainer">
                      <div className="slotThumb">
                        <div className="slotInner">
                          {slot.media &&
                            (slot.media.title.includes(".mp4") ? (
                              <video className="mediaImage" controls>
                                <source
                                  src={`${slot.media.url || slot.url}`}
                                  type="video/mp4"
                                ></source>
                              </video>
                            ) : slot.interval.service.symbol.match(
                                /^(I|B|PO)$/
                              ) ? (
                              <img
                                className="mediaImage"
                                src={`${slot.media.url || slot.url}`}
                                alt={slot.media.title}
                              />
                            ) : (
                              ""
                            ))}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

SlotsReserver.propTypes = {
  services: PropTypes.arrayOf(PropTypes.string),
};

export default inject("createCampaignStore")(observer(SlotsReserver));
