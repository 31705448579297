import React, { useState, useEffect } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { Axios, source } from "../../../../utils/http-common";
import { NotificationManager } from "react-notifications";
import { CreatePlaylist } from "./CreatePlaylistModal";
import Playlist from "./Playlist";
import { AdvertiserMedia } from "../../../../components/AdvertiserMedia";
export const Playlists = () => {
  const [playlists, setPlaylists] = useState("loading");
  const [isAddingNewPlaylist, setIsAddingNewPlaylist] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);

  useEffect(() => {
    // const cancelTokenSource = source();
    Axios.get("/playlist", {
      // cancelToken: cancelTokenSource.token
    })
      .then(res => {
        setPlaylists(res.data);
      })
      .catch(error => {
        setPlaylists("error");
        NotificationManager.error("Could not load playlists.");
      });
    return () => {
      // cancelTokenSource.cancel();
    };
  }, []);

  if (playlists === "loading") {
    return (
      <div style={{ display: "absolute", bottom: 0 }} className="card">
        <Spinner />
      </div>
    );
  } else if (playlists === "error") {
    return <div className="card">Error loading playlists</div>;
  }

  const addPlaylist = playlist => {
    setPlaylists([...playlists, playlist]);
  };

  if (Array.isArray(playlists))
    return (
      <div className="page-content-wrapper">
        <div className="mb-3 mt-3 container-fluid">
          <Row className="mt-4 mb-1">
            <Col xs={8}>
              <h4 className="mt-0 header-title">
                <i className="fa fa-photo-video mr-2" />
                Playlists
              </h4>
              <p className="text-muted font-14">
                Define the layer 0 playback type for devices.
              </p>
            </Col>
            <Col xs={4}></Col>
          </Row>
          <Row>
            <Col xs={12} lg={selectedPlaylist ? 5 : 12}>
              {isAddingNewPlaylist && (
                <CreatePlaylist
                  onClose={() => {
                    setIsAddingNewPlaylist(false);
                  }}
                  onSuccess={playlist => addPlaylist(playlist)}
                />
              )}
              {playlists.map((playlist, index) => (
                <Playlist
                  onEdit={playlist => {
                    console.log("edited:", playlist);
                    setPlaylists(
                      playlists.map(p => (p.id === playlist.id ? playlist : p))
                    );
                  }}
                  onDelete={playlist =>
                    setPlaylists(playlists.filter(p => p.id !== playlist.id))
                  }
                  onSelect={selected => setSelectedPlaylist(selected)}
                  playlist={playlist}
                  key={playlist.id}
                />
              ))}
              <div className="d-flex justify-content-end mt-3">
                <Button
                  onClick={() => {
                    setIsAddingNewPlaylist(true);
                  }}
                  outlined
                  variant=""
                  className="m-0"
                >
                  <i className="fa fa-plus mr-2" />
                  Create playlist
                </Button>
              </div>
            </Col>
            {selectedPlaylist && (
              <Col xs={12} lg={6}>
                <PlaylistDetails playlist={selectedPlaylist} />
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
};

const PlaylistDetails = ({ playlist = null }) => {
  if (!playlist) {
    return null;
  }

  const [details, setDetails] = useState(null);
  useEffect(() => {
    Axios.get("/playlist/" + playlist.id).then(res => {
      setDetails(res.data);
    });
    return () => {};
  }, [playlist]);

  return (
    <div className="card">
      <div className="card-header">
        <div className="header-title">{playlist.type}</div>
      </div>
      <div className="card-body">
        {details &&
          details.mediaList.map(media => {
            return (
              <div key={media.id} className="card">
                <div className="card-header">
                  <div className="header-title">
                    <i className="fa fa-file-image mr-2"></i>
                    {media.title}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
