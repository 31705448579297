import React from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

export const JereerBrand = inject("authStore")(
  observer(({ authStore, theme = "dark", showCompanyName = true, showLogo = true }) => {
    return (
      <Link
        style={{ color: theme === "dark" ? "white" : "black", textAlign: "center" }}
        to="/"
        className="logo"
      >
        <div className="header-logo">
          {showLogo && <img
          className={showCompanyName ? "mr-2 logo-size" : null}
          src="/assets/images/logo-sm.png"
          alt="logo"
          style={{ maxWidth: "60%" }}
          />}
          <div className="hide-phone">
            {showCompanyName && authStore.user ? `${authStore.user.parentCompany.companyName}` : null}  
          </div>
        </div>
      </Link>
    );
  })
);
