import React, { Component, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { NotificationManager } from "react-notifications";
import md5 from "md5";
import CopyRight from "../../../components/Footer/CopyRight";

const Pages_login = ({ authStore, history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async () => {
    if (email == "" || password == "") {
      NotificationManager.error("Please fill in the required fields.");
      return;
    }
    setIsLoading(true);
    const hashedPassword = md5(password);
    try {
      const result = await authStore.login(
        email.toLowerCase().trim(),
        hashedPassword
      );
      history.push("/digital-signage/home");
    } catch (e) {
      NotificationManager.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="accountbg"></div>
      <div className="wrapper-page">
        <div className="card">
          <div className="card-body pt-5">
            <h3 className="text-center m-0">
              <Link to="/" className="logo logo-admin">
                <img src="/assets/images/logo.png" height="70" alt="logo" />
              </Link>
            </h3>

            <div className="p-3">
              <h4 className="font-18 m-b-5 text-center">Welcome Back !</h4>
              <p className="text-muted text-center">
                Sign in to continue to Jereer platform.
              </p>

              <form
                className="form-horizontal m-t-30"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
                action="/"
              >
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="userpassword">Password</label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control"
                    id="userpassword"
                    placeholder="Enter password"
                  />
                </div>

                <div className="form-group row m-t-20">
                  <div className="col-sm-6">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customControlInline"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customControlInline"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6 text-right">
                    <button
                      disabled={isLoading}
                      className="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        onSubmit();
                      }}
                    >
                      Log In
                    </button>
                  </div>
                </div>

                <div className="form-group m-t-10 mb-0 row">
                  <div className="col-12 m-t-20">
                    <Link to="recovery" className="text-muted">
                      <i className="mdi mdi-lock"></i> Forgot your password?
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="m-t-40 text-center">
          <p className="text-white">
            Don't have an account ?{" "}
            <Link
              to="register"
              className="font-500 font-14 text-white font-secondary"
            >
              {" "}
              Signup Now{" "}
            </Link>{" "}
          </p>
          <CopyRight showCompanyName/>
        </div>
      </div>
    </Fragment>
  );
};

export default inject(["authStore"])(Pages_login);
