import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import * as Scheme from "yup";
import { Axios } from "../../../../utils/http-common";
import ClassNames from "classnames";
import { NotificationManager } from "react-notifications";

const IntervalFormScheme = Scheme.object().shape({
  fullScreen: Scheme.boolean(),
  name: Scheme.string().required(),
  symbol: Scheme.string().required(),
  originX: Scheme.number().min(0),
  originY: Scheme.number().min(0),
  height: Scheme.number().min(0),
  width: Scheme.number().min(0)
});

const AddServiceModal = ({ show, onClose, company }) => {
  const onSubmit = values => {
    Axios.post("/service", {
      ...values,
      company: company
    }).then(value => {
      NotificationManager.success(
        `Service ${value.data.name} added successfully`
      );
      onClose(value.data);
    });
  };
  return (
    <Modal isOpen={show}>
      <ModalHeader>Add a new service to {company.companyName}</ModalHeader>
      <ModalBody>
        <Formik
          validationSchema={IntervalFormScheme}
          initialValues={{
            fullScreen: true,
            name: "",
            symbol: "",
            originX: 0,
            originY: 0,
            height: 0,
            width: 0
          }}
          validateOnChange
          onSubmit={values => onSubmit(values)}
        >
          {({ errors, isSubmitting, isValid, values }) => (
            <Form>
              <div className="form-group">
                <label>Name</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.name
                  })}
                  name="name"
                  type="text"
                  placeholder="E.g: Banner"
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>

              <div className="form-group">
                <label>Symbol</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.symbol
                  })}
                  name="symbol"
                  type="text"
                  placeholder="E.g: B"
                />
                {errors.symbol && (
                  <div className="invalid-feedback">{errors.symbol}</div>
                )}
              </div>

              <div className="form-check">
                <Field
                  className={ClassNames("form-check-input", {
                    "is-invalid": errors.fullScreen
                  })}
                  name="fullScreen"
                  type="checkbox"
                />
                <label>Fullscreen</label>
                {errors.fullScreen && (
                  <div className="invalid-feedback">{errors.fullScreen}</div>
                )}
              </div>

              {!values.fullScreen && <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Height</label>
                    <Field
                      className={ClassNames("form-control", {
                        "is-invalid": errors.height
                      })}
                      name="height"
                      type="number"
                      placeholder="0"
                    />
                    {errors.height && (
                      <div className="invalid-feedback">{errors.height}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Width</label>
                    <Field
                      className={ClassNames("form-control", {
                        "is-invalid": errors.width
                      })}
                      name="width"
                      type="number"
                      placeholder="0"
                    />
                    {errors.width && (
                      <div className="invalid-feedback">{errors.width}</div>
                    )}
                  </div>
                </div>
              </div>}

              {!values.fullScreen && <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Origin X</label>
                    <Field
                      className={ClassNames("form-control", {
                        "is-invalid": errors.originX
                      })}
                      name="originX"
                      type="number"
                      placeholder="0"
                    />
                    {errors.originX && (
                      <div className="invalid-feedback">{errors.originX}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Origin Y</label>
                    <Field
                      className={ClassNames("form-control", {
                        "is-invalid": errors.originY
                      })}
                      name="originY"
                      type="number"
                      placeholder="0"
                    />
                    {errors.originY && (
                      <div className="invalid-feedback">{errors.originY}</div>
                    )}
                  </div>
                </div>
              </div>
              }

              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2"
                  type="reset"
                  onClick={() => onClose()}
                  variant="outline-danger"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  variant="success"
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

AddServiceModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  company: PropTypes.any
};

AddServiceModal.defaultProps = {
  show: false,
  onClose: () => {}
};

export default AddServiceModal;
