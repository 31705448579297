import React, {useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import {ServicesIntervals} from "../Company/ServicesIntervals";
import {inject} from "mobx-react";

/**
 * Company Settings
 * Services and Intervals Settings
 */
export let ServicesSettings = ({ authStore }) => {
    const { company } = authStore;

    return (
      <div className="page-content-wrapper">
        <div className="mb-3 mt-3 container-fluid">
          <Row className="mb-2">
            <Col xs={8}>
              <h4 className="mt-0 header-title">
                <i className="fas fa-layer-group mr-3" />
                Services
              </h4>
              <p className="text-muted m-b-30 font-14">
                Each screen will serve one or more services, and each service
                owns intervals.
              </p>
            </Col>
          </Row>
          <Row className="mb-2">
            <ServicesIntervals company={company} />
          </Row>
        </div>
      </div>
    );
};


ServicesSettings = inject("authStore")(ServicesSettings);
