import { types } from "mobx-state-tree";
import { MediaFile } from "./MediaFileModel";
import { IntervalModel } from "./IntervalModel";
import { config } from "../utils/http-common";

export const ReservedSlot = types
  .model("ReservedSlot", {
    id: types.optional(types.identifierNumber, -1),
    url: types.maybe(types.string),
    media: types.optional(types.maybeNull(MediaFile), null),
    interval: types.optional(types.maybeNull(IntervalModel), null),
    duration: types.optional(types.maybeNull(types.number), null),
  })
  .preProcessSnapshot(snapshot => {
    if (snapshot.url && !snapshot.url.startsWith("http")) {
      snapshot.url = `${config().BASE_URL}/${snapshot.url}`;
    }
    return snapshot;
  });
