import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import { inject, observer } from "mobx-react";

class layout extends Component {

    
    render() {
        return (
            <Fragment>
                {!this.props.loginpage ?
                    <main>
                        <div id="wrapper" className={this.props.layoutStore.enlarge ? "enlarged" : ""}>
                            {this.props.sidebar ? <Sidebar/> : null}
                            <div className="content-page">
                                <div className="content">
                                    {this.props.header ? <Header/> : null}
                                    {this.props.children}
                                </div>
                                {this.props.footer ? <Footer/> : null}
                            </div>
                        </div>
                    </main> : this.props.children}
            </Fragment>
        );
    }
}

export default inject("layoutStore")(withRouter(observer(layout)));

