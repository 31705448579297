import React, { useEffect, useState } from 'react';
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {Button} from "react-bootstrap";
import PropTypes from "prop-types";
import {Formik, Field, Form} from "formik";
import * as Scheme from "yup";
import {Axios} from "../../../../utils/http-common";
import ClassNames from "classnames";
import {ServiceSelect} from "../../../../components/ServiceSelect/ServiceSelect";
import {NotificationManager} from "react-notifications";
import TagSelect from "../../../../components/TagSelect/TagSelect";
import {PlaylistSelect} from '../../../../components/PlaylistSelect/PlaylistSelect';
import {inject, observer} from "mobx-react";

const DeviceFormScheme = Scheme.object().shape({
    deviceName: Scheme.string().required(),
    password: Scheme.string().min(8).required(),
    active: Scheme.boolean(),
    wakeupTime: Scheme.string().required(),
    shutdownTime: Scheme.string().required(),
    orientation: Scheme.number().required(),
    services: Scheme.array().min(1, "Device should serve at least 1 type of service.").required(),
    tags: Scheme.array().optional(),
    playlist: Scheme.object().nullable().optional(),
});

const AddDeviceModal = ({show, onClose, location, authStore}) => {
    const [currentServices, setCurrentServices] = useState([]);

    useEffect(() => {
        Axios.get("/service").then((res) => {
            const service_ids = res.data.map(service => service.id)
            setCurrentServices(service_ids)
        })
        return () => { };
      }, []);

    const onSubmit = (values) => {
        values.orientation = parseInt(values.orientation);
        return Axios.post("/device/register",
            {
                ...values,
                location: location,
                services: values.services.map(serviceId => ({id: serviceId})),
                deviceName: `${authStore.company.id}_${values.deviceName}`,
            },
        ).then((value) => {
            NotificationManager.success(`Screen ${value.data.deviceName} added successfully to ${location.address}`);
            onClose(value.data, "done");
            return value.data;
        }).catch(e => {
            NotificationManager.error(e.response.data);
        })
    }
    return (
        <Modal isOpen={show}>
            <ModalHeader>Add a new screen to {location.address}</ModalHeader>
            <ModalBody>
                <Formik
                    validationSchema={DeviceFormScheme}
                    initialValues={{
                        deviceName: "",
                        password: "",
                        active: true,
                        wakeupTime: "00:00",
                        shutdownTime: "00:00",
                        orientation: 0,
                        services: currentServices,
                        tags: [],
                        playlist: null,
                    }}
                    validateOnBlur
                    validateOnChange
                    onSubmit={values => onSubmit(values)}
                >
                    {({
                          errors,
                          values,
                          setFieldTouched,
                          setFieldValue,
                          touched,
                          isSubmitting,
                          isValid
                      }) => (
                        <Form>
                            <div className="form-group">
                                <label>Screen name</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">{authStore.company.id}_</div>
                                    </div>
                                    <Field
                                        className={ClassNames("form-control", {
                                            "is-invalid": errors.deviceName
                                        })}
                                        name="deviceName"
                                        type="text"
                                        placeholder="E.g: Asura 21"
                                    />
                                </div>
                                {errors.deviceName && (
                                    <div className="invalid-feedback">{errors.deviceName}</div>
                                )}
                            </div>

                            <div className="form-group">
                                <label>Password</label>
                                <Field
                                    className={ClassNames("form-control", {
                                        "is-invalid": errors.password
                                    })}
                                    name="password"
                                    type="password"
                                />
                                {errors.password && (
                                    <div className="invalid-feedback">{errors.password}</div>
                                )}
                            </div>
                            <div className="form-group">
                                        <label>Orientation</label>
                                        <Field
                                            className={ClassNames("form-control", {
                                                "is-invalid": errors.orientation
                                            })}
                                            name="orientation"
                                            as="select"
                                            type="text"
                                        >
                                            <option value="0" type="number">Landscape</option>
                                            <option value="1" type="number">Portrait</option>
                                            <option value="8" type="number">Reverse Landscape</option>
                                            <option value="9" type="number">Reverse Portrait</option>
                                        </Field>
                                    </div>


                            {!authStore.company.classic && (
                                <>
                                    <div className="form-group">
                                        <label>Wake up time</label>
                                        <Field
                                            className={ClassNames("form-control", {
                                                "is-invalid": errors.wakeupTime
                                            })}
                                            name="wakeupTime"
                                            type="text"
                                            placeholder="E.g: 13:00"
                                        />
                                        {errors.wakeupTime && (
                                            <div className="invalid-feedback">{errors.wakeupTime}</div>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Shutdown Time</label>
                                        <Field
                                            className={ClassNames("form-control", {
                                                "is-invalid": errors.shutdownTime
                                            })}
                                            name="shutdownTime"
                                            type="text"
                                            placeholder="E.g: 21:00"
                                        />
                                        {errors.shutdownTime && (
                                            <div className="invalid-feedback">
                                                {errors.shutdownTime}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                            <div className="form-group">
                                <label>Services</label>
                                <Field name="services" component={ServiceSelect}/>
                                {errors.services && (
                                    <div className="invalid-feedback">{errors.services}</div>
                                )}
                            </div>

                            {!authStore.company.classic && (
                                <>
                                    <div className="form-group">
                                        <label>Tags</label>
                                        <TagSelect
                                            id="tag-select"
                                            name="tags"
                                            value={values.tags}
                                            isMulti={true}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            touched={touched.fieldOfResearch}
                                            error={errors.fieldOfResearch}
                                        />
                                        {errors.tags && (
                                            <div className="invalid-feedback">{errors.tags}</div>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Playlist</label>
                                        <PlaylistSelect
                                            id="playlist-select"
                                            name="playlist"
                                            value={values.playlist}
                                            onChange={(value) => setFieldValue("playlist", value)}
                                        />
                                        {errors.playlist && (
                                            <div className="invalid-feedback">{errors.playlist}</div>
                                        )}
                                    </div>
                                </>
                            )}

                            <div className="d-flex justify-content-end">
                                <Button
                                    className="mr-2"
                                    type="reset"
                                    onClick={() => onClose(null, "cancel")}
                                    variant="outline-danger"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={isSubmitting || !isValid}
                                    type="submit"
                                    variant="success"
                                >
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
}

AddDeviceModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    location: PropTypes.any,
}

AddDeviceModal.defaultProps = {
    show: false,
    onClose: () => {
    },
}

export default inject("authStore")(observer(AddDeviceModal));
