import { types } from "mobx-state-tree";

const LayoutStore = types
  .model("LayoutStore", {
    enlarge: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setEnlarge: boolean => {
      self.enlarge = boolean;
    },

    toggleEnlarge: () => {
      self.enlarge = !self.enlarge;
    }
  }));

export const layoutStore = LayoutStore.create({});
