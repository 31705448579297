import React, { useState, Fragment, useEffect } from "react";
import {
  Collapse,
  CardBody,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Alert,
} from "reactstrap";
import PropType from "prop-types";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  ProgressBar,
  Row,
} from "react-bootstrap";
import AddDeviceModal from "./AddDeviceModal";
import EditDeviceModal from "./EditDeviceModal";
import { observer, inject } from "mobx-react";
import { noop } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { Axios } from "../../../../utils/http-common";
import { Device } from "./Device";
import { NotificationManager } from "react-notifications";

const Location = ({
  location,
  onRemove = noop,
  onLocationEdit = noop,
  locationsStore,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [addDeviceOpen, setAddDeviceOpen] = useState(false);
  const [editingDeviceOpen, setEditingDeviceOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(false);
  const [deletingLocation, setDeletingLocation] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const setIsEditing = () => {
    onLocationEdit(location);
  };

  const deviceEdit = (device) => {
    setSelectedDevice(device);
    setEditingDeviceOpen(true);
  };

  const deleteLocation = async () => {
    setDeletingLocation(false);
    await Axios.delete("location/" + location.id)
      .then((res) => {
        onRemove(location);
        NotificationManager.success(
          `Location ${location.name} has been deleted successfully`
        );
      })
      .catch((e) => {
        NotificationManager.error(
          "Please remove it from the campaign before deleting.",
          "Location was NOT deleted ",
          6000
        );
        return;
      });
  };

  const removeDevice = (device) => {
    Axios.delete("device/" + device.id)
      .then((res) => {
        onRemove(device);
        locationsStore.fetchLocations();
        NotificationManager.success(
          `Device ${device.deviceName} has been deleted successfully`
        );
      })
      .catch((e) => {
        NotificationManager.error(
          `Error: Device ${device.deviceName} was NOT deleted.`
        );
      });
  };

  useEffect(() => {
    locationsStore.fetchLocations();
  }, [isOpen]);

  return (
    <Card className="mb-2">
      <div className="d-flex flex-column card-header">
        <div className="row">
          <div className="col-4">
            <h6 className="mt-1 mr-3">
              <strong className="d-block">
                <i className="fa fa-map-marker mr-2" />
                {location.name}
              </strong>
              <span>
                {location.country}{" "}
                {location.state ? `- ${location.state} ` : ""}{" "}
                {location.city ? `- ${location.city} ` : ""}
                {location.address ? ` - ${location.address}` : ""}
              </span>
            </h6>
          </div>
          <div className="col-8 d-flex justify-content-end">
            <div className="">
              <Button variant="info" onClick={() => setIsEditing()} size="sm">
                <i className="fa fa-edit mr-1" />
                Edit location
              </Button>
              <Button variant="primary" onClick={toggle} size="sm">
                {isOpen ? (
                  <i className="fa fa-arrow-circle-down" />
                ) : (
                  <i className="fa fa-arrow-circle-up" />
                )}{" "}
                ({location.devices.length}) Screens
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => setDeletingLocation(true)}
              >
                <i className="fa fa-close" />
              </Button>
              {deletingLocation && (
                <SweetAlert
                  show
                  type="danger"
                  title="Are you sure?"
                  onConfirm={() => deleteLocation()}
                  onCancel={() => setDeletingLocation(false)}
                  showCancel
                  cancelBtnBsStyle="primary"
                  confirmBtnBsStyle="danger"
                  focusConfirmBtn={false}
                >
                  You will not be able to revert changes.
                </SweetAlert>
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <CardBody>
          <AddDeviceModal
            location={locationsStore.getById(location.id)}
            onClose={(device, action) => {
              setAddDeviceOpen(false);
              if (action === "done") {
                locationsStore.fetchLocations();
              }
            }}
            show={addDeviceOpen}
          />

          {selectedDevice && (
            <EditDeviceModal
              device={selectedDevice}
              show={editingDeviceOpen}
              onClose={(updatedDevice, action) => {
                setEditingDeviceOpen(false);
                if (action === "done") {
                  locationsStore.fetchLocations();
                }
              }}
            />
          )}

          {isOpen && (
            <Collapse isOpen>
              <div className="table-responsive">
                <table className="table mt-2">
                  <thead>
                    <tr>
                      <td hidden>State</td>
                      <td colSpan="2">Name</td>
                      <td>Orientation</td>
                      <td>Services</td>
                      <td>
                        <div className="d-flex">
                          <Button
                            onClick={() => setAddDeviceOpen(true)}
                            variant="dark"
                            className="mr-2"
                          >
                            <i className="fa fa-plus mr-2" />
                            Add a screen
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {location.devices.map((device) => (
                      <Device
                        key={device.id}
                        device={device}
                        onClick={() =>
                          deviceEdit(
                            locationsStore.getDeviceById(location.id, device.id)
                          )
                        }
                        onClick1={() =>
                          removeDevice(
                            locationsStore.getDeviceById(location.id, device.id)
                          )
                        }
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </Collapse>
          )}
        </CardBody>
      )}
    </Card>
  );
};
Location.propTypes = {
  location: PropType.shape({
    id: PropType.number,
    country: PropType.string,
    state: PropType.string,
    city: PropType.string,
    address: PropType.string,
    long: PropType.any,
    lat: PropType.any,
    devices: PropType.any,
    services: PropType.any,
  }),
};
export default inject("locationsStore")(observer(Location));
