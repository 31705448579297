import { useEffect, useState } from "react";
import SubscriptionCard from ".";

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}
const SubscriptionCardContainer = (props) => {
  const [price, setPrice] = useState(
    !props.primaryText && props.plan.item_price.price / 100
  );
  const [numberOfScreens, setNumberOfScreens] = useState(1);
  var features =
    !props.primaryText &&
    [
      props.plan.item_price.cf_storage &&
        `Total Storge : ${bytesToSize(
          props.plan.item_price.cf_storage * 1048576
        )}`,
      props.plan.item_price.cf_screen_status && "Screen Status",
      "Media Format: Video and Images",

      props.plan.item_price.price &&
        props.plan.item_price.period &&
        `Price : $ ${props.plan.item_price.price / 100}/Screen/${
          props.plan.item_price.period
        } Month`,
    ].filter((e) => e);
  useEffect(() => {
    setPrice(
      props.primaryText
        ? 0
        : numberOfScreens * (props.plan.item_price.price / 100)
    );
  }, [numberOfScreens]);

  return props.primaryText ? (
    <SubscriptionCard {...props} />
  ) : (
    <SubscriptionCard
      cPrice={price}
      planID={props.plan.item_price.id}
      setNumberOfScreens={setNumberOfScreens}
      numberOfScreens={numberOfScreens}
      feature={features}
      subscription={props.plan.item_price.cf_icon}
      price={price}
      immPrice={props.plan.item_price.price / 100}
    />
  );
};
export default SubscriptionCardContainer;
