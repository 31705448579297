/* eslint-disable react/prop-types */
import React from "react";
import {observer, inject} from "mobx-react";
import ReactSelect from "react-select";

class CountrySelect extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.lookupsStore.fetchCountries();
    }

    countryToOption = country => {
        return {
            ...country,
            value: country.name,
            label: country.name,
            country: country.name,
            key: country.abbr,
            id: country.abbr
        };
    };

    values() {
        if (this.props.value) {
            const found = this.props.lookupsStore.getCountryByValue(this.props.value);
            if (found) {
                return this.countryToOption(found);
            }
        }
        return null;
    }

    render() {

        const {
            lookupsStore, multi = true, id, name, onChange, onBlur, touched, error
        } = this.props;
        return (
          <ReactSelect
            isClearable
            className={this.props.className}
            isMulti={multi}
            value={this.values()}
            options={lookupsStore.countries.map(this.countryToOption)}
            key={"country-select"}
            id={id}
            name={name}
            onChange={selected => onChange(name, selected ? selected.country : null)}
            onBlur={onBlur}
            touched={touched}
            error={error}
          />
        );
    }
}

CountrySelect.defaultProps = {
    multi: true
};

export default inject("lookupsStore")(observer(CountrySelect));
