/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import PropTypes from "prop-types";
import { Axios } from "../../utils/http-common";
import { observer } from "mobx-react";

const groupLocations = (locations, values = []) => {
  let root = {
    value: [],
    label: "All Locations",
    type: "all",
    children: [],
    expanded: true,
  };

  let res = [];
  for (const location of locations) {
    // find city, if not exist then make it
    // insert location to the city
    // find the country, if not exist then make it
    let country = res.find((value) => {
      return location.country === value.label;
    });
    if (!country) {
      country = {
        value: location.country,
        label: location.country,
        children: [],
        type: "country",
        expanded: true,
      };
      res = [...res, country];
    }

    // find state if not exist then make it
    let state = country.children.find((value) => {
      return location.state == value.label;
    });
    if (!state) {
      state = {
        value: location.state,
        label: location.state || "State",
        children: [],
        type: "state",
        expanded: true,
      };
      country.children = [...country.children, state];
    }

    let city = state.children.find((value) => {
      return location.city == value.label;
    });
    if (!city) {
      city = {
        value: location.city,
        label: location.city || "City",
        children: [],
        type: "city",
        expanded: true,
      };
      state.children = [...state.children, city];
    }

    city.children = [
      ...city.children,
      {
        value: location.id,
        label: `${location.name}, ${location.address}`,
        country: country.label,
        state: state.label,
        city: city.label,
        checked: values.findIndex((value) => value.id === location.id) >= 0,
      },
    ];
  }
  root.children = res;
  return root.children.length == 0 ? [] : root;
};

const LocationSelect = ({
  onChange = () => {},
  initialValue = [],
  locationUpdated = false,
}) => {
  const [locations, setLocations] = useState([]);
  const [treeData, setTreeData] = useState({});
  useEffect(() => {
    Axios.get("/location").then((res) => {
      setLocations(
        res.data.map((snapshot) => ({
          id: snapshot.id,
          country: snapshot.country,
          state: snapshot.state,
          city: snapshot.city,
          address: snapshot.address,
          long: snapshot.long,
          lat: snapshot.lat,
          name: snapshot.name,
        }))
      );
    });
    return () => {};
  }, []);
  useEffect(() => {
    Axios.get("/location").then((res) => {
      setLocations(
        res.data.map((snapshot) => ({
          id: snapshot.id,
          country: snapshot.country,
          state: snapshot.state,
          city: snapshot.city,
          address: snapshot.address,
          long: snapshot.long,
          lat: snapshot.lat,
          name: snapshot.name,
        }))
      );
    });
    return () => {};
  }, [locationUpdated]);

  useEffect(() => {
    setTreeData(groupLocations(locations, initialValue));
    return () => {};
  }, [locations, initialValue]);

  return (
    <DropdownTreeSelect
      data={treeData}
      showPartiallySelected
      onChange={(currentValue, values) => {
        let selectedLocations = [];
        const getBasedOnValue = (value) => {
          if (!value.type) {
            const location = locations.find(
              (location) => location.id === value.value
            );
            if (location) {
              selectedLocations.push(location);
            }
          } else if (value.type === "all") {
            selectedLocations = [...locations];
          } else {
            selectedLocations = [
              ...selectedLocations,
              ...locations.filter((location) => {
                return location[value.type] === value.value;
              }),
            ];
          }
        };
        if (Array.isArray(values)) {
          for (const value of values) {
            getBasedOnValue(value);
          }
        } else {
          getBasedOnValue(values);
        }
        onChange(selectedLocations);
      }}
      className="treeview-select"
      showDropdown="always"
      keepOpenOnSelect={true}
    />
  );
};

LocationSelect.propTypes = {
  onChange: PropTypes.func,
};

export default LocationSelect;
