import React, { Component, Fragment, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link, useLocation } from "react-router-dom";
import { inject } from "mobx-react";
import md5 from "md5";

const PagesReset = ({ authStore, history }) => {
  const [newPassword, setNewPassword] = useState("");
  const [passwordReset, setPasswordReset] = useState(false);

  const search = useLocation().search;
  const firebaseOobCode = new URLSearchParams(search).get("oobCode");

  const onSubmit = async () => {
    if (newPassword == "") {
      NotificationManager.error("Please fill in the required fields.");
      return;
    }
    const hashedPassword = md5(newPassword);
    try {
      const result = await authStore.resetPassword(
        hashedPassword,
        firebaseOobCode
      );
      NotificationManager.success("Password Updated!");
    } catch (e) {
      NotificationManager.error(e.message);
    }
    setPasswordReset(true);
  };

  return (
    <Fragment>
      <div className="accountbg" />
      <div className="wrapper-page">
        {!passwordReset ? (
          <div className="card">
            <div className="card-body pt-5">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin">
                  <img src="/assets/images/logo.png" height="70" alt="logo" />
                </Link>
              </h3>

              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">Reset Password</h4>
                <p className="text-muted text-center">
                  Enter a new password for your Jereer account.
                </p>

                <form
                  className="form-horizontal m-t-30"
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Enter new password"
                    />
                  </div>

                  <div className="form-group row m-t-20">
                    <div className="col-12 text-right">
                      <button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {passwordReset ? (
          <div className="card">
            <div className="card-body pt-5">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin">
                  <img src="assets/images/logo.png" height="70" alt="logo" />
                </Link>
              </h3>
              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">Password Updated!</h4>
                <p className="text-muted text-center">
                  You can now login in with your new password.
                </p>
              </div>
              <div className="col-12 text-center">
                <button
                  className="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                  onClick={() => history.push("/")}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="m-t-40 text-center">
          <p className="text-white">
            Back to
            <Link
              to="login"
              className="font-500 font-14 text-white font-secondary"
            >
              {" "}
              Login{" "}
            </Link>
          </p>
          <p className="text-white">© 2021 Jereer.</p>
        </div>
      </div>
    </Fragment>
  );
};

export default inject(["authStore"])(PagesReset);
