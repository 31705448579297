import { types, flow } from "mobx-state-tree";
import { ReservedSlot } from "./ReservedSlotModel";
import { LocationModel } from "./LocationModel";
import { AdvertiserModel } from "./AdvertiserModel";
import { Axios } from "../utils/http-common";
export const CampaignModel = types
  .model("CampaignModel", {
    id: types.optional(types.identifierNumber, -1),
    title: types.optional(types.string, ""),
    priority: types.optional(types.number, 1),
    startDate: types.optional(types.maybeNull(types.Date), null),
    endDate: types.optional(types.maybeNull(types.Date), null),
    active: types.optional(types.union(types.boolean, types.string), true),
    reservedSlots: types.optional(types.array(ReservedSlot), []),
    locations: types.optional(types.array(LocationModel), []),
    advertiser: types.optional(types.maybeNull(AdvertiserModel), null),
    webUrl    : types.optional(types.string, "")
  })
  .actions(self => {
    return {
      setAdvertiser: advertiser => {
        if (advertiser) {
          self.advertiser = { ...advertiser };
        }
      },
      setTitle: title => {
        self.title = title;
      },
      setPriority: priority => {
        self.priority = priority;
      },
      setStartDate: startDate => {
        self.startDate = startDate;
      },
      setEndDate: endDate => {
        self.endDate = endDate;
      },
      setActive: active => {
        self.active = active;
      },
      setSlots: slots => {
        self.reservedSlots = slots;
      },
      setLocations: locations => {
        self.locations = locations;
      },
      addSlot: reservedSlot => {
        self.reservedSlots = [...self.reservedSlots, reservedSlot];
      },
      removeSlot: index => {
        if (index < self.reservedSlots.length) {
          self.reservedSlots = self.reservedSlots.filter((value, i) => {
            return index !== i;
          });
        }
      },
      clearSlots: () => {
        self.reservedSlots = [];
      },
      setActiveRemote: flow(function*(boolean) {
        self.setActive("disabled");
        yield Axios.patch(`/campaign/${self.id}/activate`, {
          active: boolean
        }).then(res => {
          self.setActive(res.data.active);
        });
      })
    };
  })
  .preProcessSnapshot(snapshot => {
    if (snapshot.startDate) {
      snapshot.startDate = new Date(snapshot.startDate);
    }

    if (snapshot.endDate) {
      snapshot.endDate = new Date(snapshot.endDate);
    }
    return snapshot;
  });
