/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import PropType from "prop-types";
import { Button } from "react-bootstrap";
import { observer } from "mobx-react";
import { noop } from "lodash";
import { types } from "mobx-state-tree";
import { Axios } from "../../../../utils/http-common";
import { NotificationManager } from "react-notifications";
import AddIntervalModal from "./AddIntervalModal";
import EditIntervalModal from "./EditIntervalModal";
import SweetAlert from "react-bootstrap-sweetalert";

const Service = ({ service: propService, onRemove = noop, onEdit = noop }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAddIntervalOpen, setIsAddIntervalOpen] = useState(false);
  const [service, setService] = useState(propService);
  const [editingInterval, setEditingInterval] = useState(null);

  const [deletingInterval, setDeletingInterval] = useState(null);

  const toggle = () => setIsOpen(!isOpen);

  const removeIntervalFromAService = (interval) => {
    const updatedService = service;
    updatedService.intervals = service.intervals.filter(
      (i) => i.id !== interval.id
    );
    setService(updatedService);
  };

  useEffect(() => {
    setService(propService);
  }, [propService]);

  const [deletingService, setDeletingService] = useState(false);
  const deleteInterval = (interval) => {
    Axios.delete("interval/" + interval.id)
      .then(() => {
        removeIntervalFromAService(interval);
        setDeletingInterval(null);
      })
      .catch((e) => {
        NotificationManager.error(e.message);
      });
  };

  const remove = () => {
    Axios.delete("service/" + service.id)
      .then(() => {
        onRemove();
      })
      .catch((e) => {
        NotificationManager.error(e.message);
      });
  };

  return (
    <Card className="mb-2">
      <CardBody>
        <div className="d-flex flex-column ">
          <div className="d-flex justify-content-between align-items-start">
            <h6 className="d-flex m-0 mr-3">
              <strong className="d-flex mr-3">
                <span className="badge badge-dark mr-2">{service.symbol}</span>
                {service.name}
              </strong>
              <span className="badge badge-primary mr-2">
                {service.fullScreen
                  ? "Fullscreen"
                  : `${service.height} X ${service.width}`}
              </span>
              <span className="badge badge-primary mr-2">
                X: {service.originX}
              </span>
              <span className="badge badge-primary">Y: {service.originY}</span>
            </h6>

            <div className="d-flex">
              <Button variant="info" size="sm" onClick={onEdit}>
                <i className="fa fa-edit mr-1" />
                Edit Service
              </Button>
              <Button variant="primary" size="sm" onClick={toggle}>
                {isOpen ? (
                  <i className="fa fa-arrow-circle-down" />
                ) : (
                  <i className="fa fa-arrow-circle-up" />
                )}{" "}
                ({service.intervals.length}) Intervals
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={() => {
                  if (service.intervals.length > 0) {
                    alert(
                      "You can't delete a service with intervals. please delete the intervals first."
                    );
                    return;
                  }
                  setDeletingService(true);
                }}
              >
                <i className="fa fa-close" />
              </Button>
              {deletingService && (
                <SweetAlert
                  show
                  type="danger"
                  title="Are you sure?"
                  onConfirm={() => remove()}
                  onCancel={() => setDeletingService(false)}
                  showCancel
                  cancelBtnBsStyle="primary"
                  confirmBtnBsStyle="danger"
                  focusConfirmBtn={false}
                >
                  You will not be able to revert changes.
                </SweetAlert>
              )}
            </div>
          </div>
        </div>

        <Collapse isOpen={isOpen}>
          <table className="table mt-2">
            <thead>
              <tr>
                <td>Name</td>
                <td>Item Duration</td>
                <td>Overall Duration</td>
                <td>
                  <div className="d-flex">
                    <Button
                      onClick={() => {
                        setIsAddIntervalOpen(true);
                      }}
                      variant="dark"
                      className="mr-2"
                    >
                      <i className="fa fa-plus mr-2" />
                      Add an interval
                    </Button>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {service.intervals.map((interval) => (
                <tr key={interval.id}>
                  <td>{interval.name}</td>
                  <td>{interval.itemTime} Seconds</td>
                  <td>{interval.overallTime} Minutes</td>
                  <td>
                    <Button
                      variant="info"
                      className="mr-1"
                      onClick={() => {
                        setEditingInterval(interval);
                      }}
                      size="sm"
                    >
                      <i className="fa fa-edit" />
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => {
                        setDeletingInterval(interval);
                      }}
                    >
                      <i className="fa fa-remove" />
                    </Button>
                    {deletingInterval && (
                      <SweetAlert
                        show
                        title="Are you sure?"
                        onConfirm={() => deleteInterval(interval)}
                        onCancel={() => setDeletingInterval(null)}
                        type="danger"
                        showCancel
                        cancelBtnBsStyle="primary"
                        confirmBtnBsStyle="danger"
                        focusConfirmBtn={false}
                      >
                        You will not be able to revert changes.
                      </SweetAlert>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Collapse>
      </CardBody>
      <AddIntervalModal
        service={service}
        onClose={(data) => {
          if (data) {
            let updatedService = service;
            updatedService.intervals.push(data);
            setService(updatedService);
          }
          setIsAddIntervalOpen(false);
        }}
        show={isAddIntervalOpen}
      />

      {editingInterval && (
        <EditIntervalModal
          interval={editingInterval}
          onClose={(data) => {
            if (data) {
              let updatedService = service;
              const index = updatedService.intervals.findIndex(
                (interval) => interval.id === data.id
              );
              updatedService.intervals[index] = data;
              setService(updatedService);
            }
            setEditingInterval(null);
          }}
          show
        />
      )}
    </Card>
  );
};
Service.propTypes = {};
export default observer(Service);
