import { types, flow } from "mobx-state-tree";
import { Axios } from "../utils/http-common";
const CityModel = types.model("CityModel", {
  name: types.string
});
const StateModel = types.model("StateModel", {
  name: types.string,
  cities: types.optional(types.array(CityModel), [])
});
const CountryModel = types.model("StateModel", {
  name: types.string,
  states: types.optional(types.array(StateModel), [])
});

const Lookup = types.model("LookupModel", {
  id: types.identifierNumber,
  name: types.string,
  options: types.optional(types.array(CountryModel), [])
});
const LookupsStore = types
  .model("LookupsStore", {
    lookups: types.optional(types.array(Lookup), [])
  })
  .views(self => ({
    get countries() {
      const found = self.lookups.find(lookup => lookup.name === "countries");
      return found ? found.options : [];
    },
    getStateByValue: (country, state) => {
      if (country && state) {
        const foundCountry = self.getCountryByValue(country);
        if (foundCountry) {
          return foundCountry.states.find(s => s.name === state) || null;
        }
      }
      return null;
    },
    getCountryByValue: country => {
      return self.countries.find(el => el.name === country);
    },
    statesOfCountry: country => {
      const foundCountry = self.getCountryByValue(country);
      if (foundCountry) {
        return foundCountry.states;
      }
      return [];
    },

    cityOfCountryState: (country, state) => {
      if (country && state) {
        const foundState = self
          .statesOfCountry(country)
          .find(s => s.name === state);
        if (foundState) {
          return foundState.cities;
        }
      }
      return [];
    },

    getCityByValue: (country, state, city) => {
      if (country && state && city) {
        return (
          self.cityOfCountryState(country, state).find(c => c.name === city) ||
          null
        );
      }
      return null;
    }
  }))
  .actions(self => ({
    setLookpus: lookups => {
      self.lookups = lookups;
    },
    fetchCountries: flow(function*() {
      if (self.countries.length === 0) {
        yield Axios.get("/lookups").then(res => {
          self.setLookpus(res.data);
        });
      }
    })
  }));

export const lookupsStore = LookupsStore.create({});
