import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { MDBDataTable } from "mdbreact";
import { Button, Col, Row } from "react-bootstrap";
import { AddEventModal } from "./AddEventModal";
import { EditEventModal } from "./EditEventModal";

export const Events = inject("eventStore")(observer(({ history, eventStore, match }) => {

  useEffect(() => {
    eventStore.fetchEvents();
    return () => {};
  }, []);

  const columns = [
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 150
    },
    {
      label: "Tags",
      field: "tags",
      sortable: false,
      width: 270
    },
    {
      label: "From - To",
      field: "dateRange",
      sortable: false,
      width: 100
    },
    {
      label: "Exception Pause Time",
      field: "exceptionPauseTime",
      sortable: true,
      width: 100
    },
    {
      label: "",
      sortable: false,
      field: "actions"
    }
  ];

  return (
    <div className="page-content-wrapper">
      <AddEventModal
        onClose={() => {
          eventStore.fetchEvents(true);
          history.push("/event/all");
        }}
        show={history.location.pathname.startsWith("/event/create")}
      />

      {match.params.eventId && (
        <EditEventModal
          onClose={() => {
            eventStore.fetchEvents(true);
            history.push("/event/all");
          }}
          eventId={match.params.eventId}
          show
        />
      )}
      <div className="mb-3 mt-3 container-fluid">
        <div className="row">
          <div className="col-12">
            <h4 className="mt-0 header-title">
              <i className="fa fa-calendar-day mr-3"></i>Events
            </h4>
            <p className="text-muted m-b-30 font-14">
              company events, events changes the behaviour of clients
            </p>
            <div className="card m-b-20">
              <div className="card-body">
                <Row className="d-fex">
                  <Col md={6}></Col>
                  <Col md={6} className="d-flex justify-content-end">
                    <Button
                      onClick={() => history.push("/event/create")}
                      variant={"dark"}
                      className="mr-2"
                    >
                      <i className="fa fa-plus mr-2"></i>
                      New Event
                    </Button>

                    <Button
                      onClick={() => eventStore.fetchEvents(true)}
                      variant={"primary"}
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </Col>
                </Row>
                <MDBDataTable
                  barReverse
                  sortable
                  exportToCSV
                  hover
                  data={{ columns, rows: eventStore.getRows() }}
                  striped
                  noBottomColumns
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}));

Events.propTypes = {};
