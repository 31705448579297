import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import * as Scheme from "yup";
import { Axios } from "../../../../utils/http-common";
import ClassNames from "classnames";
import { NotificationManager } from "react-notifications";

const ServiceFormScheme = Scheme.object().shape({
  fullScreen: Scheme.boolean(),
  name: Scheme.string().required(),
  symbol: Scheme.string().required(),
  originX: Scheme.number().min(0),
  originY: Scheme.number().min(0),
  height: Scheme.number().min(0),
  width: Scheme.number().min(0)
});

const EditServiceModal = ({ show, onClose, service }) => {
  const onSubmit = values => {
    Axios.patch(`/service/${service.id}`, {
      fullScreen: values.fullScreen,
      height: values.height,
      name: values.name,
      originX: values.originX,
      originY: values.originY,
      symbol: values.symbol,
      width: values.width,
      resizable: values.resizable,
      resizeWidth: values.resizeWidth,
      resizeHeight: values.resizeHeight,
    }).then(value => {
      NotificationManager.success(
        `Service was edited successfully`
      );
      onClose(value.data);
    });
  };
  return (
    <Modal isOpen={show}>
      <ModalHeader>Edit service {service.name}</ModalHeader>
      <ModalBody>
        <Formik
          validationSchema={ServiceFormScheme}
          initialValues={{
            ...service
          }}
          validateOnChange
          onSubmit={values => onSubmit(values)}
        >
          {({ errors, isSubmitting, isValid, values }) => (
            <Form>
              <div className="form-group">
                <label>Name</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.name
                  })}
                  name="name"
                  type="text"
                  placeholder="E.g: Banner"
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>

              <div className="form-group">
                <label>Symbol</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.symbol
                  })}
                  name="symbol"
                  type="text"
                  placeholder="E.g: B"
                />
                {errors.symbol && (
                  <div className="invalid-feedback">{errors.symbol}</div>
                )}
              </div>

              <div className="form-check">
                <Field
                  className={ClassNames("form-check-input", {
                    "is-invalid": errors.fullScreen
                  })}
                  name="fullScreen"
                  type="checkbox"
                />
                <label>Fullscreen</label>
                {errors.fullScreen && (
                  <div className="invalid-feedback">{errors.fullScreen}</div>
                )}
              </div>

              {!values.fullScreen && (
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Height</label>
                      <Field
                        className={ClassNames("form-control", {
                          "is-invalid": errors.height
                        })}
                        name="height"
                        type="number"
                        placeholder="0"
                      />
                      {errors.height && (
                        <div className="invalid-feedback">{errors.height}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Width</label>
                      <Field
                        className={ClassNames("form-control", {
                          "is-invalid": errors.width
                        })}
                        name="width"
                        type="number"
                        placeholder="0"
                      />
                      {errors.width && (
                        <div className="invalid-feedback">{errors.width}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {!values.fullScreen && (
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Origin X</label>
                      <Field
                        className={ClassNames("form-control", {
                          "is-invalid": errors.originX
                        })}
                        name="originX"
                        type="number"
                        placeholder="0"
                      />
                      {errors.originX && (
                        <div className="invalid-feedback">{errors.originX}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Origin Y</label>
                      <Field
                        className={ClassNames("form-control", {
                          "is-invalid": errors.originY
                        })}
                        name="originY"
                        type="number"
                        placeholder="0"
                      />
                      {errors.originY && (
                        <div className="invalid-feedback">{errors.originY}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="form-check">
                <Field
                  className={ClassNames("form-check-input", {
                    "is-invalid": errors.resizable
                  })}
                  name="resizable"
                  type="checkbox"
                />
                <label>Resizable</label>
                {errors.resizable && (
                  <div className="invalid-feedback">{errors.resizable}</div>
                )}
              </div>

              {values.resizable && (
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Resize width</label>
                      <Field
                        className={ClassNames("form-control", {
                          "is-invalid": errors.resizeWidth
                        })}
                        name="resizeWidth"
                        type="number"
                        placeholder="0"
                      />
                      {errors.resizeWidth && (
                        <div className="invalid-feedback">
                          {errors.resizeWidth}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Resize height</label>
                      <Field
                        className={ClassNames("form-control", {
                          "is-invalid": errors.resizeHeight
                        })}
                        name="resizeHeight"
                        type="number"
                        placeholder="0"
                      />
                      {errors.resizeHeight && (
                        <div className="invalid-feedback">
                          {errors.resizeHeight}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2"
                  type="reset"
                  onClick={() => onClose()}
                  variant="outline-danger"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  variant="success"
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

EditServiceModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  company: PropTypes.any
};

EditServiceModal.defaultProps = {
  show: false,
  onClose: () => {}
};

export default EditServiceModal;
