import React from 'react';
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {Button, Row, Col} from "react-bootstrap";
import PropTypes from "prop-types";
import {Formik, Field, Form} from "formik";
import * as Scheme from "yup";
import {Axios} from "../../../../utils/http-common";
import ClassNames from "classnames";
import {ServiceSelect} from "../../../../components/ServiceSelect/ServiceSelect";
import {NotificationManager} from "react-notifications";
import TagSelect from '../../../../components/TagSelect/TagSelect';
import {inject, observer} from "mobx-react";


const DeviceFormScheme = Scheme.object().shape({
    deviceName: Scheme.string().required(),
    password: Scheme.string().min(8),
    active: Scheme.boolean(),
    wakeupTime: Scheme.string().required(),
    shutdownTime: Scheme.string().required(),
    orientation: Scheme.number().required(),
    services: Scheme.array(Scheme.number()).min(
        1,
        "device must serve at least 1 service."
    ),
    tags: Scheme.array(Scheme.object()).nullable()
});

const EditDeviceModal = ({show, onClose, device, authStore}) => {
    const onSubmit = (values) => {
        values.orientation = parseInt(values.orientation);
        Axios.patch(`/device/${device.id}`,
            {...values, services: values.services.map(serviceId => ({id: serviceId}))},
        ).then((value) => {
            NotificationManager.success(`Device edited successfully`)
            onClose(value.data, "done");
        })
    }
    return (
        <Modal isOpen={show}>
            <ModalHeader>Edit device: {device.deviceName}</ModalHeader>
            <ModalBody>
                <Formik
                    validationSchema={DeviceFormScheme}
                    initialValues={{
                        ...device,
                        orientation: device.orientation,
                        services: device.services
                            ? device.services.map(service => service.id)
                            : [],
                        tags: device.tags || []
                    }}
                    validateOnChange
                    onSubmit={values => onSubmit(values)}
                >
                    {({errors, values, touched, setFieldTouched, setFieldValue, isSubmitting, isValid}) => (
                        <Form>
                            <div className="form-group">
                                <label>Password</label>
                                <Field
                                    className={ClassNames("form-control", {
                                        "is-invalid": errors.password
                                    })}
                                    name="password"
                                    type="password"
                                />
                                {errors.password && (
                                    <div className="invalid-feedback">{errors.password}</div>
                                )}
                            </div>
                            <div className="form-group">
                                        <label>Orientation</label>
                                        <Field
                                            className={ClassNames("form-control", {
                                                "is-invalid": errors.orientation
                                            })}
                                            name="orientation"
                                            as="select"
                                            type="text"
                                        >
                                            <option value="0" type="number">Landscape</option>
                                            <option value="1" type="number">Portrait</option>
                                            <option value="8" type="number">Reverse Landscape</option>
                                            <option value="9" type="number">Reverse Portrait</option>
                                        </Field>
                                    </div>

                            {!authStore.company.classic && (
                                <>
                                    <div className="form-group">
                                        <label>Wake up time</label>
                                        <Field
                                            className={ClassNames("form-control", {
                                                "is-invalid": errors.wakeupTime
                                            })}
                                            name="wakeupTime"
                                            type="text"
                                            placeholder="E.g: 13:00"
                                        />
                                        {errors.wakeupTime && (
                                            <div className="invalid-feedback">{errors.wakeupTime}</div>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label>Shutdown Time</label>
                                        <Field
                                            className={ClassNames("form-control", {
                                                "is-invalid": errors.shutdownTime
                                            })}
                                            name="shutdownTime"
                                            type="text"
                                            placeholder="E.g: 21:00"
                                        />
                                        {errors.shutdownTime && (
                                            <div className="invalid-feedback">
                                                {errors.shutdownTime}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                            <div className="form-group">
                                <label>Services</label>
                                <Field name="services" component={ServiceSelect}/>
                                {errors.services && (
                                    <div className="invalid-feedback">{errors.services}</div>
                                )}
                            </div>

                            {!authStore.company.classic && (<Row>
                                <Col sm={12}>
                                    <div className="form-group">
                                        <label>Tags</label>
                                        <TagSelect
                                            id="tag-select"
                                            name="tags"
                                            value={values.tags}
                                            isMulti={true}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            touched={touched.fieldOfResearch}
                                            error={errors.fieldOfResearch}
                                        />
                                        {errors.tags && (
                                            <div className="invalid-feedback">{errors.tags}</div>
                                        )}
                                    </div>
                                </Col>
                            </Row>)}

                            <div className="d-flex justify-content-end">
                                <Button
                                    className="mr-2"
                                    type="reset"
                                    onClick={() => onClose(null, "cancel")}
                                    variant="outline-danger"
                                >
                                    Cancel
                                </Button>
                                <Button disabled={isSubmitting || !isValid} type="submit" variant="success">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
}

EditDeviceModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    device: PropTypes.any,
}

EditDeviceModal.defaultProps = {
    show: false,
    onClose: () => {
    },
}

export default inject("authStore")(observer(EditDeviceModal));
