import React from "react";
import { Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

const PublicRoute = observer(({ component: Component, authStore, restricted, ...rest }) => {
  return(
  <Route 
    render={(props) =>
      authStore.user ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} />
      )
    }
  />
  );
});

export default inject(["authStore"])(PublicRoute);
