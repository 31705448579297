import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Alert, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import * as Scheme from "yup";
import { Axios } from "../../../../utils/http-common";
import ClassNames from "classnames";
import CountrySelect from "../../../../components/CountrySelect/CountrySelect";
import StateSelect from "../../../../components/StateSelect/StateSelect";
import CitySelect from "../../../../components/CitySelect/CitySelect";
import { NotificationManager } from "react-notifications";
import { locationsStore } from "../../../../store/locationsStore";

const LocationFormScheme = Scheme.object().shape({
  country: Scheme.string().required(),
  state: Scheme.string().required(),
  city: Scheme.string().required(),
  address: Scheme.string().required(),
  name: Scheme.string().required(),
});

const AddLocationModal = ({ show, onClose, countryName }) => {
  const [error, setError] = useState(null);

  const onSubmit = (values) => {
    return Axios.post("/location", values)
      .then((res) => {
        onClose(values);
        NotificationManager.success(
          `Location ${values.name} has been added successfully`
        );
        locationsStore.fetchLocations();
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <Modal isOpen={show}>
      <ModalHeader>Add a new location</ModalHeader>
      <ModalBody>
        {error && <Alert variant="danger">{error}</Alert>}
        <Formik
          validationSchema={LocationFormScheme}
          initialValues={{
            country: countryName,
            state: "",
            city: "",
            address: "",
            name: "",
          }}
          validateOnChange
          onSubmit={(values) => onSubmit(values)}
        >
          {({
            errors,
            isSubmitting,
            isValid,
            values,
            setFieldValue,
            setFieldTouched,
            touched,
          }) => (
            <Form>
              <div className="form-group">
                <label>Country</label>
                <CountrySelect
                  id="country-select"
                  value={values.country}
                  multi={false}
                  onBlur={setFieldTouched}
                  touched={touched.country}
                  error={errors.country}
                  name={"country"}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                />
                {errors.country && (
                  <div className="invalid-feedback">{errors.country}</div>
                )}
              </div>

              <div className="form-group">
                <label>State</label>
                <StateSelect
                  country={values.country}
                  id="state-select"
                  className={ClassNames("form", {
                    "is-invalid": errors.name,
                  })}
                  value={values.state}
                  multi={false}
                  onBlur={setFieldTouched}
                  touched={touched.state}
                  error={errors.state}
                  name={"state"}
                  onChange={setFieldValue}
                />
                {errors.state && (
                  <div className="invalid-feedback">{errors.state}</div>
                )}
              </div>

              <div className="form-group">
                <label>City</label>
                <CitySelect
                  country={values.country}
                  state={values.state}
                  id="city-select"
                  className={ClassNames("form", {
                    "is-invalid": errors.name,
                  })}
                  value={values.city}
                  multi={false}
                  onBlur={setFieldTouched}
                  touched={touched.city}
                  error={errors.city}
                  name={"city"}
                  onChange={setFieldValue}
                />
                {errors.city && (
                  <div className="invalid-feedback">{errors.city}</div>
                )}
              </div>

              <div className="form-group">
                <label>Address</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.address,
                  })}
                  name="address"
                  type="text"
                  placeholder="E.g: 21 jump street"
                />
                {errors.address && (
                  <div className="invalid-feedback">{errors.address}</div>
                )}
              </div>
              <div className="form-group">
                <label>Name</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.name,
                  })}
                  name="name"
                  type="text"
                  placeholder="E.g: Jereer Main Office"
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2"
                  type="reset"
                  onClick={() => onClose()}
                  variant="danger"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  variant="success"
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

AddLocationModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

AddLocationModal.defaultProps = {
  show: false,
  onClose: () => {},
};

export default AddLocationModal;
