import React, { useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { inject, observer } from "mobx-react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const UserAvatar = inject("authStore")(
  observer(({ authStore, full = false }) => {
    try {
      authStore.user.initials();
    } catch (error) {
      localStorage.clear();
      window.location.reload();
    }
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggle
          style={{
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
            margin: 10,
          }}
          className="nav-link dropdown-toggle droptest arrow-none nav-user d-flex align-items-center text-black"
          tag="a"
        >
          <div
            className="rounded-circle"
            style={{
              background: "#253337",
              height: 40,
              width: 40,
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textTransform: "uppercase",
            }}
          >
            {authStore.user.initials()}
          </div>
          {full && (
            <span className="ml-2">
              {authStore.user.fullName()} <i className="fa fa-caret-down"></i>
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu>
          <p className="dropdown-header">{authStore.user.fullName()}</p>
          <DropdownItem
            onClick={() => {
              if (localStorage?.adminInfo && localStorage?.AdminAccessToken) {
                const adminUserInfo = JSON.parse(localStorage.adminInfo);
                const adminAccessTocken = localStorage.AdminAccessToken;
                localStorage.clear();
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify({ ...adminUserInfo })
                );
                localStorage.setItem("accessToken", adminAccessTocken);
                window.location.replace("/");
              } else {
                authStore.logout();
              }
            }}
          >
            <i className="mdi mdi-power text-danger" /> Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  })
);

export default UserAvatar;
