import { types, flow } from "mobx-state-tree";
import { CampaignModel } from "../models/CampaignModel";
import { LocationModel } from "../models/LocationModel";
import { Axios } from "../utils/http-common";
import { ServiceModel } from "../models/ServiceModel";

const LocationProjection = types.model("LocationProjection", {
  id: types.number,
  info: types.maybe(LocationModel),
  projections: types.number,
  services: types.array(
    types.model("ServiceProjections", {
      id: types.number,
      projections: types.number,
      info: types.maybe(ServiceModel)
    })
  )
});

const CampaignReportModel = types
  .model("ReportModel", {
    campaign: CampaignModel,
    locations: types.array(LocationProjection),
    totalProjections: types.number,
    services: types.array(ServiceModel)
  })
  .views(self => ({
    getService(id) {
      return self.services.find(service => service.id === id);
    }
  }));

export const ReportStore = types
  .model("ReportStore", {
    campaignReport: types.optional(types.maybeNull(CampaignReportModel), null)
  })
  .actions(self => {
    return {
      resetCampaignReport: () => {
        self.campaignReport = null;
      },
      setCampaignReport: campaignReport => {
        self.campaignReport = campaignReport;
      },
      fetchCampaignReport: flow(function*(campaignId) {
        yield Axios.get("/report/" + campaignId).then(res => {
          self.setCampaignReport(res.data);
          return res;
        });
      })
    };
  });

export const reportStore = ReportStore.create({});
