import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import * as Scheme from "yup";
import ClassNames from "classnames";
import { NotificationManager } from "react-notifications";
import { Axios } from "../../../utils/http-common";

const AdvetiserFormScheme = Scheme.object().shape({
  name: Scheme.string().required(),
  phone: Scheme.string()
    .min(7)
    .required(),
  email: Scheme.string().required(),
  address: Scheme.string(),
});

const AddAdvertiserModal = ({ show, onClose }) => {
  const onSubmit = values => {
    Axios.post("/advertiser", {
      ...values,
    }).then(value => {
      NotificationManager.success(
        `Advertiser ${value.data.name} added successfully`
      );
      onClose(value.data);
    });
  };
  return (
    <Modal isOpen={show}>
      <ModalHeader>
        Add a new advertiser
      </ModalHeader>
      <ModalBody>
        <Formik
          validationSchema={AdvetiserFormScheme}
          initialValues={{
            name: "",
            phone: "",
            email: "",
            address: "",
          }}
          validateOnChange
          onSubmit={values => onSubmit(values)}
        >
          {({ errors, setFieldValue }) => (
            <Form>
              <div className="form-group">
                <label>Advertiser name</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.name
                  })}
                  name="name"
                  type="text"
                  placeholder="E.g: Jereer"
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>

              <div className="form-group">
                <label>Phone number</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.phone
                  })}
                  name="phone"
                  type="text"
                  placeholder="E.g: 059XXXXXXX or 02XXXXXXX"
                />
                {errors.phone && (
                  <div className="invalid-feedback">{errors.phone}</div>
                )}
              </div>

              <div className="form-group">
                <label>Email</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.email
                  })}
                  name="email"
                  type="email"
                  placeholder="E.g: name@service.com"
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>

              <div className="form-group">
                <label>Address</label>
                <Field
                  className={ClassNames("form-control", {
                    "is-invalid": errors.address
                  })}
                  name="address"
                  type="textarea"
                />
                {errors.address && (
                  <div className="invalid-feedback">{errors.address}</div>
                )}
              </div>

              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2"
                  type="reset"
                  onClick={() => onClose()}
                  variant="outline-danger"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="success">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

AddAdvertiserModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  location: PropTypes.any
};

AddAdvertiserModal.defaultProps = {
  show: false,
  onClose: () => {}
};

export default AddAdvertiserModal;
