import React, { useState } from "react";
import PropType from "prop-types";
import { Button } from "react-bootstrap";
import { observer, inject } from "mobx-react";
import { noop } from "lodash";
import { Axios } from "../../../../utils/http-common";
import SweetAlert from "react-bootstrap-sweetalert";
import { CreatePlaylist } from "./CreatePlaylistModal";

const Playlist = ({
  playlist,
  onDelete = noop,
  onEdit = noop,
  onSelect = noop,
}) => {
  const [editing, setEditing] = useState(false);

  const [deletingPlaylist, setDeletingPlaylist] = useState(false);

  const deletePlaylist = () => {
    setDeletingPlaylist(false);
    Axios.delete("/playlist/" + playlist.id)
      .then((res) => {
        onDelete(playlist);
      })
      .catch((e) => NotificationManager.error(e.message));
  };

  const postPlaylistChanges = () => {
    Axios.put("/playlist/" + playlist.id).then((res) => {
      onEdit(res.data);
      setEditing(false);
    });
  };

  if (editing) {
    return (
      <CreatePlaylist
        onClose={() => {
          setEditing(false);
        }}
        onEdit={(values) => {
          onEdit(values);
        }}
        editing
        playlist={playlist}
      />
    );
  }

  return (
    <div
      key={playlist.id}
      onClick={() => onSelect(playlist)}
      className={["card", "mb-2"].join(" ")}
    >
      <div className="card-body d-flex justify-content-between">
        <div>{playlist.type}</div>
        <div>
          <span className="badge badge-pill badge-dark">
            3 <i className="fa fa-file-video ml-1"></i>
          </span>

          <Button
            onClick={() => setEditing(true)}
            variant=""
            className="ml-3 icon-button"
            outlined
          >
            <i className="fa fa-edit text-success"></i>
          </Button>

          <Button
            onClick={() => setDeletingPlaylist(true)}
            variant=""
            className="ml-3 icon-button"
            outlined
          >
            <i className="fa fa-close text-danger"></i>
          </Button>
          <i className="fa fa-arrow-right ml-3 text-muted"></i>
        </div>
      </div>

      {deletingPlaylist && (
        <SweetAlert
          show
          type="danger"
          title="Are you sure?"
          onConfirm={() => deletePlaylist()}
          onCancel={() => setDeletingPlaylist(false)}
          showCancel
          cancelBtnBsStyle="primary"
          confirmBtnBsStyle="danger"
          focusConfirmBtn={false}
        >
          You will not be able to revert changes.
        </SweetAlert>
      )}
    </div>
  );
};
Location.propTypes = {
  location: PropType.shape({
    id: PropType.number,
    country: PropType.string,
    state: PropType.string,
    city: PropType.string,
    address: PropType.string,
    long: PropType.any,
    lat: PropType.any,
    devices: PropType.any,
    services: PropType.any,
  }),
};
export default inject("locationsStore")(observer(Playlist));
