import { types, flow } from "mobx-state-tree";
import { Axios } from "../utils/http-common";

export const TagModel = types.model("TagModel", {
  id: types.identifierNumber,
  name: types.string,
  color: types.string
});

const TagStore = types
  .model("EventStore", {
    tags: types.optional(types.array(TagModel), [])
  })
  .actions(self => {
    return {
      setTags: tags => {
        self.tags = tags;
      },
      fetchTags: flow(function*(force = false) {
        if (force || self.tags.length === 0) {
          yield Axios.get("/tag").then(res => {
            self.setTags(res.data);
          });
        }
      })
    };
  });

const tagStore = TagStore.create({
  tags: []
});

export { tagStore };
