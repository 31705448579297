import { types } from "mobx-state-tree";
import { DeviceModel } from "./DeviceModel";

export const LocationModel = types
  .model("LocationModel", {
    id: types.identifierNumber,
    country: types.optional(types.string, ""),
    state: types.optional(types.maybeNull(types.string), ""),
    city: types.optional(types.maybeNull(types.string), ""),
    address: types.optional(types.string, ""),
    long: types.optional(types.maybeNull(types.number), null),
    lat: types.optional(types.maybeNull(types.number), null),
    devices: types.array(DeviceModel),
    name: types.optional(types.maybeNull(types.string), "")
  })
  .actions(self => ({
    setDevice: device => {
      if (device) {
        self.devices = [
          ...self.devices.filter(d => d.id !== device.id),
          device
        ];
      }
    }
  }));
