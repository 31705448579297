import axios from "axios";

export const config = () => {
  // if (window.location.host.includes("localhost")) {
  //   return {
  //     BASE_URL: "http://localhost:3000",
  //     development: true,
  //   };
  // } else {
  return {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    development: false,
    AUTO_RECONNECT: true,
    SSL_CONNECTION: true,
  };
  // }
};

export const variables = () => {
  return {
    periodic_interval: 60000,
  };
};
export const Axios = axios.create({
  baseURL: config().BASE_URL,
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

Axios.interceptors.request.use(
  function (config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const source = axios.CancelToken.source();
