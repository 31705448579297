import React, {Component} from 'react';
import C3Chart from 'react-c3js';
import 'c3/c3.css';

const colors = {
    pattern: ['#4ac18e', '#000', "#333", "#652", "#155"]
};

class DonutChart extends Component {
    render() {
      const data = {
        type: "pie",
        columns: this.props.labels.map((label, index) => 
          [label, this.props.data[index]])
      };
      return (
          <C3Chart data={data} color={colors}/>
      );
    }
}

DonutChart.defaultProps = {
  labels: [],
  data: [],
};

export default DonutChart;   
