/* eslint-disable react/prop-types */
import React from "react";
import {observer, inject} from "mobx-react";
import ReactSelect from "react-select";

class CitySelect extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.lookupsStore.fetchCountries();
    }

    cityToOption = city => {
        return {
            ...city,
            value: city.name,
            label: city.name,
            country: city.name,
        };
    };

    values() {
        if (this.props.value) {
            const found = this.props.lookupsStore.getCityByValue(this.props.country, this.props.state, this.props.value);
            if (found) {
                return this.cityToOption(found);
            }
        }
        return null;
    }

    render() {

        const {
            lookupsStore, multi = true, id, name, onChange, onBlur, touched, error, country, state
        } = this.props;
        return (
          <ReactSelect
            isClearable
            className={this.props.className}
            isMulti={multi}
            isDisabled={!(country && state)}
            value={this.values()}
            options={lookupsStore
              .cityOfCountryState(country, state)
              .map(this.cityToOption)}
            key={"country-select"}
            id={id}
            name={name}
            onChange={selected => onChange(name, selected ? selected.country : null)}
            onBlur={onBlur}
            touched={touched}
            error={error}
          />
        );
    }
}

CitySelect.defaultProps = {
    multi: true
};

export default inject("lookupsStore")(observer(CitySelect));
