import React, { useState, useEffect, useCallback, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { inject, observer } from "mobx-react";
import { JereerBrand } from "../JereerBrand";
import UserAvatar from "../UserAvatar/UserAvatar";
import { Axios } from "../../utils/http-common";
import { useLocation, useHistory } from "react-router-dom";

const sidebar = ({ authStore, layoutStore }) => {
  const changeSideBar = () => {
    if (layoutStore.enlarge === true) {
      layoutStore.toggleEnlarge();
    }
  };
  const history = useHistory();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(0);
  const [fallBack, setFallBack] = useState(0);
  useEffect(() => {
    Axios("/user").then((rez) => {
      setIsAdmin(rez.data.is_admin);
      setFallBack(1);
    });
  }, []);

  useEffect(() => {
    // Axios.get(
    //   `/company/subscription/${
    //     JSON.parse(localStorage.userInfo).parentCompany.id
    //   }`
    // ).then((data) => {
    //   const subscribtionStatus = data.data.subscription.status;
    //   if (
    //     subscribtionStatus == "non_renewing" ||
    //     subscribtionStatus == "active"
    //   ) {
    //     console.log(subscribtionStatus);
    //   } else {
    //     if (!window.location.toString().includes("paymentstatus")) {
    //       history.push("/digital-signage/subscription");
    //     }
    //   }
    // });
  }, [location]);

  return (
    <div className="left side-menu d-flex">
      <div className="master-menu-container d-flex flex-column justify-content-between">
        <div className="d-flex flex-column">
          <JereerBrand showCompanyName={false} theme="dark" />
        </div>

        <div>
          {/* <ul className="master-menu">
            <li>
              <NavLink
                to={"/digital-signage/subscription"}
                onClick={() => {
                  changeSideBar();
                }}
              >
                <i className="fas fa-money-bill-wave"></i>
              </NavLink>
            </li>
          </ul> */}
          <ul className="master-menu">
            <li>
              <NavLink to={"/settings/user"}>
                <i className="fa fa-gears" />
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-100">
        <div className="topbar-left">
          <div className="">
            <JereerBrand showLogo={false} theme="dark" />
          </div>
        </div>
        <div className="sidebar-inner slimscrollleft">
          <PerfectScrollbar>
            <div id="sidebar-menu">
              {window.location.href.includes("#/digital-signage") && (
                <ul>
                  <li>
                    <NavLink
                      to="/digital-signage/campaign/create"
                      exact
                      className={"waves-effect"}
                      activeClassName={"active-menu"}
                      onClick={() => {
                        changeSideBar();
                      }}
                    >
                      <i className="mdi mdi-plus-box" />
                      <span>New Campaign</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/digital-signage/home"
                      exact
                      className={"waves-effect"}
                      activeClassName={"active-menu"}
                      onClick={() => {
                        changeSideBar();
                      }}
                    >
                      <i className="mdi mdi-view-dashboard" />
                      <span>Home</span>
                    </NavLink>
                  </li>

                  {/* {!authStore.company.classic && (
                                    <li>
                                        <NavLink
                                            to="/digital-signage/advertiser"
                                            exact
                                            activeClassName={"active-menu"}
                                            className={"waves-effect"}
                                        >
                                            <i className="mdi mdi-account-multiple"/>
                                            <span>Advertisers</span>
                                        </NavLink>
                                    </li>
                                )} */}

                  <li>
                    <NavLink
                      to="/digital-signage/campaign/all"
                      exact
                      activeClassName={"active-menu"}
                      className={"waves-effect"}
                      onClick={() => {
                        changeSideBar();
                      }}
                    >
                      <i className="fa fa-bullhorn" />
                      <span>Campaigns</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/digital-signage/media"
                      exact
                      activeClassName={"active-menu"}
                      className={"waves-effect"}
                      onClick={() => {
                        changeSideBar();
                      }}
                    >
                      <i className="fa fa-file-image" />
                      <span>Media</span>
                    </NavLink>
                  </li>

                  {/* {!authStore.company.classic && (
                                    <li>
                                        <NavLink
                                            to="/digital-signage/event/all"
                                            exact
                                            className={"waves-effect"}
                                            activeClassName={"active-menu"}
                                        >
                                            <i className="fa fa-calendar-day"/>
                                            <span>Events</span>
                                        </NavLink>
                                    </li>
                                )} */}

                  <li className="menu-title">Devices</li>
                  <li>
                    <NavLink
                      to="/digital-signage/locations"
                      exact
                      activeClassName={"active-menu"}
                      className={"waves-effect"}
                      onClick={() => {
                        changeSideBar();
                      }}
                    >
                      <i className="fa fa-tv" />
                      <span>Locations</span>
                    </NavLink>
                  </li>

                  {fallBack == 1 && isAdmin == 1 && (
                    <Fragment>
                      <li className="menu-title">Admin</li>
                      <li>
                        <NavLink
                          to="/digital-signage/Admin"
                          exact
                          activeClassName={"active-menu"}
                          className={"waves-effect"}
                          onClick={() => {
                            changeSideBar();
                          }}
                        >
                          <i className="fas fa-users"></i>
                          <span>Clients</span>
                        </NavLink>
                      </li>
                    </Fragment>
                  )}
                  {/* {!authStore.company.classic && (<><li className="menu-title">Reports</li>
                                <li>
                                    <NavLink
                                        to="/digital-signage/report"
                                        exact
                                        activeClassName={"active-menu"}
                                        className={"waves-effect"}
                                    >
                                        <i className="fa fa-file"/>
                                        <span>Campaign Report</span>
                                    </NavLink>
                                </li></>)} */}

                  {/* {!authStore.company.classic && (<li className="menu-title">Settings</li>)}

                                {!authStore.company.classic && (<li>
                                    <NavLink
                                        to="/digital-signage/settings/services"
                                        exact
                                        activeClassName={"active-menu"}
                                        className={"waves-effect"}
                                    >
                                        <i className="fas fa-layer-group"/>
                                        <span>Services</span>
                                    </NavLink>
                                </li>)} */}

                  {/* {!authStore.company.classic && (<li>
                                    <NavLink
                                        to="/digital-signage/settings/playlists"
                                        exact
                                        activeClassName={"active-menu"}
                                        className={"waves-effect"}
                                    >
                                        <i className="fa fa-photo-video"/>
                                        <span>Playlists</span>
                                    </NavLink>
                                </li>)} */}
                </ul>
              )}

              {/*{window.location.href.includes("#/analytics") && (*/}
              {/*    <ul>*/}
              {/*        <li>*/}
              {/*            <NavLink*/}
              {/*                to="/analytics/dashboard"*/}
              {/*                exact*/}
              {/*                className={"waves-effect"}*/}
              {/*                activeClassName={"active-menu"}*/}
              {/*            >*/}
              {/*                <i className="mdi mdi-view-dashboard" />*/}
              {/*                <span>Dashboard</span>*/}
              {/*            </NavLink>*/}
              {/*        </li>*/}
              {/*    </ul>*/}
              {/*)}*/}

              {window.location.href.includes("#/settings") && (
                <ul>
                  <li>
                    <NavLink
                      to="/settings/user"
                      exact
                      className={"waves-effect"}
                      activeClassName={"active-menu"}
                      onClick={() => {
                        changeSideBar();
                      }}
                    >
                      <i className="mdi mdi-settings" />
                      <span>User Settings</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/settings/company"
                      exact
                      className={"waves-effect"}
                      activeClassName={"active-menu"}
                      onClick={() => {
                        changeSideBar();
                      }}
                    >
                      <i className="mdi mdi-settings" />
                      <span>Company Settings</span>
                    </NavLink>
                  </li>
                </ul>
              )}
            </div>

            <div className="clearfix"></div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};

sidebar.propTypes = {};

export default inject("authStore", "layoutStore")(withRouter(sidebar));
