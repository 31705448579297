import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  ProgressBar,
  ButtonGroup,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import {
  upload,
  createWebUrlMedia,
  cancelUpload,
} from "../../services/FileUploadService";

import "../../styles/Media.css";
import { Axios, config } from "../../utils/http-common";
import { Promise } from "q";
import SweetAlert from "react-bootstrap-sweetalert";
import { NotificationManager } from "react-notifications";
import { v4 as uuidv4 } from "uuid";
import * as _ from "lodash";
import { create, initial } from "lodash";
import { inject } from "mobx-react";
import { autorun } from "mobx";
import FileTrackerContainer from "../FileTracker/container";

/**
 * Formats the size
 */
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
const CampaignMedia = ({
  advertiser,
  onChange = (media = []) => {},
  createCampaignStore,
}) => {
  const [files, setFiles] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [webUrl, setWebUrl] = useState("");
  const [deletingMedia, setDeletingMedia] = useState(false);
  const [mediaFile, setMediaFile] = useState(-1);
  const [mediaIndex, setMediaIndex] = useState(-1);
  const [fetchedFiles, setFetchedFiles] = useState([]);
  const [filesToDisplay, setFilesToDisplay] = useState([]);
  const [slots, setSlots] = useState([]);
  const [intervals, setIntervals] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(null);
  const [viewMode, setViewMode] = useState("all");
  const [filesUploaded, setFilesUploaded] = useState([]);
  const locationPath = useLocation();
  const [showFileTracker, setShowFileTracker] = useState(false);
  useEffect(() => {
    Axios.get("/interval").then((res) => {
      setIntervals(res.data);
    });
    return () => {};
  }, []);

  // fetch media files for current advertiser
  useEffect(() => {
    if (advertiser) {
      fetchFiles(advertiser.id);
    }
    return () => {};
  }, [advertiser]);

  // retrieve reserved slots from create campaign store
  useEffect(() => {
    autorun(() => {
      setSlots(createCampaignStore.campaign.reservedSlots.toJSON());
    });
  }, []);

  // get fetched files and display them as media
  useEffect(() => {
    // add files to be display in upload/media section
    setFilesToDisplay(fetchedFiles);

    // get names of media just uploaded
    let newSlotNames = filesUploaded.map((file) => {
      return file.name;
    });

    // get media objects
    let newSlotObjects = newSlotNames.map((name) => {
      return fetchedFiles.find((fetchedFile) => fetchedFile.title === name);
    });

    // add them as slots
    newSlotObjects.map((file) => {
      if (file) {
        addSlot(file);
      }
    });

    return () => {};
  }, [fetchedFiles]);

  // set campaign reserved slots based on added media items
  useEffect(() => {
    if (slots.length > 0) {
      createCampaignStore.campaign.setSlots(slots);
    }
    return () => {};
  }, [slots]);

  // set view mode for media
  useEffect(() => {
    if (viewMode === "all") {
      setFilesToDisplay(fetchedFiles);
    } else if (viewMode === "image") {
      setFilesToDisplay(
        fetchedFiles.filter((file) =>
          file.title.toLowerCase().match(/(.png|.jpg|.gif|.jpeg|.svg)$/)
        )
      );
    } else if (viewMode === "video") {
      setFilesToDisplay(
        fetchedFiles.filter((file) =>
          file.title.toLowerCase().match(/(.mp4|.mkv)$/)
        )
      );
    }
    return () => {};
  }, [viewMode]);

  const deleteFile = (advertiserId, file, index) => {
    setDeletingMedia(false);

    Axios.delete("/library/link/" + advertiserId + "/" + file.id)
      .then(() => {
        fetchFiles(advertiserId);
        NotificationManager.success(`File ${file.title} deleted successfully`);
      })
      .catch((e) => {
        NotificationManager.error(`File was NOT deleted.`);
      });
    setSlots(slots.filter((_, i) => index !== i));
  };

  const addSlot = (file) => {
    const interval = file.title.toLowerCase().match(/(.mp4|.mkv)$/)
      ? intervals[0]
      : intervals[1];
    setSlots((prevSlots) => [
      ...prevSlots,
      {
        key: uuidv4(),
        interval: interval,
        media: {
          id: file.id,
          url: file.url,
          content: "",
          title: file.title,
        },
        url: file.url,
        duration: file.duration ? parseInt(file.duration) : interval?.itemTime,
      },
    ]);
  };

  const handleAcceptedFiles = (inputFiles) => {
    setShowFileTracker(true);
    inputFiles.map((file) =>
      Object.assign(file, {
        id: uuidv4(),
        uploadProgress: 0,
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setFiles([...inputFiles, ...files]);
    uploadNewFiles(inputFiles);
  };

  const fetchFiles = (advertiserId) => {
    return Axios.get(`/library/link/${advertiserId}`).then((res) => {
      res.data = res.data.map((file) => {
        const newUrl = `${file.url}`;
        return { ...file, url: newUrl };
      });
      setFetchedFiles(res.data);
      onChange(res.data);
      return res;
    });
  };

  // assign files to currentFiles (files to be displayed)
  useEffect(() => {
    setCurrentFiles((prevMovies) => [...files]);
    return () => {};
  }, [files]);

  // add upload progress to file
  const updateFileUploadProgress = (file = {}, progress = 0) => {
    let oldState = _.cloneDeep(files);
    file.uploadProgress = progress;
    setUploadingFile({ ...file });
  };
  useEffect(() => {
    // setFiles(oldState=>{
    //   if (oldState.id==) {
    //   }
    // })
  }, [uploadingFile]);

  // upload files and calculate progress
  const uploadNewFiles = (newFiles) => {
    setUploading(true);
    return Promise.all(
      newFiles.map((file) =>
        upload(file, advertiser.id, (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          updateFileUploadProgress(file, percentCompleted);
        })
      )
    ).then(() => {
      fetchFiles(advertiser.id);
      setFilesUploaded(newFiles);
    });
  };

  const mediaItems = filesToDisplay.map((file, index) => (
    <OverlayTrigger
      key={file.title + index + "ov"}
      placement="top"
      overlay={
        <Tooltip id={`tooltip-${file.title}`}>
          <strong>{file.title}</strong>
        </Tooltip>
      }
    >
      <div
        className="thumb"
        key={file.title + index}
        onClick={() => {
          addSlot(file);
        }}
      >
        <div className="thumbInner">
          {file.title.includes(".mp4") ? (
            <video
              className="img-fluid p-1"
              style={{
                objectFit: "cover",
              }}
              preload="metadata"
            >
              <source src={file.url + "#t=0.50"} type="video/mp4"></source>
            </video>
          ) : (
            <img src={file.url} className="img" />
          )}
          <div className="middle">
            <div className="textArea">Add to Playlist</div>
          </div>
        </div>
      </div>
    </OverlayTrigger>
  ));

  return (
    <Row>
      <FileTrackerContainer
        show={showFileTracker}
        setShow={setShowFileTracker}
        files={currentFiles}
        setFiles={setCurrentFiles}
      />
      <Col lg={12}>
        <div className="card m-b-20">
          <div className="card-body">
            <div className="row d-flex justify-content-between">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="d-flex justify-content-between">
                  <div>
                    <div>
                      <h4 className="mt-0 header-title">Available Media</h4>
                      <p className="text-muted m-b-30 font-14">
                        Click on media to add it to playlist.
                      </p>
                    </div>
                    <div>
                      <ButtonToolbar
                        className="d-flex"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={"Filter media by type"}
                      >
                        <ButtonGroup
                          aria-label="Filter media by type"
                          className="filter-btns"
                        >
                          <Button
                            style={{
                              width: 80,
                              height: 33,
                              paddingTop: "5px !important",
                              textAlign: "center",
                              fontSize: "10px !important",
                            }}
                            variant="info"
                            onClick={() => {
                              setViewMode("all");
                              $(".filter-btn").removeClass("active");
                              $(".all").addClass("active");
                            }}
                            className="filter-btn all active"
                          >
                            All
                          </Button>
                          <Button
                            style={{ width: 80, height: 33 }}
                            variant="info"
                            onClick={() => {
                              setViewMode("image");
                              $(".filter-btn").removeClass("active");
                              $(".image").addClass("active");
                            }}
                            className="filter-btn image"
                          >
                            Image
                          </Button>
                          <Button
                            style={{ width: 80, height: 33 }}
                            variant="info"
                            onClick={() => {
                              setViewMode("video");
                              $(".filter-btn").removeClass("active");
                              $(".video").addClass("active");
                            }}
                            className="filter-btn video"
                          >
                            Video
                          </Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {/* <Button
                  // type="button"
                  // variant="outline-primary"
                  // className="btn btn-outline-dark"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  Upload
                  <i className="h1 text-muted dripicons-cloud-upload"></i>
                </Button> */}
              </div>
            </div>
            <div className="container-fluid d-flex flex-nowrap campaign-media-direction">
              <Dropzone
                onDrop={(acceptedFiles, rejected) => {
                  if (rejected.length === 0) {
                    handleAcceptedFiles(acceptedFiles);
                  } else {
                    NotificationManager.error("the file/s was not uploaded ");
                  }
                }}
                disableClick={null}
                multiple
                accept="image/*,video/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="uploadThumbsContainer" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="uploadThumbInner">
                      <div className="uploadThumb">
                        <div className="uploadMiddle">
                          <i className="h1 text-muted dripicons-cloud-upload upload-icon"></i>
                          <div className="uploadTextArea">Upload</div>
                        </div>
                      </div>
                      <div className="uploadThumbInner">
                        <div className="uploadMiddle"></div>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
              <aside
                className={`thumbsContainer ${
                  fetchedFiles.length > 0 ? "available-phone-media" : ""
                }`}
              >
                {mediaItems}
              </aside>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title"></h4>
                    <p className="text-muted m-b-30 font-14"></p>
                    <section>
                      <Row
                        className="dropzone-previews mt-3 mb-3"
                        id="file-previews"
                      >
                        {currentFiles.map((f, i) => {
                          return (
                            <Col md={12} key={i}>
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="card-body">
                                  <Row className="align-items-center">
                                    <Col sm={6}>
                                      <div className="mb-2">
                                        {f.type.includes("video") && (
                                          <video
                                            style={{
                                              maxWidth: "100%",
                                            }}
                                            controls
                                          >
                                            <source
                                              src={f.preview}
                                              type={f.type}
                                            ></source>
                                          </video>
                                        )}
                                        {f.type.includes("image") && (
                                          <img
                                            data-dz-thumbnail=""
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                            height="150"
                                            style={{
                                              maxWidth: "100%",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="">
                                        <p
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </p>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <ProgressBar
                                      className="w-100"
                                      animated
                                      striped
                                      min={0}
                                      now={f.uploadProgress}
                                      max={100}
                                    />
                                    {/* <button
                                      className="btn btn-warning cancel"
                                      onClick={() => {
                                        cancelUpload();
                                      }}
                                    >
                                      <span>Cancel</span>
                                    </button> */}
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </section>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    setFiles([]);
                    setUploading(false);
                    cancelUpload();
                  }}
                >
                  Cancel
                </button> */}
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    if (files.length != 0) {
                      alert("the files will be downloaded soon");
                    } else {
                      setUploading(false);
                      setFiles([]);
                    }
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </Col>
      {deletingMedia && (
        <SweetAlert
          show
          type="danger"
          title="Are you sure?"
          onConfirm={() => deleteFile(advertiser.id, mediaFile, mediaIndex)}
          onCancel={() => setDeletingMedia(false)}
          showCancel
          cancelBtnBsStyle="primary"
          confirmBtnBsStyle="danger"
          focusConfirmBtn={false}
        >
          You will not be able to revert changes.
        </SweetAlert>
      )}
    </Row>
  );
};

export default inject(["createCampaignStore"])(CampaignMedia);
