/* eslint-disable no-empty */
import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { CardHeader } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import { Field, Form, Formik } from "formik";
import * as Scheme from "yup";
import { inject, observer } from "mobx-react";
import { Axios } from "../../../../utils/http-common";
import { NotificationManager } from "react-notifications";

const CompanyInfoScheme = Scheme.object().shape({
  companyName: Scheme.string().required(),
  description: Scheme.string(),
  address: Scheme.string()
});

const CompanyInfoForm = ({
  company,
  onSubmit = values => {},
  onReset = () => {}
}) => {
  const [loading, setLoading] = useState(false);

  const submit = values => {
    setLoading(true);
    Axios.patch("company/" + company.id, values)
      .then(res => {
        onSubmit(res.data);
      })
      .catch(e => {
        NotificationManager.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Formik
      validationSchema={CompanyInfoScheme}
      initialValues={{
        ...company
      }}
      validateOnChange
      onSubmit={submit}
      onReset={onReset}
    >
      {({ errors }) => (
        <Form>
          <div className="form-group">
            <label>Company Name</label>
            <Field name="companyName" type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Address</label>
            <Field name="address" type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Description</label>
            <Field name="description" type="text" className="form-control" />
          </div>

          <div className="form-group">
            <Button variant={"outline-danger"} type="reset">
              <span>Cancel</span>
            </Button>
            <Button variant="success" type="submit" className="mr-2">
              {loading && (
                <>
                  {" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span>Loading...</span>
                </>
              )}
              {!loading && <span>Save</span>}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};


/**
 * Company Settings
 * Services and Intervals Settings
 */
const CompanySettings = ({ authStore }) => {
  const [isEditingCompanyInfo, setisEditingCompanyInfo] = useState(false);
  const { company } = authStore;

  return (
    <div className="page-content-wrapper">
      <div className="mb-3 mt-3 container-fluid">
        <Row className="mb-2">
          <Col xs={8}>
            <h4 className="mt-0 header-title">
              <i className="fa fa-cogs mr-3"></i>Company Settings
            </h4>
          </Col>
        </Row>
        <Row className="mb-2">
          <Card className="w-100">
            <CardHeader className="d-flex justify-content-between align-items-center">
              <div className="header-title">
                Company Info
              </div>
              {!isEditingCompanyInfo && (
                <Button
                  onClick={() => {
                    setisEditingCompanyInfo(true);
                  }}
                  variant="info"
                >
                  <i className="fa fa-edit mr-2" />
                  Edit
                </Button>
              )}
            </CardHeader>

            <CardBody>
              {isEditingCompanyInfo ? (
                <CompanyInfoForm
                  onSubmit={values => {
                    setisEditingCompanyInfo(false);
                    authStore.fetchProfile();
                  }}
                  company={company}
                  onReset={() => setisEditingCompanyInfo(false)}
                />
              ) : (
                <>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">
                      Company Name
                    </label>
                    <div className="col-sm-10">{company.companyName}</div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Address</label>
                    <div className="col-sm-10">{company.address}</div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">
                      Description
                    </label>
                    <div className="col-sm-10">{company.description}</div>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Row>
      </div>
    </div>
  );
};

CompanySettings.propTypes = {};

export default inject("authStore")(observer(CompanySettings));
