import campaignStore from "./campaignStore";
import createCampaignStore from "./createCampaignStore";
import navigationStore from "./navigationStore";
import { connectReduxDevtools } from "mst-middlewares";
import { valuesIn } from "lodash";
import { authStore } from "./authStore";
import { locationsStore } from "./locationsStore";
import advertiserStore from "./advertiserStore";
import { eventStore } from "./eventStore";
import { tagStore } from "./tagStore";
import { lookupsStore } from "./lookupsStore.js";
import { reportStore } from "./reportStore";
import { MobXProviderContext } from "mobx-react";
import { layoutStore } from "./layoutStore";
import { config } from "../utils/http-common";

export const stores = {
  campaignStore,
  createCampaignStore,
  navigationStore,
  authStore,
  locationsStore,
  advertiserStore,
  eventStore,
  tagStore,
  lookupsStore,
  reportStore,
  layoutStore,
};

export const useStores = () => {
  return MobXProviderContext.Consumer;
};

/*if(config().development){
  for (const store of valuesIn(stores)) {
    connectReduxDevtools(require("remotedev"), store, {
      logChildActions: true
    });
  }
}*/
