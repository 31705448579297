/* eslint-disable no-dupe-else-if */
import React from "react";
import { types, flow } from "mobx-state-tree";
import { Axios } from "../utils/http-common";
import { clone } from "lodash";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import moment from "moment";
import chroma from "chroma-js";

const TagModel = types.model("TagModel", {
  id: types.identifierNumber,
  name: types.string,
  color: types.string
});

const EventModel = types.model("EventModel", {
  id: types.identifierNumber,
  name: types.string,
  startDate: types.string,
  endDate: types.string,
  tags: types.array(TagModel),
  startTime: types.string,
  endTime: types.string,
  exceptionPauseTime: types.number
});

const EventStore = types
  .model("EventStore", {
    events: types.optional(types.array(EventModel), [])
  })
  .actions(self => {
    return {
      setEvents: events => {
        self.events = events;
      },
      fetchEvents: flow(function*(force = false) {
        if (force || self.events.length === 0) {
          yield Axios.get("/event").then(res => {
            self.setEvents(res.data);
          });
        }
      })
    };
  })
  .views(self => ({
    getRows: () => {
      try {
        return self.events.map(event => {
          const newOne = clone(event);
          // newOne.startDate = campaign.startDate.toISOString();
          // newOne.endDate = campaign.endDate.toISOString();
          newOne.dateRange = (
            <>
              <span>
                {`${moment(event.startDate).format("DD/MM/YY")} - ${
                  event.endDate
                    ? moment(event.endDate).format("DD/MM/YY")
                    : "Infinite"
                }`}
              </span>
              <br />
              <span>{`${event.startTime} - ${event.endTime}`}</span>
            </>
          );
          newOne.tags = event.tags.map(tag => {
            let color = chroma("black");
            if (
              tag.color &&
              tag.color.match(
                /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^)]*\)/
              )
            ) {
              color = chroma(tag.color);
            }
            return (
              <span
                key={tag.id}
                style={{
                  backgroundColor: color.alpha(0.1),
                  color: color,
                  borderRadius: 5,
                  padding: "2px 5px",
                  marginInlineEnd: 5
                }}
                className="tag tag-success"
              >
                {tag.name}
              </span>
            );
          });
          newOne.actions = (
            <>
              <Link
                to={`/digital-signage/event/${event.id}/edit`}
                className="btn btn-info btn-sm"
              >
                <i className="fa fa-edit mr-1"></i>
                Edit
              </Link>
              <Button variant={"danger"} size="sm" onClick={() => {}}>
                <i className="fa fa-remove"></i>
              </Button>
            </>
          );
          return newOne;
        });
      } catch (e) {
        return [];
      }
    }
  }));

const eventStore = EventStore.create({
  events: []
});

export { eventStore };
